import { Button, ButtonProps, styled } from '@mui/material';

interface SidebarButtonLink {
  isSidebarOpen: boolean;
}

const SidebarButtonLinkWrapper = <C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }> & SidebarButtonLink,
) => <Button {...props}>{props.children}</Button>;

export const SidebarButtonLink = styled(SidebarButtonLinkWrapper, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen',
})(({ theme, isSidebarOpen }) => ({
  alignSelf: isSidebarOpen ? 'flex-start' : 'center',
  justifyContent: isSidebarOpen ? 'flex-start' : 'center',
  padding: theme.spacing(1),
  textTransform: 'capitalize',
  height: theme.spacing(4),
  minWidth: theme.spacing(3.6),
  width: isSidebarOpen ? '100%' : theme.spacing(4),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '& .MuiButton-startIcon': {
    ...(!isSidebarOpen && { margin: '0' }),
  },
}));
