import dayjs, { Dayjs } from 'dayjs';
import { useMemo, useState } from 'react';
import Box from '@mui/material/Box/Box';
import { Button, Container, Typography, useTheme } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RoomMenu } from '../RoomMenu';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { SearchLayout } from '../../../common/components/SearchLayout';
import { IHotelSearch } from '../../../common/types';
import { DateRange, KingBed, Person, Place } from '@mui/icons-material';

const useAvailabilitySearcherPadding = () => {
  const theme = useTheme();
  const { isMediumUp, isLargeUp } = useBreakpoints();
  if (isLargeUp) return theme.spacing(2, 8);
  if (isMediumUp) return theme.spacing(2, 4);
  return theme.spacing(2);
};

const tRoot = 'hotels.searcher.';

export const HotelAvailabilitySearcher = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);
  const [checkInDate, setCheckIn] = useState<dayjs.Dayjs | null>(null);
  const [checkOutDate, setCheckOut] = useState<dayjs.Dayjs | null>(null);
  const [disabledChecks, setDisabledChecks] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMediumDown, isMediumUp, isLargeUp } = useBreakpoints();
  const padding = useAvailabilitySearcherPadding();
  const methods = useForm<IHotelSearch>();
  const { control, handleSubmit } = methods;

  const handleDisableChecks = () => {
    setDisabledChecks((prevState) => !prevState);
    setCheckIn(null);
    setCheckOut(null);
  };
  const handleToggleRoomMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setOpen((prevState) => !prevState);
    setAnchorEl(event ? event.currentTarget : null);
  };
  const handleCheckIn = (value: Dayjs | null) => {
    setCheckIn(dayjs(value));
  };
  const handleCheckOut = (value: Dayjs | null) => {
    setCheckOut(dayjs(value));
  };
  const onSubmit = (args: any) => console.log(args);

  const firstInput = {
    formFieldName: 'destination',
    text: t(`${tRoot}destination`),
    error: t(`${tRoot}destination.error`),
    icon: <Place />,
  };

  const checkInConfig = useMemo(
    () => ({
      formFieldName: 'initialDate',
      text: t(`${tRoot}checkIn`),
      error: t(`${tRoot}checkIn.error`),
      disabled: disabledChecks,
      value: checkInDate,
      handler: handleCheckIn,
      icon: (props: any) => <DateRange {...props} sx={{ color: theme.palette.primaryAlternative.special }} />,
    }),
    [disabledChecks, checkInDate, handleCheckIn],
  );

  const checkOutConfig = useMemo(
    () => ({
      formFieldName: 'endDate',
      text: t(`${tRoot}checkOut`),
      error: t(`${tRoot}checkOut.error`),
      disabled: disabledChecks,
      value: checkOutDate,
      handler: handleCheckOut,
      icon: (props: any) => <DateRange {...props} sx={{ color: theme.palette.primaryAlternative.special }} />,
    }),
    [disabledChecks, checkOutDate, handleCheckOut],
  );

  const firstMenu = {
    formFieldName: 'room',
    text: t(`${tRoot}room`),
    error: t(`${tRoot}room.error`),
    icon: <KingBed />,
  };
  const secondMenu = {
    formFieldName: 'guest',
    text: t(`${tRoot}guest`),
    error: t(`${tRoot}guest.error`),
    icon: <Person />,
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          p={padding}
          bgcolor="primary.main"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={3}
        >
          <Container>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={3}>
              <SearchLayout
                control={control}
                firstInput={firstInput}
                firstMenu={firstMenu}
                secondMenu={secondMenu}
                unknownDatesMessage={t(`${tRoot}unknownDates`)}
                handleDisableChecks={handleDisableChecks}
                handleToggleRoomMenu={handleToggleRoomMenu}
                checkInConfig={checkInConfig}
                checkOutConfig={checkOutConfig}
                darkMode
              />
              <Button
                fullWidth={isMediumDown}
                type="submit"
                color="secondary"
                variant="contained"
                size="large"
                sx={{
                  padding: theme.spacing(1, 3),
                  borderRadius: theme.spacing(1),
                  boxShadow: theme.shadows[8],
                  alignSelf: isMediumUp ? 'end' : '',
                  marginTop: isLargeUp ? '-40px' : '',
                }}
              >
                <Typography variant="body1" color="primary.contrastText">
                  {t(`${tRoot}apply`)}
                </Typography>
              </Button>
              <RoomMenu
                firstFieldControlled={firstMenu}
                secondFieldControlled={secondMenu}
                control={control}
                open={open}
                toggle={handleToggleRoomMenu}
                anchorEl={anchorEl}
              />
            </Box>
          </Container>
        </Box>
      </form>
    </FormProvider>
  );
};
