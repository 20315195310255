import { disconnectedUser } from '../../../assets';

export const users = [
  {
    name: 'Gelya',
    lastName: 'Strotton',
    phoneNumber: '(836) 8736421',
    profilePicture: disconnectedUser,
    email: 'gstrotton0@posterous.com',
    roles: ['Administrador'],
  },
  {
    name: 'Amos',
    lastName: 'Stephenson',
    phoneNumber: '(399) 8846831',
    profilePicture: disconnectedUser,
    email: 'astephenson1@noaa.gov',
    roles: ['Administrador'],
  },
  {
    name: 'Constantia',
    lastName: 'Gouldstone',
    phoneNumber: '(411) 3119173',
    profilePicture: disconnectedUser,
    email: 'cgouldstone2@ucla.edu',
    roles: ['Vendedor'],
  },
  {
    name: 'Georgette',
    lastName: 'Nornable',
    phoneNumber: '(848) 3502200',
    profilePicture: disconnectedUser,
    email: 'gnornable3@usa.gov',
    roles: ['Vendedor'],
  },
  {
    name: 'Atlanta',
    lastName: 'Elsbury',
    phoneNumber: '(563) 9205343',
    profilePicture: disconnectedUser,
    email: 'aelsbury4@opera.com',
    roles: ['Administrador', 'Vendedor'],
  },
  {
    name: 'Penni',
    lastName: 'Gercken',
    phoneNumber: '(489) 7158737',
    profilePicture: disconnectedUser,
    email: 'pgercken5@jalbum.net',
    roles: ['Usuario'],
  },
  {
    name: 'Con',
    lastName: 'Burmaster',
    phoneNumber: '(627) 3501123',
    profilePicture: disconnectedUser,
    email: 'cburmaster6@newsvine.com',
  },
  {
    name: 'Ceciley',
    lastName: 'Logsdale',
    phoneNumber: '(903) 6053048',
    profilePicture: disconnectedUser,
    email: 'clogsdale7@feedburner.com',
  },
  {
    name: 'Jermayne',
    lastName: 'Normanvill',
    phoneNumber: '(770) 8506821',
    profilePicture: disconnectedUser,
    email: 'jnormanvill8@sourceforge.net',
  },
  {
    name: 'Meir',
    lastName: 'Frend',
    phoneNumber: '(615) 9157403',
    profilePicture: disconnectedUser,
    email: 'mfrend9@reference.com',
  },
];
export const roles = ['AGENT', 'OPERATOR', 'TRAVEL_MANAGER'];
