import { Box, IconButton } from '@mui/material';
import { ReactNode, useState, useMemo } from 'react';
import { Tune } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { BackToolbar } from '../BackToolbar';
import { HotelAvailabilitySearcher } from '../../../hotel/components/HotelAvailabilitySearcher';
import { MapWithMarkersProps, MapWrapper } from '../../../hotel/components/MapWrapper/MapWrapper';
import { mobileAvailabilityHeaderHeight } from '../../constants';

type MobileMapViewProps = MapWithMarkersProps & {
  title: string;
  center: google.maps.LatLngLiteral;
  markers: google.maps.MarkerOptions[];
  onBack: () => void;
  children?: ReactNode;
};

export const MobileMapView = ({ title, onBack, ...props }: MobileMapViewProps) => {
  const [openTune, setOpenTune] = useState(false);
  const { t } = useTranslation();

  const handleTune = () => setOpenTune((prevState) => !prevState);

  const map = useMemo(
    () => (
      <MapWrapper {...props} style={{ height: `calc(100vh - ${mobileAvailabilityHeaderHeight})`, width: '100%' }} />
    ),
    [props],
  );

  return (
    <Box display="flex" flexDirection="column">
      <BackToolbar title={title} handleBack={onBack}>
        <IconButton aria-label={t('hotels.availabilityLayout.tuneFiltersAriaLabel')} onClick={handleTune}>
          <Tune sx={{ color: 'primary.contrastText' }} />
        </IconButton>
      </BackToolbar>
      {openTune && <HotelAvailabilitySearcher />}
      {map}
    </Box>
  );
};
