export const providerData = [
  {
    providerName: 'Servicios de Hospitalidad ABC',
    hotelNameForProvider: 'Resort XYZ',
    stars: 4,
    description:
      'Experimenta el lujo y la comodidad en el Resort XYZ, proporcionado por Servicios de Hospitalidad ABC. Nuestro hotel de cuatro estrellas ofrece una combinación perfecta de elegancia y comodidades modernas para una estancia memorable.',
  },
  {
    providerName: 'Viajes de Lujo DEF',
    hotelNameForProvider: 'Hotel ABC',
    stars: 5,
    description:
      'Descubre la excelencia en el Hotel ABC, presentado por Viajes de Lujo DEF. Con una calificación de cinco estrellas, nuestro hotel ofrece un ambiente de lujo inigualable y servicios de clase mundial para una experiencia única.',
  },
  {
    providerName: 'Hospedajes GHI',
    hotelNameForProvider: 'Villa Relax',
    stars: 3,
    description:
      'Disfruta de la serenidad en la Villa Relax, gestionada por Hospedajes GHI. Con tres estrellas, nuestro alojamiento ofrece un ambiente relajado y acogedor, perfecto para escapadas tranquilas y momentos de descanso.',
  },
];
