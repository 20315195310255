import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { routes } from './routes';
import { Flights } from './flights/home';
import { Hotels } from './hotels/home';
import { Cars } from './cars/home';
import { Explorer } from './explorer/home';
import { Layout } from '../features/common/components/Layout';
import { HotelMarkUpRules } from '../features/hotel/components/HotelMarkupRules';
import { HotelsAvailability } from './hotels/availability';
import { Users } from './users/home';
import { Sublayout } from '../features/common/components/Sublayout';
import { HotelRoutingRules } from '../features/hotel/components/HotelRoutingRules';
import { HotelCreateMarkupRule } from '../features/hotel/components/HotelMarkupRules/HotelCreateMarkupRule';
import { HotelContentManager } from '../features/hotel/components/HotelContentManager';
import { Error404 } from './404';
import { ProtectedRoute } from '../features/auth/components/ProtectedRoute';
import { HotelDetailContentManger } from '../features/hotel/components/HotelDetailContentManager';
import { Dashboard } from '../features/dashboard/components/Dashboard';
import { DashboardLayout } from '../features/dashboard/components/DashboardLayout';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={routes.error404} element={<Error404 />} />
      <Route path="/" element={<Layout />}>
        <Route element={<Sublayout />}>
          <Route path={routes.flights} element={<Flights />} />
          <Route path={routes.hotels} element={<Hotels />} />
          <Route path={routes.cars} element={<Cars />} />
          <Route path={routes.explorer} element={<Explorer />} />
        </Route>
        <Route
          path={routes.dashboard}
          element={
            <ProtectedRoute I="view" a="Dashboard">
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Dashboard />} />
        </Route>
        <Route
          path={routes.hotelMarkupRules}
          element={
            <ProtectedRoute I="view" a="MarkupRules">
              <DashboardLayout>
                <HotelMarkUpRules />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.hotelContentManager}
          element={
            <ProtectedRoute I="view" a="ContentManager">
              <HotelContentManager />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.hotelDetailContentItem}
          element={
            <ProtectedRoute I="view" a="DetailContentManger">
              <HotelDetailContentManger />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.hotelsRoutingRules}
          element={
            <ProtectedRoute I="view" a="RoutingRules">
              <DashboardLayout>
                <HotelRoutingRules />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.hotelNewMarkupRule}
          element={
            <ProtectedRoute I="create-edit" a="MarkupRules">
              <HotelCreateMarkupRule />
            </ProtectedRoute>
          }
        />

        {/* TODO: add missing protected route */}
        <Route
          path={routes.users}
          element={
            <DashboardLayout>
              <Users />
            </DashboardLayout>
          }
        />
      </Route>
      <Route path="/" element={<Layout noLargeDownHeader />}>
        <Route element={<Sublayout noPayments noLargeDownMt noFooter />}>
          <Route path={routes.hotelsAvailability} element={<HotelsAvailability />} />
        </Route>
      </Route>
    </Route>,
  ),
);
