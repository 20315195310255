import { Control, Controller } from 'react-hook-form';
import { FormControl, OutlinedInput, OutlinedInputProps, FormHelperText } from '@mui/material';

type ControlledTextFieldProps = OutlinedInputProps & {
  control: Control<any, any>;
  name: string;
  required?: boolean;
  errorMessage?: string;
  helperText?: string;
};

export const ControlledTextField = ({
  helperText,
  control,
  name,
  required,
  errorMessage,
  ...props
}: ControlledTextFieldProps) => (
  <Controller
    control={control}
    name={name}
    rules={{ required: Boolean(required) }}
    render={({ field: { onChange, onBlur, value = '', ref }, fieldState: { error } }) => (
      <>
        <FormControl error={Boolean(error)} sx={{ width: props.fullWidth ? '100%' : 'auto' }}>
          <OutlinedInput
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
            {...props}
            aria-describedby={`component-error-text-${name}`}
          />
          {error && errorMessage && <FormHelperText id={`component-error-text-${name}`}>{errorMessage}</FormHelperText>}
          {helperText && !(error && errorMessage) && (
            <FormHelperText id={`component-error-text-${name}`}>{helperText}</FormHelperText>
          )}
        </FormControl>
      </>
    )}
  />
);
