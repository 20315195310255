import { Box, SwipeableDrawer, Typography, useTheme, Divider, Button, Avatar, IconButton } from '@mui/material';
import { Hotel, MoreVert, Place, Stars } from '@mui/icons-material';
import grey from '@mui/material/colors/grey';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Carousel } from 'react-material-ui-carousel/dist/components/Carousel';
import { useTranslation } from 'react-i18next';

import { RatingStars } from '../HotelAvailabilityCard/starHook';
import { useProperty } from '../../hooks/useProperty';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';

interface HotelPropertyMapDrawerProps {
  amenities: ReactNode[];
  carouselImages: string[];
  name: string;
  starAmount: number;
  hotelDescription: string;
  distanceHint: string;
  valueAmount: string;
  benefits?: ReactNode[];
}

const drawerBleeding = 56;

export const HotelPropertyMapDrawer = ({ ...props }: HotelPropertyMapDrawerProps) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation();
  const { property, handleDeselectProperty } = useProperty();
  const { amenities, carouselImages, starAmount, hotelDescription, distanceHint, benefits } = props;
  const { isSmallUp } = useBreakpoints();

  const toggleDrawer = () => setOpen((prevState) => !prevState);
  const onCloseDrawer = () => {
    handleDeselectProperty();
    toggleDrawer();
  };

  useEffect(() => {
    property ? setOpen(true) : setOpen(false);
  }, [property]);

  const imagesComponents = useMemo(
    () =>
      carouselImages.map((image, index) => (
        <img src={image} key={`Image ${index}`} style={{ width: '66%', borderRadius: theme.spacing(1) }} />
      )),
    [carouselImages],
  );

  const name = property?.name || 'No name found';

  return (
    <SwipeableDrawer
      anchor={'bottom'}
      open={open}
      onClose={onCloseDrawer}
      onOpen={toggleDrawer}
      ModalProps={{
        keepMounted: true,
      }}
      swipeAreaWidth={drawerBleeding}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
        }}
      >
        <Box width={26} height={2} bgcolor={grey[900]} borderRadius={3} mt={1} />
        <Box
          display="flex"
          alignSelf="stretch"
          alignItems="center"
          padding={isSmallUp ? theme.spacing(1, 4) : theme.spacing(1, 2)}
        >
          <Avatar
            variant="circular"
            sx={{ background: 'none', color: 'primary.dark', border: '2px solid', marginRight: theme.spacing(2) }}
          >
            <Hotel />
          </Avatar>
          <Typography flex="1 0 0" variant="body1">
            {t('hotels.propertyMapDrawer.hotelDetails')}
          </Typography>
          <IconButton aria-label="" onClick={undefined}>
            <MoreVert color="disabled" />
          </IconButton>
        </Box>
      </Box>
      <Divider flexItem />
      <Box
        display="flex"
        overflow="auto"
        flexDirection="column"
        alignItems="center"
        pb={2}
        gap={2}
        padding={theme.spacing(3, 0)}
      >
        <Box
          display="flex"
          padding={isSmallUp ? theme.spacing(0, 4) : theme.spacing(0, 2)}
          flexDirection="column"
          alignItems="flex-start"
          alignSelf="stretch"
          gap={2}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" color="initial">
              {name}
            </Typography>
            <Box display="flex" alignItems="center">
              <RatingStars rating={starAmount} />
              <Typography variant="body1" color="gray">
                {starAmount} stars
              </Typography>
            </Box>
          </Box>
          {isSmallUp && imagesComponents && imagesComponents.length >= 2 ? (
            <Box display="flex" gap={1} alignSelf="stretch">
              {imagesComponents[0]}
              <Box display="flex" flexDirection="column" gap={1} flex="1" justifyContent="space-between">
                {imagesComponents[1]}
                {imagesComponents[2]}
              </Box>
            </Box>
          ) : (
            <Carousel sx={{ width: '100%' }}>
              {carouselImages.map((image, index) => (
                <img src={image} key={`Image ${index}`} style={{ width: '100%', height: '100%' }} />
              ))}
            </Carousel>
          )}
          <Box display="flex" flexDirection="column" color="gray">
            <Box display="flex" gap={1}>
              <Stars />
              <Typography variant="body1">{hotelDescription}</Typography>
            </Box>
            <Box display="flex" gap={1}>
              <Place />
              <Typography variant="body1"> {distanceHint}</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          padding={isSmallUp ? theme.spacing(0, 4) : theme.spacing(0, 2)}
          display="flex"
          justifyContent={isSmallUp ? 'flex-start' : 'space-between'}
          gap={isSmallUp ? 3 : 0}
          alignSelf="stretch"
          color="gray"
        >
          {amenities}
        </Box>
        {benefits && (
          <Box
            padding={isSmallUp ? theme.spacing(0, 4) : theme.spacing(0, 2)}
            display="flex"
            flexDirection="column"
            alignSelf="stretch"
            color="gray"
          >
            {benefits.map((element, index) => (
              <Box display="flex" gap={1} key={`Benefit ${index}`}>
                {element}
              </Box>
            ))}
          </Box>
        )}
        <Divider flexItem sx={{ margin: theme.spacing(0, 2) }} />
        <Box
          padding={isSmallUp ? theme.spacing(0, 4) : theme.spacing(0, 2)}
          display="flex"
          flexDirection="column"
          alignSelf="stretch"
        >
          <Typography variant="body1" color="gray">
            {t('hotels.propertyMapDrawer.value')}
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" color="primary">
              {t('common.currencies.usd')} {property ? property.price : '0'}
            </Typography>
            <Button variant="contained" color="primary">
              {t('hotels.propertyMapDrawer.reserveButton')}
            </Button>
          </Box>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};
