import { Button, Typography, styled } from '@mui/material';

export const MyTypography = styled(Typography)(() => ({
  textTransform: 'none',
  whiteSpace: 'nowrap',
}));

export const MyButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  height: 40,
  padding: theme.spacing(0, 2),
  gap: theme.spacing(1),
  borderRadius: theme.spacing(1),
}));

export const calendarClass = (backgroundColor: string, color: string) => ({
  '.MuiInputBase-root': {
    backgroundColor,
    color,
    flex: '1 0 0',
  },
});
