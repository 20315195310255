import { useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { OptionState } from '../../../hotel/components/HotelDetailContentManager/constants';

interface Props {
  options: OptionState[];
  placeholder: string;
}

const getStyles = (state: string, options: string[], theme: Theme) => ({
  fontWeight: options.includes(state) ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
});

export const DropdownSelect = ({ options, placeholder }: Props) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;

    setSelectedOptions(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div>
      <FormControl sx={{ width: 220 }}>
        <Select
          displayEmpty
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography variant="subtitle2" textAlign="start" color={theme.palette.text.disabled}>
                  {placeholder}
                </Typography>
              );
            }
            return selected.join(', ');
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((state) => (
            <MenuItem key={state} value={state} style={getStyles(state, selectedOptions, theme)}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
