import { createContext, useCallback, useState } from 'react';

export type SidebarContextState = {
  isOpen: boolean;
  handleSidebarOpening: () => void;
  headerHeight?: number;
  handleHeaderHeightChange: (height?: number) => void;
};

export const SidebarContext = createContext<SidebarContextState>({} as SidebarContextState);

interface SidebarContextProviderProps {
  children: JSX.Element;
}

export const SidebarContextProvider = ({ children }: SidebarContextProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);

  const handleSidebarOpening = useCallback(() => setIsOpen((currentState) => (currentState ? false : true)), [isOpen]);

  const handleHeaderHeightChange = useCallback((height?: number) => setHeaderHeight(height), []);

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        handleSidebarOpening,
        headerHeight,
        handleHeaderHeightChange,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
