import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, IconButton, InputBase, Paper, Tab, Typography, useTheme } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { routes } from '../../../../router/routes';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { hotelArray } from './constants';
import { MarkupRulesList } from './MarkupRulesList';

export const HotelMarkUpRules = () => {
  const [value, setValue] = useState('2');
  const navigate = useNavigate();
  const theme = useTheme();
  const { isLargeUp } = useBreakpoints();

  const handleGoHome = () => {
    navigate('/');
  };
  const { t } = useTranslation();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  const handleNewRules = () => navigate(routes.hotelNewMarkupRule);

  return (
    <Container>
      <Box width="100%" pt={isLargeUp ? 10 : 20} pb={8}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleGoHome}>
          {t('hotels.markupRules.buttonBackHome')}
        </Button>

        <Box display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
            <Typography variant="h5" color={theme.palette.text.primary}>
              {t('hotels.markupRules.title')}
            </Typography>
          </Box>

          <Box
            sx={{
              width: '100%',
              margin: theme.spacing(5, 0),
              borderBottom: `1px solid ${theme.palette.text.disabled}`,
            }}
          >
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
              >
                <Tab
                  label={t('hotels.markupRules.optionFlights')}
                  style={{ color: theme.palette.text.primary }}
                  value="1"
                />
                <Tab
                  label={t('hotels.markupRules.optionHotels')}
                  style={{ color: theme.palette.text.primary }}
                  value="2"
                />
                <Tab
                  label={t('hotels.markupRules.optionExplorer')}
                  style={{ color: theme.palette.text.primary }}
                  value="3"
                />
              </TabList>
            </TabContext>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
            <Typography variant="subtitle1" color={theme.palette.text.primary}>
              {t('hotels.markupRules.listTitle')}
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              margin={`${theme.spacing(3)} 0`}
            >
              <Paper
                component="form"
                sx={{
                  p: '2 4',
                  display: 'flex',
                  alignItems: 'center',
                  width: '517px',
                  backgroundColor: deepPurple[50],
                }}
              >
                <IconButton type="button" sx={{ p: theme.spacing(1) }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={t('hotels.markupRules.search')}
                  inputProps={{ 'aria-label': t('hotels.markupRules.search') }}
                />
              </Paper>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleNewRules}
                sx={{ height: theme.spacing(5) }}
              >
                {t('hotels.markupRules.buttonNewRule')}
              </Button>
            </Box>
            <Typography variant="body2" color={theme.palette.text.secondary} margin={theme.spacing(3, 0)}>
              {hotelArray.length} {t('hotels.markupRules.numberOfResults')} | ({hotelArray.length})
              {t('hotels.markupRules.shownElements')}
            </Typography>
          </Box>

          <MarkupRulesList />
        </Box>
      </Box>
    </Container>
  );
};
