import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languages } from './languages/languages';

import './index.css';
//@ts-ignore
import { Providers } from './features/common/components/Providers';

// @ts-ignore
window.global ||= window;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources: languages,
    fallbackLng: 'en', // Fallback language
  });

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    // TODO: meter esto en los secretes de AWS
    dsn: 'https://4dbfec9a5aff9a795531314e5dbf5f62@o4505947096875008.ingest.sentry.io/4505947110047744',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'https://beta.mevuelo.com',
          // /^https:\/\/yourserver\.io\/api/ TO Be added in the future
        ],
      }),
      new Sentry.Replay(),
    ],
    //  Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Providers />
  </React.StrictMode>,
);
