import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Cookie from 'js-cookie';
import { Backdrop, CircularProgress } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { MongoQuery, MongoAbility, AbilityTuple } from '@casl/ability';

import { IMyProfileData, UserRoles } from '../../types';
import { useGetMyUserData } from '../../queries';
import { QueryKeys } from '../../../common/types';
import { EditUser } from '../EditUser';
import { abilities } from '../../abilities';

export type AuthContextState = {
  checkIsLogged: () => void;
  doSignOut: () => void;
  doSignIn: () => void;
  isAuthenticated: boolean;
  toggleLoginDrawer: () => void;
  toggleEditUser: () => void;
  showLoginDrawer: boolean;
  myProfile: IMyProfileData | null;
  ability: false | MongoAbility<AbilityTuple, MongoQuery>;
};

export const AuthContext = createContext<AuthContextState>({} as AuthContextState);
interface Props {
  children: JSX.Element;
}
export const AuthContextProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const [showLoginDrawer, setShowLoginDrawer] = useState(false);
  const [myProfile, setMyProfile] = useState<IMyProfileData | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [showCreateUser, setShowCreateUser] = useState<boolean>(false);

  const ability = useMemo(() => {
    if (myProfile?.groups?.length) {
      const myGroups = myProfile?.groups;

      if (myGroups.includes(UserRoles.ADMINS)) {
        return abilities[UserRoles.ADMINS];
      }

      if (myGroups.includes(UserRoles.OPERATORS)) {
        return abilities[UserRoles.OPERATORS];
      }

      if (myGroups.includes(UserRoles.AGENTS)) {
        return abilities[UserRoles.AGENTS];
      }

      return abilities[UserRoles.REGISTERED];
    }

    return false;
  }, [myProfile]);

  const checkUserIsCreated = useCallback(
    (error: AxiosError) => {
      if (error.response?.status === 404) {
        // no user in our db show complete user creation drawer
        setShowCreateUser(true);
      }
    },
    [setShowCreateUser],
  );

  const { data: userData, isLoading } = useGetMyUserData({
    onErrorCallback: checkUserIsCreated,
  });

  const doSignOut = useCallback(() => {
    Cookie.remove('idToken');

    window.location.href = `${import.meta.env.VITE_COGNITO_AUTH_URL}/logout?client_id=${
      import.meta.env.VITE_COGNITO_CLIENT_ID
    }&logout_uri=${encodeURIComponent(`${window.location.origin}/`)}`;
  }, []);

  const doSignIn = useCallback(() => {
    window.location.href = `${import.meta.env.VITE_COGNITO_AUTH_URL}/login?client_id=${
      import.meta.env.VITE_COGNITO_CLIENT_ID
    }&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(`${window.location.origin}/`)}`;
  }, []);

  const checkIsLogged = useCallback(async () => {
    queryClient.invalidateQueries({ queryKey: [QueryKeys.getMyUserData] });
  }, [queryClient]);

  const toggleLoginDrawer = useCallback(() => setShowLoginDrawer((prevState) => !prevState), []);

  const toggleEditUser = useCallback(() => setShowCreateUser((prevState) => !prevState), []);

  useEffect(() => {
    if (userData) {
      setMyProfile(userData ? (userData?.data as IMyProfileData) : null);

      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userData]);

  useEffect(() => {
    const checkCognitoCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        const token_params = {
          grant_type: 'authorization_code',
          code,
          client_id: import.meta.env.VITE_COGNITO_CLIENT_ID,
          redirect_uri: `${window.location.origin}/`,
        };

        const token_endpoint = `${import.meta.env.VITE_COGNITO_AUTH_URL}/oauth2/token`;

        const result = await axios.post(token_endpoint, new URLSearchParams(token_params), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (result.data.id_token) {
          Cookie.set('idToken', result.data.id_token);

          setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: [QueryKeys.getMyUserData] });
          }, 1000);
        }
      }
    };

    checkCognitoCode();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        doSignIn,
        doSignOut,
        isAuthenticated,
        checkIsLogged,
        toggleEditUser,
        toggleLoginDrawer,
        showLoginDrawer,
        myProfile,
        ability,
      }}
    >
      {children}
      <EditUser isOpen={showCreateUser} onClose={toggleEditUser} initialData={myProfile} />

      <Backdrop
        sx={{ color: (theme) => theme.palette.common.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  );
};
