import { Alert, Slide, Snackbar } from '@mui/material';
import { createContext, useCallback, useContext, useState } from 'react';

import { snackBarDisplayTime } from '../../constants';

interface SnackBarData {
  message: string;
  color: SnackBarColor;
}

interface SnackBarState extends SnackBarData {
  open: boolean;
}

type SnackBarColor = 'error' | 'warning' | 'info' | 'success';

export type SnackBarContextState = {
  addToast: (value: SnackBarData) => void;
};

interface ProviderProps {
  children: JSX.Element;
}

export const SnackBarContext = createContext<SnackBarContextState>({} as SnackBarContextState);

export const SnackBarContextProvider = ({ children }: ProviderProps) => {
  const [data, setData] = useState<SnackBarState | null>(null);

  const addToast = useCallback((snackData: SnackBarData) => setData({ ...snackData, open: true }), [setData]);

  const handleClose = () => {
    setData(null);
  };

  return (
    <SnackBarContext.Provider value={{ addToast }}>
      {children}
      <Snackbar
        open={data?.open}
        autoHideDuration={snackBarDisplayTime}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
      >
        <Alert severity={data?.color}>{data?.message}</Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = () => {
  const context = useContext(SnackBarContext);
  return { ...context };
};
