import React, { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { PlaceMarker } from '../../../common/components/PlaceMarker/PlaceMarker';
import { useProperty } from '../../hooks/useProperty';

export interface MarkerComponent {
  element: { name: string; description: string; price: string };
  options: google.maps.marker.AdvancedMarkerElementOptions;
}

export interface MapWithMarkersProps extends google.maps.MapOptions {
  markers: MarkerComponent[];
  style?: React.CSSProperties;
}

function buildContent(element: string) {
  const content = document.createElement('div');
  content.innerHTML = element;
  return content;
}

export const MapWrapper: React.FC<MapWithMarkersProps> = ({ ...props }: MapWithMarkersProps) => {
  const { markers, zoom, center, style } = props;
  const { handleSetProperty } = useProperty();

  const loader = new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  const initMap = async () => {
    await loader.importLibrary('maps');
    const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: center,
      zoom: zoom,
      mapId: import.meta.env.VITE_MAP_ID,
    });

    markers.forEach((markerOption) => {
      const marker = new AdvancedMarkerElement({
        map: map,
        position: markerOption.options.position,
        content: buildContent(PlaceMarker({ text: markerOption.element.price })),
      });

      marker.addListener('click', () => {
        handleSetProperty({ name: markerOption.element.name, price: markerOption.element.price });
      });
    });
  };

  useEffect(() => {
    initMap();
  }, [markers]);

  return <div id="map" style={style} />;
};
