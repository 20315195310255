import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useBreakpoints } from '../../features/common/hooks/useBreakpoints';
import { routes } from '../routes';
import { MobileAvailability } from './mobileAvailability';
import { DesktopAvailability } from './desktopAvailability';

//@todo: supongo que esto luego debe moverse a un mock y ser dinamico
const center: google.maps.LatLngLiteral = { lat: -34.565517, lng: -58.4443019 };
const zoom = 15;

export const HotelsAvailability = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLargeDown, isSmallUp } = useBreakpoints();

  const handleBack = () => navigate(routes.hotels, { replace: true });
  const handleEdit = () => setOpenEdit((prevState) => !prevState);
  const handleTune = () => setOpenFilter((prevState) => !prevState);
  const handleMap = () => setOpenMap((prevState) => !prevState);

  return (
    <Box display="flex" flexDirection="column">
      {isLargeDown ? (
        <MobileAvailability
          isSmallUp={isSmallUp}
          openMap={openMap}
          zoom={zoom}
          center={center}
          t={t}
          theme={theme}
          openEdit={openEdit}
          openFilter={openFilter}
          handleMap={handleMap}
          handleBack={handleBack}
          handleEdit={handleEdit}
          handleTune={handleTune}
        />
      ) : (
        <DesktopAvailability openMap={openMap} zoom={zoom} center={center} t={t} handleMap={handleMap} />
      )}
    </Box>
  );
};
