import { useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, List, ListItemText, Typography, useTheme } from '@mui/material';

import { hotelArray, title } from './constants';
import { MarkupRulesItem } from './MarkupRulesItem';

export const MarkupRulesList = () => {
  const [hotelData, setHotelData] = useState(hotelArray);

  const theme = useTheme();

  const resultLength = [];
  for (let i = 0; i < hotelArray.length; i++) {
    resultLength.push(i);
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    const oldIndex = hotelData.findIndex((hotel) => hotel.id === active.id);
    const newIndex = hotelData.findIndex((hotel) => hotel.id === over.id);

    const newOrder = arrayMove(hotelData, oldIndex, newIndex);
    setHotelData(newOrder);
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={hotelData} strategy={verticalListSortingStrategy}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            boxShadow: theme.shadows[1],
            borderRadius: theme.spacing(2),
          }}
        >
          <Box display="flex">
            {title.map((nameColumn, index) => (
              <ListItemText key={index} sx={{ color: theme.palette.text.primary }}>
                <Typography variant="subtitle1">{nameColumn}</Typography>
              </ListItemText>
            ))}
          </Box>

          {resultLength.map((value, index) => (
            <MarkupRulesItem key={index} value={value} hotelData={hotelData} />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
};
