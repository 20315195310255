import { useState, SyntheticEvent } from 'react';
import { Divider, useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { HotelContentDescriptionEditor } from '../HotelContentDescriptionEditor';
import { HotelContentProvaiderDescription } from '../HotelContentProviderDescription';
import { labelTabs } from './constants';

export const MenuTabs = () => {
  const [value, setValue] = useState('one');

  const theme = useTheme();

  const handleChange = (_event: SyntheticEvent, newValue: string) => setValue(newValue);

  return (
    <>
      <Tabs
        sx={{ display: 'flex', justifyContent: 'space-around' }}
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
        variant="fullWidth"
      >
        {labelTabs.map((tab, index) => (
          <Tab key={`${index}-${tab.value}`} value={tab.value} label={tab.label} color={theme.palette.text.secondary} />
        ))}
      </Tabs>
      <Divider flexItem />
      {value === 'one' && (
        <>
          <HotelContentDescriptionEditor />
          <HotelContentProvaiderDescription />
        </>
      )}
    </>
  );
};
