export const data = [
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 1,
    provider: 'Proveedor 1',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 2,
    provider: 'Proveedor 2',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 3,
    provider: 'Proveedor 3',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 4,
    provider: 'Proveedor 4',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 5,
    provider: 'Proveedor 5',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 6,
    provider: 'Proveedor 6',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 7,
    provider: 'Proveedor 7',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 8,
    provider: 'Proveedor 8',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 9,
    provider: 'Proveedor 9',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 10,
    provider: 'Proveedor 10',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 11,
    provider: 'Proveedor 11',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 12,
    provider: 'Proveedor 12',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 13,
    provider: 'Proveedor 13',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 14,
    provider: 'Proveedor 14',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 15,
    provider: 'Proveedor 15',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 16,
    provider: 'Proveedor 16',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 17,
    provider: 'Proveedor 17',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 18,
    provider: 'Proveedor 18',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: true,
    order: 19,
    provider: 'Proveedor 19',
  },
  {
    imgUrl: 'https://picsum.photos/200',
    active: false,
    order: 20,
    provider: 'Proveedor 20',
  },
];

export const amenities = [
  {
    value: '1',
    text: 'Pileta',
  },
  {
    value: '2',
    text: 'Solarium',
  },
  {
    value: '3',
    text: 'Habitación compartida',
  },
  {
    value: '4',
    text: 'Gimnasio',
  },
  {
    value: '5',
    text: 'Estacionamiento',
  },
  {
    value: '6',
    text: 'Wifi gratuito',
  },
  {
    value: '7',
    text: 'Desayuno incluido',
  },
  {
    value: '8',
    text: 'Servicio de limpieza diario',
  },
];
