import { MarkerComponent } from '../../../features/hotel/components/MapWrapper/MapWrapper';

export const markers: MarkerComponent[] = [
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.564517, lng: -58.4443019 } },
  },
  {
    element: { name: 'Fake hotel', description: 'Hotel 2 estrellas', price: 'USD 217' },
    options: { position: { lat: -34.565517, lng: -58.4543019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.566517, lng: -58.4643019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.564517, lng: -58.4343019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.565517, lng: -58.4243019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.566517, lng: -58.4143019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.554517, lng: -58.4443019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.545517, lng: -58.4543019 } },
  },
  {
    element: { name: 'Mykonos Town Boutique Hotel', description: 'Hotel 5 estrellas', price: 'USD 127' },
    options: { position: { lat: -34.536517, lng: -58.4643019 } },
  },
];
