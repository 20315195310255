import LocalParkingIcon from '@mui/icons-material/LocalParking';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import WifiIcon from '@mui/icons-material/Wifi';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

const iconsHotelAvailabilityCard = {
  LocalParking: LocalParkingIcon,
  FreeBreakfast: FreeBreakfastIcon,
  Wifi: WifiIcon,
  Pool: PoolIcon,
  FitnessCenter: FitnessCenterIcon,
};

export const iconArray = Object.values(iconsHotelAvailabilityCard);

export const availabilityCards = [
  {
    title: 'Mykonos Town Boutique H...',
    rating: 4.2,
    numberOfOpinions: 1500,
    img: 'https://picsum.photos/seed/picsum/450/300',
    proximity: 'A 200mts de Oklahoma St. 2354',
    localCurrency: 'PY',
    price: 120,
    cancellationPolicy: 'Cancelación gratis',
    paymentPolicy: 'Sin pago adelantado',
  },
  {
    title: 'Sunset Resort',
    rating: 3.8,
    numberOfOpinions: 2000,
    img: 'https://picsum.photos/seed/picsum/450/300',
    proximity: 'A 200mts de Oklahoma St. 2354',
    localCurrency: 'UY',
    price: 90,
    cancellationPolicy: 'No cancelación gratis',
    paymentPolicy: 'Sin pago adelantado',
  },
  {
    title: 'Ocean View Suites Reso...',
    rating: 4.7,
    numberOfOpinions: 3000,
    img: 'https://picsum.photos/seed/picsum/450/300',
    proximity: 'A 200mts de Oklahoma St. 2354',
    localCurrency: 'PY',
    price: 1500,
    cancellationPolicy: 'Cancelación gratis',
    paymentPolicy: 'Pago adelantado requerido',
  },
  {
    title: 'Mountain Retreat Hotel',
    rating: 3.5,
    numberOfOpinions: 1000,
    img: 'https://picsum.photos/seed/picsum/450/300',
    proximity: 'A 200mts de Oklahoma St. 2354',
    localCurrency: 'USD',
    price: 80000,
    cancellationPolicy: 'No cancelación gratis',
    paymentPolicy: 'Pago adelantado requerido',
  },
];
