export const espanol = {
  auth: {
    login: {
      errorMessage: 'Usuario o password invalido!',
      title: 'Iniciá sesión',
      emailPlaceHolder: 'Correo electrónico',
      passwordPlaceHolder: 'Contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      fieldErrorMessage: 'Este campo es requerido',
      signinButton: 'Ingresar',
      createAccountText: '¿No tenés cuenta?',
      createAccountLink: 'Registrate',
      startWithSocial: 'Iniciar con',
    },
    editUser: {
      title: 'Editar datos personales',
      subtitle: 'Ingresa los datos como aparecen en tu documento de identificación o pasaporte.',
      failMessage: 'Error al actualizar el usuario',
      firstName: {
        placeHolder: 'Nombres',
        errorMessage: 'Campo requerido',
        helperText: 'Tal cual figura en su DNI',
      },
      lastName: {
        placeHolder: 'Apellidos',
        errorMessage: 'Campo requerido',
        helperText: 'Tal cual figura en su DNI',
      },
      phoneCountryCode: {
        placeHolder: 'Codigo de area',
        errorMessage: 'Campo requerido',
      },
      phoneNumber: {
        placeHolder: 'Numero de telefono',
        errorMessage: 'Campo requerido',
      },
      address1: {
        placeHolder: 'Direccion',
        errorMessage: 'Campo requerido',
        helperText: 'Ingrese su direccion',
      },
      address2: {
        placeHolder: '',
        errorMessage: 'Campo requerido',
        helperText: '2nda linea de direccion (opcional)',
      },
      country: {
        placeHolder: 'Pais',
        errorMessage: 'Campo requerido',
      },
      city: {
        placeHolder: 'Ciudad',
        errorMessage: 'Campo requerido',
      },
      postalCode: {
        placeHolder: 'Codigo Postal',
        errorMessage: 'Campo requerido',
      },
      documentType: {
        placeHolder: 'Tipo de documento',
        errorMessage: 'Campo requerido',
        helperText: 'Tipo de documento',
      },
      documentNumber: {
        placeHolder: 'Numero de documento',
        errorMessage: 'Campo requerido',
        helperText: 'Tal cual figura en el documento',
      },
      applyChangesBtn: 'Aplicar',
      patchSuccess: 'Usuario actualizado con éxito!',
    },
  },
  common: {
    welcomeMessage: 'Hola mundo! este es un mensaje en español de bienvenida para: ',
    filters: 'Filtros',
    nav: {
      flights: 'Vuelos',
      hotels: 'Hoteles',
      cars: 'Autos',
      explorer: 'Explorar',
      dashboard: 'Dashboard',
      userMenu: {
        userIdentifier: 'Invitado',
        disconnectedMessage: 'No has iniciado sesión',
        myFavorites: 'Mis favoritos',
        myNotifications: 'Notificaciones',
        myBookings: 'Mis reservas',
        admin: 'Admin',
        routingRules: 'Routing rules',
        markupRules: 'Reglas de margenes',
        userList: 'Listado de usuarios',
        contentManager: 'Gestor de Contenidos',
        accountMessage: 'Cuenta',
        currentCurrency: 'Moneda',
        currentLanguage: 'Idioma',
        help: 'Ayuda',
        loginMesssage: 'Iniciar sesión',
        logoutMesssage: 'Cerrar sesión',
        selectedLanguage: 'Español',
      },
    },
    footer: {
      companyType: 'Agencia de viajes registrada',
      identity: 'Real Consulting S.A. Resolución Registur N° 1263/2019',
      contactMessage: 'Contactanos',
      company: {
        title: 'Empresa',
        about: 'Sobre Nosotros',
        terms: 'Términos y Condiciones',
        privacy: 'Políticas de Privacidad',
        join: 'Sumate al Equipo',
      },
      account: {
        title: 'Cuenta',
        data: 'Mis Datos',
        favourites: 'Mis Favoritos',
        alerts: 'Mis Alertas',
        reserves: 'Mis Reservas',
        close: 'Cerrar Sesión',
      },
      platform: {
        title: 'Plataforma',
        currency: 'Moneda',
        language: 'Idioma',
        rates: 'Rates Tracker',
        rules: 'Markup Rules',
        travelers: 'Mis Viajeros',
        airlines: 'ABM Aerolineas',
        cabines: 'ABM Cabinas',
        alliances: 'ABM Alianzas',
      },
      payments: {
        certification: {
          title: 'Certificación de seguridad',
          description:
            'Mevuelo.com se encuentra certificado por el consejo de normas de seguridad para tus medios de pago.',
        },
        intelligentTravel: {
          title: 'Viajá inteligente y gastá menos',
          description:
            'Descubrí precios inmejorables en vuelos, hoteles y autos, haciendo de cada viaje una aventura económica.',
        },
        explore: {
          title: 'Explorá más, preocupate menos',
          description:
            'Con Mevuelo, viajar es más fácil que nunca. Personalizá tu viaje como quieras, empacá tus sueños y nosotros nos encargamos del resto.',
        },
        paymentMethods: {
          title: 'Pagá con el método que más te guste',
          credit: 'Tarjetas de crédito',
          bankTransfers: 'Transferencias bancarias',
          cash: 'Efectivo en cualquiera de nuestras sucursales',
        },
        altImages: {
          visa: 'logo de la tarjeta visa',
          oca: 'logo de la tarjeta oca',
          mastercard: 'logo de la tarjeta mastercard',
          american: 'logo de la tarjeta american',
          securityEncryption: 'imagen caja de seguridad',
          wallet: 'imagen billetera',
          backpack: 'imagen mochila',
          card: 'icono de tarjeta',
          bank: 'icono de banco',
          cash: 'icono de dinero',
        },
      },
      help: {
        title: 'Ayuda',
        faq: 'FAQ’s',
      },
    },
    priceTrend: {
      message: 'Tendencia de precios',
    },
  },
  flights: {
    searcher: {
      ariaLabelSearcher: 'Motor de vuelos',
      oneWay: 'Solo ida',
      roundTrip: 'Ida y vuelta',
      multidestination: 'Multidestino',
      origin: 'Origen',
      destination: 'Destino',
      go: 'Ida',
      return: 'Vuelta',
      unknownDates: 'Todavía no decido las fechas',
      economic: 'Economica',
      guest: 'Pasajero',
      search: 'Buscar',
      addSection: '+ Añadir tramo',
      section: 'Tramo {{amount}}',
    },
  },
  hotels: {
    desktopAvailability: {
      viewList: 'Ver lista',
    },
    searcher: {
      title: 'Búsqueda de hoteles',
      destination: 'Destino',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      unknownDates: 'Todavía no decido las fechas',
      room: 'Habitaciones',
      guest: 'Huéspedes',
      search: 'Búsqueda',
      apply: 'Aplicar',
    },
    roomMenu: {
      roomAmount: 'Cantidad',
      room: 'Habitación',
      year: 'año',
      years: 'años',
      adults: 'Adultos',
      kids: 'Niños',
      infants: 'Bebés',
      apply: 'Aplicar',
    },
    cardsDestination: {
      seeMore: 'Ver más',
      from: 'Desde',
    },
    cardPackage: {
      doubleBaseText: 'Valor por persona en base doble',
      fromText: 'Desde',
      reserveButtonText: 'Reservar',
      hotelRating: 'Hotel {{stars}} estrellas',
      nightsOfDuration: '{{amount}} noches',
    },
    filter: {
      filters: 'Filtros',
      orderByAriaLabel: 'Grupo de filtros para ordenar por',
      orderBy: 'Ordenar por',
      recommended: 'Recomendados',
      bestPrice: 'Mejor precio',
      moreReviews: 'Más opiniones',
      score: 'Puntuación',
      name: 'Nombre',
      searchDistanceAriaLabel: 'Deslizador de distancia de búsqueda',
      searchDistance: 'Distancia de búsqueda',
      radiusMessage: 'En un radio de',
      popularFiltersAriaLabel: 'Popular filters group',
      popularFilters: 'Grupo de filtros populares',
      allInclusive: 'All inclusive',
      oceanView: 'Ocean view',
      hotelResort: 'Hotel resort',
      hotel: 'Hotel',
      freeCancel: 'Cancelación gratis',
      noPaymentInAdvance: 'Sin pago por adelantado',
      priceAriaLabel: 'Slider de rango de precios',
      price: 'Precio',
      travelersExperienceAriaLabel: 'Grupo de filtros de experiencia de viajeros',
      travelersExperience: 'Experiencia de viajeros',
      lgbtq: 'LGBTQ',
      businessFriendly: 'Business friendly',
      reducedMobility: 'Movilidad reducida',
      onlyAdults: 'Only adults',
    },
    availabilityLayout: {
      editSearchTitle: 'Editar búsqueda',
      avatarAriaLabel: 'Icono ávatar de cama de hotel',
      viewMapAriaLabel: 'Botón para ver mapa',
      tuneFiltersAriaLabel: 'Botón para ajustar filtros',
      results: 'resultados',
      viewMap: 'Ver mapa',
    },
    availabilityCard: {
      opinions: '{{numberOfOpinions}} opiniones',
      endValueText: 'Valor final total desde',
      hotelRating: 'Hotel {{rating}} estrellas',
      allInclusiveLabel: 'All inclusive',
      recommendedLabel: 'Recomendado',
    },
    markupRules: {
      buttonNewRule: '+ NUEVA REGLA',
      buttonBackHome: 'Volver al home',
      numberOfResults: 'Resultados',
      shownElements: 'Elementos mostrados',
      listTitle: 'Listado de reglas',
      search: 'Buscador',
    },
    routingRules: {
      buttonNewRule: '+ NUEVA REGLA',
      buttonBackHome: 'Volver al home',
      numberOfResults: 'Resultados',
      shownElements: 'Elementos mostrados',
      listTitle: 'Listado de reglas',
      search: 'Buscador',
    },
    newMarkUpRules: {
      newRule: 'Nueva Regla',
      newGroup: '+ NUEVO GRUPO',
      buttonBackList: 'Volver a la lista',
      aplicationRatesTrackers: 'Aplica para Rates Trackers',
      deleteGroup: 'ELiminar grupo',
      addCondition: '+ Agregar condición',
      addAction: '+ Agregar acción',
      relationshipGroups: 'Relación entre grupos',
      and: 'Y',
      or: 'O',
      name: 'Nombre',
      buttonDiscard: 'Descartar',
      buttonSave: 'Guardar',
      conditionalGroup: 'Grupo condicional',
    },
    propertyMapDrawer: {
      hotelDetails: 'Detalles del hotel',
      value: 'Valor final total desde',
      reserveButton: 'Reservar',
    },
    propertyMapCard: {
      details: 'Detalles',
      opinions: 'Opiniones',
      reserveButton: 'Reservar',
      popularAmenities: 'Comodidades populares',
    },
    contentManager: {
      title: 'Hotel Content Manager',
      configuration: 'Configuración',
      configurationDescription: 'Descripcion predeterminada',
      configurationUbication: 'Ubicación predeterminada',
      configurationRooms: 'Habitaciones predeterminada',
      configurationImages: 'Imágenes predeterminada',
      configurationAmenities: 'Amenities predeterminada',
      paginationRowsPerPage: 'Filas por pagina',
      hotelDetail: {
        titleCard: 'Resumen del hotel',
        buttonBackList: 'Volver al listado',
        value: 'Valor final total desde',
        buttonPreview: 'Vista previa',
        buttonSave: 'Guardar',
        selectPlaceholder: 'Estado',
      },
      hotelContentDescriptionEditor: {
        title: 'Mi descripción',
        buttonEverythingWithGpt: 'Generar todo con gpt',
        buttonGenerateWithGpt: 'Generar con gpt',
        placeholderHotelName: 'Nombre del hotel',
        placeholderStars: 'Hotel {{stars}} estrellas',
        placeholderDescription: 'Descripción del hotel',
      },
      hotelContentProviderDescription: {
        buttonSave: 'Guardar',
      },
      contentImages: {
        title: 'Imagenes del hotel',
        buttonSave: 'Guardar',
      },
    },
  },
  users: {
    list: 'Listado de usuarios',
    new: 'Nuevo usuario',
    search: 'Buscar usuario',
    filters: {
      userTypes: 'Tipos de usuario',
      propertyCard: {
        hotelDetails: 'Detalles del hotel',
        value: 'Valor final total desde',
        reserveButton: 'Reservar',
        contentManager: {
          title: 'Gestor de contenido de Hoteles',
          configuration: 'Configuracion',
          paginationRowsPerPage: 'Filas por pagina',
        },
      },
      roles: {
        AGENT: 'Agente',
        OPERATOR: 'Operador',
        TRAVEL_MANAGER: 'Gerente de Viajes',
      },
    },
  },
  error404: {
    message: 'La pagina que buscas no se encontro',
    btnLabel: 'Volver al incio',
  },
};
