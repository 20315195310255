import { Box, InputAdornment, Switch, Typography, useTheme, Button } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { MyButton, MyTypography, calendarClass } from './styles';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { ControlledTextField } from '../ControlledTextField';
import { ControlledFieldProp } from '../../types';
import { ControlledDatePicker } from '../ControlledDatePicker';

type CheckOption = {
  formFieldName: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  value: dayjs.Dayjs | null;
  handler: (value: Dayjs | null) => void;
  icon: React.ElementType;
};

interface Props {
  control: any; //@todo: fixit. This because Control<any,any> wont overlap with Control<MyInterface,any> by some reason.
  firstInput: ControlledFieldProp;
  darkMode?: boolean;
  sectionSubtitleMessage?: string | false;
  secondInput?: ControlledFieldProp | false;
  unknownDatesMessage?: string | false;
  addSectionMessage?: string | false;
  firstMenu?: ControlledFieldProp | false;
  secondMenu?: ControlledFieldProp | false;
  handleDisableChecks: () => void;
  handleAddSections?: () => void;
  handleToggleRoomMenu: (event?: React.MouseEvent<HTMLElement>) => void;
  checkInConfig: CheckOption;
  checkOutConfig?: CheckOption;
}

/**
 * @param control controller form
 * @param firstInput text and error for the first input.
 * @param handleDisableChecks handler for the unknown dates switchs.
 * @param handleAddSections handler for the add sections button.
 * @param handleToggleRoomMenu menu buttons action.
 * @param disabledChecks unkwown dates switchs state value.
 * @param darkMode optional to trigger dark color mode.
 * @param sectionSubtitleMessage optional to render text for the sections subtitle.
 * @param secondInput text and error for the second input.
 * @param unknownDatesMessage optional to render text for the unkowwn dates switch.
 * @param addSectionMessage optional to render text for the add sections button.
 * @param firstMenu optional to render text for the first menu.
 * @param secondMenu optional to render text for the second menu.
 * @param checkInConfig check-in configurations see: @CheckOption type
 * @param checkOutConfig check-out configurations see: @CheckOption type
 *
 * @returns a searcher layout widely used for hotels, flights, etc component.
 */

export const SearchLayout = ({
  control,
  firstInput,
  darkMode,
  secondInput,
  unknownDatesMessage,
  addSectionMessage,
  firstMenu,
  secondMenu,
  handleDisableChecks,
  handleToggleRoomMenu,
  handleAddSections,
  checkInConfig,
  checkOutConfig,
}: Props) => {
  const { isMediumUp, isLargeUp } = useBreakpoints();
  const theme = useTheme();
  const buttonColor = darkMode ? theme.palette.primaryAlternative.special : '';
  const backgroundColor = darkMode
    ? theme.palette.primaryAlternative.darkest
    : theme.palette.primaryAlternative.lighter;
  const typographyColor = darkMode ? theme.palette.primary.contrastText : 'black';
  const gap = isMediumUp ? 2 : 1;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={isLargeUp ? 'flex-start' : 'center'}
      flexDirection={!secondInput && isLargeUp ? 'row' : 'column'}
      alignSelf="stretch"
      flex="1 0 0"
      gap={gap}
    >
      <Box
        display="flex"
        justifyContent="center"
        gap={gap}
        alignSelf="stretch"
        flex="0.5 0 0"
        alignItems={isMediumUp ? 'flex-start' : 'center'}
        flexDirection={isMediumUp ? 'row' : 'column'}
      >
        <ControlledTextField
          name={firstInput.formFieldName}
          size="small"
          control={control}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{ color: darkMode ? theme.palette.primaryAlternative.special : 'primary.main' }}
            >
              {firstInput.icon}
            </InputAdornment>
          }
          placeholder={firstInput.text}
          errorMessage={firstInput.error}
          fullWidth
          sx={{
            backgroundColor: backgroundColor,
            color: darkMode ? theme.palette.primary.contrastText : '',
            flex: '1 0 0',
          }}
        />
        {/* @todo: add a switch button floating between these 2 */}
        {secondInput && (
          <ControlledTextField
            name={secondInput.formFieldName}
            size="small"
            control={control}
            startAdornment={
              <InputAdornment
                position="start"
                sx={{ color: darkMode ? theme.palette.primaryAlternative.special : 'primary.main' }}
              >
                {secondInput.icon}
              </InputAdornment>
            }
            placeholder={secondInput.text}
            errorMessage={secondInput.error}
            fullWidth
            sx={{
              backgroundColor: backgroundColor,
              color: darkMode ? theme.palette.primary.contrastText : '',
              flex: '1 0 0',
            }}
          />
        )}
      </Box>
      <Box display="flex" flexDirection={isLargeUp ? 'row' : 'column'} alignSelf="stretch" flex="1 0 0" gap={gap}>
        <Box display="flex" flexDirection="column" alignSelf="stretch" flex="1 0 0">
          <Box
            display="flex"
            flexDirection={isMediumUp ? 'row' : 'column'}
            justifyContent="center"
            alignItems="center"
            gap={gap}
          >
            {checkInConfig && (
              <ControlledDatePicker
                control={control}
                name={checkInConfig.formFieldName}
                disabledChecks={!!checkInConfig.disabled}
                handleAccept={checkInConfig.handler}
                value={checkInConfig.value}
                icon={checkInConfig.icon}
                sx={calendarClass(backgroundColor, darkMode ? theme.palette.primary.contrastText : '')}
              />
            )}
            {checkOutConfig && (
              <ControlledDatePicker
                control={control}
                name={checkOutConfig.formFieldName}
                disabledChecks={!!checkOutConfig.disabled}
                handleAccept={checkOutConfig.handler}
                value={checkOutConfig.value}
                icon={checkOutConfig.icon}
                sx={calendarClass(backgroundColor, darkMode ? theme.palette.primary.contrastText : '')}
              />
            )}
          </Box>

          {unknownDatesMessage && (
            <Box display="flex" alignItems="center" alignSelf="stretch">
              <Switch color={darkMode ? 'secondary' : 'primary'} onChange={handleDisableChecks} />
              <Typography color={darkMode ? theme.palette.primary.contrastText : 'GrayText'} variant="body2">
                {unknownDatesMessage}
              </Typography>
            </Box>
          )}
        </Box>

        {addSectionMessage && (
          <Box display="flex" alignItems="center" justifyContent="center" alignSelf="stretch">
            <Button size="small" sx={{ padding: 1 }} variant="text" color="success" onClick={handleAddSections}>
              {addSectionMessage}
            </Button>
          </Box>
        )}

        {firstMenu && secondMenu && (
          <Box display="flex" alignSelf="stretch" flex="1 0 0" gap={gap}>
            <MyButton
              sx={{
                backgroundColor: backgroundColor,
                color: buttonColor,
              }}
              fullWidth
              type="button"
              onClick={handleToggleRoomMenu}
            >
              {firstMenu.icon}
              <MyTypography variant="body2" color={typographyColor}>{`1 ${firstMenu.text}`}</MyTypography>
            </MyButton>
            <MyButton
              sx={{
                backgroundColor: backgroundColor,
                color: buttonColor,
              }}
              fullWidth
              type="button"
              onClick={handleToggleRoomMenu}
            >
              {secondMenu.icon}
              <MyTypography variant="body2" color={typographyColor}>{`1 ${secondMenu.text}`}</MyTypography>
            </MyButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
