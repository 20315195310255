import { DateRange, KingBed, Person } from '@mui/icons-material';
import Box from '@mui/material/Box/Box';
import Divider from '@mui/material/Divider/Divider';
import Typography from '@mui/material/Typography/Typography';

interface HotelAvailabilityTitleProps {
  destination: string;
  date: string;
  guestsAmount: string;
  roomAmount: string;
}

export const HotelAvailabilityTitle = ({
  destination,
  date,
  guestsAmount,
  roomAmount,
}: HotelAvailabilityTitleProps) => (
  <Box ml={2} display="flex" flexDirection="column" alignItems="flex-start" flex="1 0 0">
    <Box display="flex" alignItems="flex-start" gap={1}>
      <Typography variant="h6" color="primary.contrastText">
        {destination}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" gap={1} alignSelf="stretch">
      <Box display="flex" gap={1}>
        <DateRange />
        <Typography variant="body2" color="contrastText">
          {date}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'primary.contrastText' }} />
      <Box display="flex" alignItems="flex-start" gap={1}>
        <Person />
        <Typography variant="body2" color="contrastText">
          {guestsAmount}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem sx={{ backgroundColor: 'primary.contrastText' }} />
      <Box display="flex" alignItems="flex-start" gap={1}>
        <KingBed />
        <Typography variant="body2" color="contrastText">
          {roomAmount}
        </Typography>
      </Box>
    </Box>
  </Box>
);
