import { ReactNode } from 'react';
import { Box } from '@mui/material';

interface DesktopAvailabilityLayoutProps {
  filter: ReactNode;
  cardList: ReactNode;
  listHeader: ReactNode;
}

/** Desktop availability layout component. It renders the view of the filters and hotel cards searched.
 *	@param filter: center ReactNode for top toolbar
 *	@param cardList: on back function
 *	@param listHeader: optional map button to be on right of price trend
 */
export const DesktopAvailabilityLayout = ({ filter, cardList, listHeader }: DesktopAvailabilityLayoutProps) => (
  <Box display="flex" mt={4} justifyContent="center" alignItems="flex-start" gap={3}>
    <Box display="flex" maxWidth="250px" flexDirection="column" alignItems="center" gap={1}>
      {filter}
    </Box>
    <Box display="flex" flexDirection="column" alignItems="center" gap={4} flex="1 0 0">
      <Box display="flex" justifyContent="center" alignItems="flex-start" gap={3} alignSelf="stretch">
        {listHeader}
      </Box>
      {cardList}
    </Box>
  </Box>
);
