import { styled } from '@mui/material';

import { closedDrawerWidthRem, openDrawerWidthRem } from './constants';
import { DashboardOenIconButtonParent, CustomDrawer } from './helpers';

export const Drawer = styled(CustomDrawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'headerHeight',
})(({ theme, open, headerHeight }) => ({
  backgroundColor: 'red',
  marginTop: theme.spacing(8),
  '& .MuiDrawer-paper': {
    gap: theme.spacing(1),
    padding: open ? theme.spacing(3, 2) : theme.spacing(2, 1),
    width: `${openDrawerWidthRem}rem`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: headerHeight || theme.spacing(8),

    ...(!open && {
      width: `${closedDrawerWidthRem}rem`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  },
}));

export const OpenIconButton = styled(DashboardOenIconButtonParent, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen',
})(({ theme, isSidebarOpen }) => ({
  height: theme.spacing(4),
  width: theme.spacing(4),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transform: isSidebarOpen ? 'rotate(180deg)' : 'rotate(0)',
  transition: theme.transitions.create('transform', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));
