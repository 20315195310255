import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, InputBase, Typography, useTheme } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

import logoChatGpt from '../../../../assets/logoChatgpt.svg';

interface Props {
  icon: string;
  name: string;
  showStarsSelector?: boolean;
  hotelStar?: string;
}

export const CardDescription = ({ icon, name, showStarsSelector, hotelStar }: Props) => {
  const [hotelName, setHotelName] = useState<string>('');
  const [hotelStars, setHotelStars] = useState<string>('');
  const [hotelDescription, setHotelDescription] = useState<string>('');

  const theme = useTheme();
  const { t } = useTranslation();

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setHotelName(e.target.value);
  const onChangeStars = (e: ChangeEvent<HTMLInputElement>) => setHotelStars(e.target.value);
  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => setHotelDescription(e.target.value);

  return (
    <Box
      sx={{ backgroundColor: deepPurple[100], borderRadius: theme.spacing(2), padding: theme.spacing(2) }}
      margin={theme.spacing(3, 0)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center">
          <img src={icon} alt={`icon ${name}`} style={{ borderRadius: theme.spacing(1) }} />
          <Typography variant="body1" color={theme.palette.primary.main} marginLeft={theme.spacing(1)}>
            {name}
          </Typography>
        </Box>
        <Button size="large">
          <img src={logoChatGpt} alt="Logo GPT" style={{ paddingRight: theme.spacing(1) }} />
          {t('hotels.contentManager.hotelContentDescriptionEditor.buttonGenerateWithGpt')}
        </Button>
      </Box>
      <Box width="100%">
        <Grid container padding="0">
          <Grid
            item
            xs={12}
            md={12}
            lg={showStarsSelector ? 8 : 12}
            p={showStarsSelector ? `${theme.spacing(0, 1, 0, 0)}` : '0'}
          >
            <InputBase
              sx={{
                backgroundColor: deepPurple[50],
                paddingLeft: theme.spacing(1),
                borderRadius: theme.spacing(1),
                width: '100%',
                height: theme.spacing(5),
              }}
              value={hotelName}
              onChange={onChangeName}
              placeholder={t('hotels.contentManager.hotelContentDescriptionEditor.placeholderHotelName')}
            />
          </Grid>

          {showStarsSelector && (
            <Grid item sm={12} md={4} lg={4} padding="0">
              <InputBase
                sx={{
                  backgroundColor: deepPurple[50],
                  paddingLeft: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                  width: '100%',
                  height: theme.spacing(5),
                }}
                value={hotelStars}
                onChange={onChangeStars}
                placeholder={t('hotels.contentManager.hotelContentDescriptionEditor.placeholderStars', {
                  stars: hotelStar,
                })}
              />
            </Grid>
          )}
          <Grid item sm={12} md={12} lg={12} padding="0">
            <InputBase
              sx={{
                backgroundColor: deepPurple[50],
                paddingLeft: theme.spacing(1),
                marginTop: theme.spacing(1),
                borderRadius: theme.spacing(1),
                width: '100%',
                height: '95px',
              }}
              value={hotelDescription}
              onChange={onChangeDescription}
              placeholder={t('hotels.contentManager.hotelContentDescriptionEditor.placeholderDescription')}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
