import React from 'react';
import { AppBar, Box, IconButton, useTheme } from '@mui/material';
import { ArrowBack, Edit } from '@mui/icons-material';

import { useBreakpoints } from '../../hooks/useBreakpoints';

interface MobileAvailabilityLayoutProps {
  title: React.ReactNode;
  edit: React.ReactNode;
  filter: React.ReactNode;
  children: React.ReactNode;
  openEdit: boolean;
  openFilter: boolean;
  onBack: () => void;
  onEdit: () => void;
}

/** Mobile availability layout component. It renders an appbar with 2 toolbars. In the bottom one it renders the children
 *	@param title: center ReactNode for top toolbar
 *	@param onBack: on back function
 *	@param onEdit: on edit function
 */

export const MobileAvailabilityLayout = ({
  title,
  edit,
  filter,
  children,
  openEdit,
  openFilter,
  onBack,
  onEdit,
}: MobileAvailabilityLayoutProps) => {
  const theme = useTheme();
  const { isSmallUp } = useBreakpoints();
  return (
    <AppBar position="relative">
      {openEdit ? (
        edit
      ) : (
        <Box
          minHeight="64px"
          display="flex"
          alignItems="center"
          color="primary"
          padding={isSmallUp ? theme.spacing(0, 4) : theme.spacing(0, 2)}
        >
          <IconButton sx={{ padding: 2 }} aria-label="Back" onClick={onBack}>
            <ArrowBack sx={{ color: 'primary.contrastText' }} />
          </IconButton>
          {title}
          <IconButton aria-label="Edit search" onClick={onEdit}>
            <Edit sx={{ color: 'primary.contrastText' }} />
          </IconButton>
        </Box>
      )}
      {openFilter && filter}
      <Box
        minHeight="64px"
        display="flex"
        flex="1 0 0"
        alignSelf="stretch"
        alignItems="center"
        borderRadius={theme.spacing(2, 2, 0, 0)}
        padding={isSmallUp ? theme.spacing(0, 4) : theme.spacing(1, 2)}
        bgcolor="primary.contrastText"
      >
        {children}
      </Box>
    </AppBar>
  );
};
