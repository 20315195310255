import { defineAbility } from '@casl/ability';
import { UserRoles } from './types';

const registered = defineAbility((_can, cannot) => {
  cannot('view', 'Dashboard');
  cannot('view', 'MarkupRules');
  cannot('create-edit', 'MarkupRules');
  cannot('view', 'RoutingRules');
  cannot('view', 'ContentManager');
});

const agent = defineAbility((can, cannot) => {
  can('view', 'Dashboard');
  can('view', 'MarkupRules');
  cannot('create-edit', 'MarkupRules');
  cannot('view', 'RoutingRules');
  cannot('view', 'ContentManager');
});

const operator = defineAbility((can, cannot) => {
  can('view', 'Dashboard');
  can('view', 'MarkupRules');
  can('create-edit', 'MarkupRules');
  can('view', 'RoutingRules');
  cannot('view', 'ContentManager');
});

const admin = defineAbility((can) => {
  can('view', 'Dashboard');
  can('view', 'MarkupRules');
  can('create-edit', 'MarkupRules');
  can('view', 'RoutingRules');
  can('view', 'ContentManager');
});

export const abilities = {
  [UserRoles.ADMINS]: admin,
  [UserRoles.AGENTS]: agent,
  [UserRoles.OPERATORS]: operator,
  [UserRoles.REGISTERED]: registered,
};
