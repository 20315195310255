export const featuredSectionData = {
  title: 'Grandes destinos de america',
  items: [
    {
      title: 'roma',
      localcurrency: 'USD',
      price: '99.000',
      img: 'https://picsum.photos/id/50/4608/3072.jpg',
    },
    {
      title: 'Test',
      localcurrency: 'EUR',
      price: '75.50',
      img: 'https://picsum.photos/id/48/5000/3333',
    },
    {
      title: 'parís',
      localcurrency: 'EUR',
      price: '120.00',
      img: 'https://picsum.photos/id/57/2448/3264.jpg',
    },
    {
      title: 'New York',
      localcurrency: 'USD',
      price: '150.00',
      img: 'https://picsum.photos/id/43/1280/831.jpg?',
    },
  ],
};
