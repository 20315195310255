import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { HotelAvailabilityCardProps } from './types';
import { HotelAvailabilityCardDesktop } from './HotelAvailabilityCardDesktop';
import { HotelAvailabilityCardMobile } from './HotelAvailabilityCardMobile';

export const HotelAvailabilityCard = (props: HotelAvailabilityCardProps) => {
  const { isLargeUp } = useBreakpoints();

  return isLargeUp ? <HotelAvailabilityCardDesktop {...props} /> : <HotelAvailabilityCardMobile {...props} />;
};
