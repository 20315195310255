import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Card, CardContent, CardMedia, Divider, Grid, Typography, useTheme } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarsIcon from '@mui/icons-material/Stars';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DropdownSelect } from '../../../common/components/DropdownSelect';
import { iconArray, optionStateArray } from './constants';

interface HotelData {
  id: string;
  name: string;
  rating: number;
  description: string;
  img: string;
  proximity: string;
  localCurrency: string;
  price: number;
}

interface Props {
  dataHotel: HotelData;
}

export const CardDetail = ({ dataHotel }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { name, rating, description, img, proximity, localCurrency, price } = dataHotel;

  return (
    <Card sx={{ boxShadow: 'none', border: 'none' }}>
      <Box sx={{ display: 'flex' }}>
        <Grid container padding="0">
          <Grid item xs={12} md={5} padding={theme.spacing(1, 0)}>
            <CardMedia
              component="img"
              sx={{ width: '100%', height: '100%', borderRadius: theme.spacing(1), padding: theme.spacing(0) }}
              image={img}
              alt="Hotel Mykonos-Town Boutique Hotel image"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <CardContent sx={{ flex: '1 0 auto', paddingBottom: '0 !important' }}>
              <Typography
                component="div"
                variant="subtitle1"
                sx={{ textAlign: 'start', paddingRight: theme.spacing(1) }}
              >
                {name}
              </Typography>
              <Box display="flex" alignItems="center" padding={theme.spacing(1, 0)}>
                <LocationOnIcon
                  sx={{ color: theme.palette.text.disabled, paddingRight: theme.spacing(1) }}
                  fontSize="small"
                />
                <Typography variant="body2" color={theme.palette.text.secondary} component="div">
                  {proximity}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <StarsIcon
                  sx={{ color: theme.palette.text.disabled, paddingRight: theme.spacing(1) }}
                  fontSize="small"
                />
                <Typography variant="body2" color={theme.palette.text.secondary} component="div">
                  {rating}
                </Typography>
              </Box>

              <Box padding={theme.spacing(2, 0)} display="flex">
                {iconArray.map((Icon, index) => (
                  <Avatar key={index} sx={{ background: 'transparent', display: 'flex', justifyContent: 'flex-start' }}>
                    <Icon sx={{ color: theme.palette.text.disabled, width: theme.spacing(3) }} />
                  </Avatar>
                ))}
              </Box>
              <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                paddingBottom={theme.spacing(2)}
                textAlign="start"
                maxWidth="560px"
              >
                {description}
              </Typography>
              <Divider flexItem />
              <Box display="flex" flexDirection="column" alignSelf="stretch" mt={theme.spacing(1)}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  paddingBottom={theme.spacing(2)}
                >
                  <Typography variant="body1" color={theme.palette.text.disabled}>
                    {t('hotels.contentManager.hotelDetail.value')}
                  </Typography>
                  <Typography variant="h6" color={theme.palette.primary.dark}>
                    {localCurrency} {price}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ margin: theme.spacing(1, 0) }}>
                  <DropdownSelect
                    options={optionStateArray}
                    placeholder={t('hotels.contentManager.hotelDetail.selectPlaceholder')}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<VisibilityIcon />}
                    sx={{ margin: theme.spacing(0, 1), width: '100%' }}
                  >
                    {t('hotels.contentManager.hotelDetail.buttonPreview')}
                  </Button>
                  <Button variant="contained" sx={{ width: '100%' }}>
                    {' '}
                    {t('hotels.contentManager.hotelDetail.buttonSave')}
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
