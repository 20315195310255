import { AppBar, Box, Toolbar, Container, Avatar, Tabs, useTheme, useMediaQuery, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { disconnectedUser, logo, logoDesktop } from '../../../../assets';
import { getLinks } from './constants';
import { AvatarHolder, NavBarButton, Tab } from './styles';
import { UserMenu } from '../UserMenu';
import { useAuth } from '../../../auth/hooks/useAuth';
import { useUpdateHeaderHeight } from '../../../dashboard/hooks/useHeaderHeight';

export const Header = () => {
  const location = useLocation();
  const { ability } = useAuth();
  const pages = getLinks(ability);
  const getPathIndex = () => pages.findIndex((page) => page.link === location.pathname);
  const [pageIndex, setPageIndex] = useState(getPathIndex());
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const headerRef = useRef<HTMLElement>(null);

  const handleNavigate = (index: number) => () => {
    navigate(pages[index].link, { replace: true });
  };

  const toggleDrawer = () => setOpen((prevState) => !prevState);

  const setCurrentPath = () => setPageIndex(getPathIndex());

  useEffect(setCurrentPath, [location.pathname]);
  useUpdateHeaderHeight(headerRef);

  return (
    <AppBar position="fixed" ref={headerRef}>
      <Container>
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* TODO: refactor to use mui components */}
          <img
            style={{ cursor: 'pointer' }}
            onClick={handleNavigate(0)}
            src={isLarge ? logoDesktop : logo}
            width="100px"
            height="32px"
            alt="Logo"
          />

          {isLarge && (
            <Box
              flexGrow={1}
              display={isMedium ? ' flex' : 'column'}
              justifyContent={isMedium ? 'center !important' : 'space-around'}
            >
              {pages.map((page, index) => (
                <NavBarButton
                  sx={{
                    borderRadius: 100,
                  }}
                  className={pageIndex === index ? 'active' : ''}
                  key={index}
                  size="small"
                  variant="outlined"
                  color="primary"
                  disableFocusRipple={true}
                  disableRipple
                  onClick={handleNavigate(index)}
                  startIcon={page.logo}
                >
                  {t(page.name)}
                </NavBarButton>
              ))}
            </Box>
          )}

          {isLarge && (
            <AvatarHolder>
              <IconButton disableFocusRipple disableRipple>
                <Avatar alt="Invitado" src={disconnectedUser} />
              </IconButton>
            </AvatarHolder>
          )}
          <Box flexGrow={0} display="flex" justifyContent="flex-end">
            <IconButton
              size="large"
              aria-label="user menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <UserMenu open={open} toggle={toggleDrawer} />
          </Box>
        </Toolbar>
      </Container>

      {!isLarge && (
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Tabs
            variant="scrollable"
            value={pageIndex}
            aria-label="menu de motores"
            allowScrollButtonsMobile
            scrollButtons
          >
            {pages.map((page, index) => (
              <Tab
                key={index}
                icon={page.logo}
                onClick={handleNavigate(index)}
                iconPosition={isSmall ? 'start' : 'top'}
                label={t(page.name)}
              />
            ))}
          </Tabs>
        </Box>
      )}
    </AppBar>
  );
};
