export const english = {
  auth: {
    login: {
      errorMessage: 'Invalid login or password!',
      title: 'Start session',
      emailPlaceHolder: 'Email address',
      passwordPlaceHolder: 'Passowrd',
      forgotPassword: 'Forgot Password?',
      fieldErrorMessage: 'This field is required',
      signinButton: 'Sign in',
      createAccountText: 'Don&#39;t you have an account?',
      createAccountLink: 'Register',
      startWithSocial: 'Login with',
    },
    editUser: {
      title: 'Edit personal data',
      subtitle: 'Please insert data as it is shown in your identification document',
      failMessage: 'There was an error while trying to apply changes',
      firstName: {
        placeHolder: 'Names',
        errorMessage: 'Required field',
        helperText: 'As it shows in you indentification document',
      },
      lastName: {
        placeHolder: 'Lastnames',
        errorMessage: 'Required field',
        helperText: 'As it shows in you indentification document',
      },
      phoneCountryCode: {
        placeHolder: 'Area code',
        errorMessage: 'Required field',
      },
      phoneNumber: {
        placeHolder: 'Phone number',
        errorMessage: 'Required field',
      },
      address1: {
        placeHolder: 'Address',
        errorMessage: 'Required field',
        helperText: 'Ingrese su direccion',
      },
      address2: {
        placeHolder: '',
        errorMessage: 'Required field',
        helperText: '2nda linea de direccion (opcional)',
      },
      country: {
        placeHolder: 'Country',
        errorMessage: 'Required field',
      },
      city: {
        placeHolder: 'City',
        errorMessage: 'Required field',
      },
      postalCode: {
        placeHolder: 'Postal Code',
        errorMessage: 'Required field',
      },
      documentType: {
        placeHolder: 'Document type',
        errorMessage: 'Required field',
        helperText: 'Tipo de documento',
      },
      documentNumber: {
        placeHolder: 'Document number',
        errorMessage: 'Required field',
        helperText: 'As it shows in you indentification document',
      },
      applyChangesBtn: 'Apply',
      patchSuccess: 'User updated successfully!',
    },
  },
  common: {
    welcomeMessage: 'Hello world! this is an english welcome message for: ',
    currencies: {
      usd: 'USD',
    },
    filters: 'Filters',
    nav: {
      flights: 'Flights',
      hotels: 'Hotels',
      cars: 'Cars',
      explorer: 'Explorer',
      dashboard: 'Dashboard',
      userMenu: {
        userIdentifier: 'Guest',
        disconnectedMessage: "You haven't logged in",
        myFavorites: 'My favorites',
        myNotifications: 'Notifications',
        myBookings: 'My Bookings',
        admin: 'Admin',
        routingRules: 'Routing Rules',
        markupRules: 'Markup Rules',
        userList: 'User list',
        contentManager: 'Content Manager',
        accountMessage: 'Account',
        currentCurrency: 'Currency',
        currentLanguage: 'Language',
        help: 'Help',
        loginMesssage: 'Log in',
        logoutMesssage: 'Logout',
        selectedLanguage: 'English',
      },
    },
    footer: {
      companyType: 'Registered travel agency',
      identity: 'Real Consulting S.A. Resolution Registur N° 1263/2019',
      contactMessage: 'Contact Us',
      company: {
        title: 'Company',
        about: 'About Us',
        terms: 'Terms and Conditions',
        privacy: 'Privacy Policy',
        join: 'Join The team',
      },
      account: {
        title: 'Account',
        data: 'My Data',
        favorites: 'My Favorites',
        alerts: 'My Alerts',
        bookings: 'My Bookings',
        close: 'Log Out',
      },
      platform: {
        title: 'Platform',
        currency: 'Currency',
        language: 'Language',
        rates: 'Rates Tracker',
        rules: 'Markup Rules',
        travelers: 'My Travelers',
        airlines: 'ABM Airlines',
        cabines: 'ABM Cabins',
        alliances: 'ABM Alliances',
      },
      payments: {
        certification: {
          title: 'Safety certification',
          description: 'Mevuelo.com is certified by the council of security norms for your means of payment.',
        },
        intelligentTravel: {
          title: 'Travel smart and spend less',
          description:
            'Discover unbeatable prices on flights, hotels and cars, making every trip an economic adventure.',
        },
        explore: {
          title: 'Explore More, Worry Less',
          description:
            'With Mevuelo, traveling is easier than ever. Customize your trip as you wish, pack your dreams, and we take care of the rest.',
        },
        paymentMethods: {
          title: 'Pay with the method you like the most',
          credit: 'Credit Cards',
          bankTransfers: 'Bank Transfers',
          cash: 'Cash at any of our branches',
        },
        altImages: {
          visa: 'Visa card logo',
          oca: 'OCA card logo',
          mastercard: 'Mastercard card logo',
          american: 'American card logo',
          securityEncryption: 'Safety box image',
          wallet: 'Wallet image',
          backpack: 'Backpack image',
          card: 'Card icon',
          bank: 'Bank icon',
          cash: 'Money icon',
        },
      },
      help: {
        title: 'Help',
        faq: "FAQ's",
      },
    },
    priceTrend: {
      message: 'Price trend',
    },
  },
  flights: {
    searcher: {
      ariaLabelSearcher: 'Flights engine',
      oneWay: 'One way',
      roundTrip: 'Round trip',
      multidestination: 'Multidestino',
      origin: 'Origin',
      destination: 'Destination',
      go: 'Go',
      return: 'Return',
      unknownDates: 'I have not yet decided on the dates',
      economic: 'Economic',
      guest: 'Guests',
      search: 'Search',
      addSection: '+ Add section',
      section: 'Section {{amount}}',
    },
  },
  hotels: {
    desktopAvailability: {
      viewList: 'View list',
    },
    searcher: {
      title: 'Hotel search',
      destination: 'Destination',
      checkIn: 'Check-in',
      checkOut: 'Check-out',
      unknownDates: 'I have not yet decided on the dates',
      room: 'Rooms',
      guest: 'Guests',
      search: 'Search',
      apply: 'apply',
    },
    roomMenu: {
      roomAmount: 'Amount',
      room: 'Room',
      year: 'year',
      years: 'years',
      adults: 'Adults',
      kids: 'Kids',
      infants: 'Infants',
      apply: 'Apply',
    },
    cardsDestination: {
      seeMore: 'See more',
      from: 'From',
    },
    cardPackage: {
      doubleBaseText: 'Value per person on a double basis',
      fromText: 'From',
      reserveButtonText: 'Book',
      hotelRating: 'Hotel {{stars}} stars',
      nightsOfDuration: '{{amount}} nights',
    },
    filter: {
      filters: 'Filters',
      orderByAriaLabel: 'Order by sort group',
      orderBy: 'Order by',
      recommended: 'Recommended',
      bestPrice: 'Best Price',
      moreReviews: 'More Reviews',
      score: 'Score',
      name: 'Name',
      searchDistanceAriaLabel: 'Search distance slider',
      searchDistance: 'Search distance',
      radiusMessage: 'In a radius of',
      popularFiltersAriaLabel: 'Popular filters group',
      popularFilters: 'Popular filters',
      allInclusive: 'All inclusive',
      oceanView: 'Ocean view',
      hotelResort: 'Hotel resort',
      hotel: 'Hotel',
      freeCancel: 'Free cancel',
      noPaymentInAdvance: 'No payment in advance',
      priceAriaLabel: 'Prices ranges slider',
      price: 'Price',
      travelersExperienceAriaLabel: 'Travelers experience filter group',
      travelersExperience: 'Travelers experience',
      lgbtq: 'LGBTQ',
      businessFriendly: 'Business friendly',
      reducedMobility: 'Reduced Mobility',
      onlyAdults: 'Only adults',
    },
    availabilityLayout: {
      editSearchTitle: 'Edit Search',
      avatarAriaLabel: 'Hotel bed avatar icon',
      viewMapAriaLabel: 'Open View map button',
      tuneFiltersAriaLabel: 'Open tune filters button',
      results: 'results',
      viewMap: 'View map',
    },
    availabilityCard: {
      opinions: '{{numberOfOpinions}} opinions',
      endValueText: 'Total final value from',
      hotelRating: '{{rating}} star hotel',
      allInclusiveLabel: 'All inclusive',
      recommendedLabel: 'Recommended',
    },
    markupRules: {
      title: 'Markup Rules',
      optionFlights: 'Flights',
      optionHotels: 'Hotels',
      optionExplorer: 'Explorer',
      buttonNewRule: '+ NEW RULE',
      buttonBackHome: 'Back home',
      numberOfResults: 'Results',
      shownElements: 'Elements shown',
      listTitle: 'List of rules',
      search: 'Search engine',
    },
    routingRules: {
      title: 'Routing Rules',
      optionFlights: 'Flights',
      optionHotels: 'Hotels',
      optionExplorer: 'Explorer',
      buttonNewRule: '+ NEW RULE',
      buttonBackHome: 'Back home',
      numberOfResults: 'Results',
      shownElements: 'Elements shown',
      listTitle: 'List of rules',
      search: 'Search engine',
    },
    newMarkUpRules: {
      newRule: 'New Rule',
      newGroup: '+ NEW GROUP',
      buttonBackList: 'Back list',
      aplicationRatesTrackers: 'Applies to Rates Trackers',
      deleteGroup: 'Delete group',
      addCondition: '+ Add condition',
      addAction: '+ Add action',
      relationshipGroups: 'Relationship between groups',
      and: 'AND',
      or: 'OR',
      name: 'Name',
      buttonDiscard: 'Discard',
      buttonSave: 'Save',
      conditionalGroup: 'Conditional group',
    },
    propertyMapDrawer: {
      hotelDetails: 'Hotel details',
      value: 'Final value from',
      reserveButton: 'Reserve',
    },
    propertyMapCard: {
      details: 'Details',
      opinions: 'Opinions',
      reserveButton: 'Reserve',
      popularAmenities: 'Popular amenities',
    },
    contentManager: {
      title: 'Hotel Content Manager',
      configuration: 'Configuration',
      configurationDescription: 'Description default',
      configurationUbication: 'Location default',
      configurationRooms: 'Rooms default',
      configurationImages: 'Images default',
      configurationAmenities: 'Amenities default',
      paginationRowsPerPage: 'Rows per page',
      hotelDetail: {
        titleCard: 'Hotel overview',
        buttonBackList: 'Back to list',
        value: 'Total final value from',
        buttonPreview: 'Preview',
        buttonSave: 'Save',
        selectPlaceholder: 'State',
      },
      hotelContentDescriptionEditor: {
        title: 'My description',
        buttonEverythingWithGpt: 'Generate everything with gpt',
        buttonGenerateWithGpt: 'Generate with gpt',
        placeholderHotelName: 'Name hotel',
        placeholderStars: '{{stars}} star hotel',
        placeholderDescription: 'Hotel description',
      },
      hotelContentProviderDescription: {
        buttonSave: 'Save',
      },
      contentImages: {
        title: 'Hotel Images',
        buttonSave: 'Save',
      },
    },
  },
  users: {
    list: 'Users list',
    new: 'New user',
    search: 'Search users',
    filters: {
      userTypes: 'User types',
      propertyCard: {
        hotelDetails: 'Hotel details',
        value: 'Final value from',
        reserveButton: 'Reserve',
        contentManager: {
          title: 'Hotel Content Manager',
          configuration: 'Configuration',
          paginationRowsPerPage: 'Rows per page',
        },
      },
    },
    roles: {
      AGENT: 'Agent',
      OPERATOR: 'Operator',
      TRAVEL_MANAGER: 'Travel Manager',
    },
  },
  error404: {
    message: 'The page you’re looking for doesn’t exist.',
    btnLabel: 'back home',
  },
};
