import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

import { providers, getTranslatedTitlesArray } from './constants';

export const ButtonConfig = () => {
  const [open, setOpen] = React.useState(false);
  const [age, setAge] = React.useState<number | string>('');

  const titlesArray = getTranslatedTitlesArray();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<typeof age>) => setAge(event.target.value);

  const handleClickOpen = () => setOpen(true);

  const handleClose = (_event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>{t('hotels.contentManager.configuration')}</Button>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon sx={{ color: theme.palette.primary.main, marginRight: theme.spacing(1) }} />
            <Typography variant="h6" color={theme.palette.text.primary}>
              {t('hotels.contentManager.configuration')}
            </Typography>
          </Box>
          <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', color: theme.palette.action.active }} />
        </DialogTitle>
        <DialogContent>
          {titlesArray?.map((title, index) => (
            <div key={index}>
              <Box component="form" sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                <Box display="flex" alignItems="center" margin={theme.spacing(2, 0)}>
                  <Box color={theme.palette.action.active}>
                    <title.icon />
                  </Box>
                  <Typography variant="body1" sx={{ color: theme.palette.text.primary, marginLeft: theme.spacing(1) }}>
                    {t(title.name)}
                  </Typography>
                </Box>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    onChange={handleChange}
                    input={<OutlinedInput label="Age" />}
                  >
                    {providers.map((provider, index) => (
                      <MenuItem key={`${index}-${provider.id}`} color={theme.palette.text.primary} value={provider.id}>
                        {provider.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          ))}
        </DialogContent>
      </Drawer>
    </div>
  );
};
