import { Box, ListItem, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { StatusIcon } from './StatusIcon';

interface Data {
  id: number;
  location: string;
  name: string;
  idMevuelo: number;
  idTravelgate: number;
  impressions: number;
  status: string;
}

interface Props {
  data: Data;
}

export const ContentManagerItem = (props: Props) => {
  const { data } = props;
  const theme = useTheme();

  //HERE you are redirected to the edit page
  const handleGoRedirection = () => window.open('http://www.google.com/', '_blank');

  return (
    <Box display="flex" alignItems="center" borderBottom={`1px solid ${theme.palette.divider}`}>
      <ListItem disablePadding onClick={handleGoRedirection}>
        <ListItemButton role={undefined} dense style={{ minHeight: '150px' }}>
          <Box display="flex" width="100%">
            <ListItemText
              primary={`${data.location}`}
              style={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'center' }}
            />
            <ListItemText
              primary={`${data.name}`}
              style={{
                color: theme.palette.text.primary,
                display: 'flex',
                justifyContent: 'start',
                marginLeft: theme.spacing(4),
              }}
            />
            <ListItemText
              primary={`${data.idMevuelo}`}
              style={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'start' }}
            />
            <ListItemText
              primary={`${data.idTravelgate}`}
              style={{
                color: theme.palette.text.primary,
                display: 'flex',
                justifyContent: 'center',
                marginRight: theme.spacing(6),
              }}
            />
            <ListItemText
              primary={`${data.impressions}`}
              style={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'center' }}
            />
            <ListItemText primary={StatusIcon(data.status)} style={{ display: 'flex', justifyContent: 'center' }} />
          </Box>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};
