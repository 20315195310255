import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { ThreeDotsMenu } from '../../../common/components/ThreeDotsMenu/ThreeDotsMenu';

interface Props {
  value: number;
  hotelData: {
    id: string;
    name: string;
    condition: string;
    markup: string;
  }[];
}

export const MarkupRulesItem = (props: Props) => {
  const { value, hotelData } = props;

  const theme = useTheme();
  const labelId = `checkbox-list-label-${value}`;

  const selectedHotel = hotelData[value];

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: selectedHotel.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
  };

  return (
    <Box display="flex" alignItems="center" borderBottom={`1px solid ${theme.palette.divider}`}>
      <ListItem
        style={style}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
        key={value}
        secondaryAction={<IconButton edge="end" aria-label="comments"></IconButton>}
        disablePadding
      >
        <ListItemButton role={undefined} dense style={{ minHeight: '150px' }}>
          <ListItemIcon style={{ alignItems: 'center' }}>
            <DragIndicatorIcon style={{ marginRight: theme.spacing(3) }} />
          </ListItemIcon>
          <Box display="flex" width="100%">
            <ListItemText
              id={labelId}
              primary={`${hotelData[value].name}`}
              style={{ color: theme.palette.text.primary }}
            />
            <ListItemText
              id={labelId}
              primary={`${hotelData[value].condition}`}
              style={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'center' }}
            />
            <ListItemText
              id={labelId}
              primary={`${hotelData[value].markup}`}
              style={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'end' }}
            />
          </Box>
        </ListItemButton>
      </ListItem>
      <ThreeDotsMenu />
    </Box>
  );
};
