import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { AuthContextProvider } from '../../../auth/components/AuthContext';
import { router } from '../../../../router/router';
import { theme } from '../../../../theme';
import { SnackBarContextProvider } from '../SnackBarContext';
import { PropertyContextProvider } from '../../../hotel/context/PropertyContext';
import { SidebarContextProvider } from '../../../dashboard/contexts/SidebarContext';

const queryClient = new QueryClient();

export const Providers = () => (
  <ThemeProvider theme={theme}>
    <SnackBarContextProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthContextProvider>
          <PropertyContextProvider>
            <SidebarContextProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </SidebarContextProvider>
          </PropertyContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </SnackBarContextProvider>
  </ThemeProvider>
);
