import { RefObject, useEffect } from 'react';

import { useSidebar } from '../contexts/SidebarContext/hook';

export const useUpdateHeaderHeight = (headerRef: RefObject<HTMLElement>): void => {
  const { handleHeaderHeightChange } = useSidebar();

  const handleUpdate = () => {
    if (headerRef) {
      handleHeaderHeightChange(headerRef.current?.clientHeight);
    }
  };

  useEffect(() => {
    handleUpdate();
  }, [headerRef.current]);
};
