import Box from '@mui/material/Box/Box';

import { useBreakpoints } from '../../features/common/hooks/useBreakpoints';
import { FlightSearcher } from '../../features/flight/components/FlightSearcher';
import { FeaturedSection } from '../../features/offers/components/FeaturedSection';
import { OfferSection } from '../../features/offers/components/OfferSection';

export const Flights = () => {
  const { gap } = useBreakpoints();

  return (
    <Box display="flex" flexDirection="column" gap={gap} paddingBottom={gap}>
      <FlightSearcher />
      <OfferSection />
      <FeaturedSection />
    </Box>
  );
};
