import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { QueryKeys } from '../common/types';
import { client } from '../common/services';

interface useGetMyUserDataProps {
  onErrorCallback?: (_e: AxiosError) => void;
}

export const useGetMyUserData = ({ onErrorCallback }: useGetMyUserDataProps) =>
  useQuery({
    queryKey: [QueryKeys.getMyUserData],
    queryFn: async () => {
      try {
        return await client.get('user/me');
      } catch (_e) {
        onErrorCallback && onErrorCallback(_e as AxiosError);
      }
    },
    refetchOnWindowFocus: true,
  });
