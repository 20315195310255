import { useSidebar } from '../../contexts/SidebarContext/hook';
import { SidebarButtonLink } from './styles';

interface DashboardButtonLink {
  icon: JSX.Element;
  label: string;
  link: string;
  onClick: () => void;
}

export const DashboardButtonLink = ({ icon, label, onClick }: DashboardButtonLink) => {
  const { isOpen } = useSidebar();

  return (
    <SidebarButtonLink startIcon={icon} aria-label={label} isSidebarOpen={isOpen} onClick={onClick}>
      {isOpen ? label : ''}
    </SidebarButtonLink>
  );
};
