import deepPurple from '@mui/material/colors/deepPurple';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    disabled: {
      main: string;
    };
    primaryAlternative: {
      lighter: string;
      darker: string;
      darkest: string;
      special: string;
    };
    paper: {
      background: string;
      light: string;
    };
    alphas: {
      halfWhite: string;
    };
  }
}

const palette = {
  primary: {
    main: '#4a148c',
    light: '#AB47BC',
    dark: '#8e24aa',
    contrastText: '#fff',
  },
  secondary: {
    main: '#FFB300',
    light: '#FFD268',
    dark: '#A97700',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: 'rgba(0,0,0,0.87)',
    secondary: 'rgba(0,0,0,0.6)',
    disable: 'rgba(0,0,0,0.38)',
  },
  success: {
    main: '#4CAF50',
  },
  disabled: {
    main: 'rgba(0, 0, 0, 0.38)',
  },
  warning: {
    main: '#ED6C02',
  },
  field: {
    main: '#EDE7F6',
  },
  //From here those extended from the Palette type above
  primaryAlternative: {
    lighter: deepPurple[50],
    darker: deepPurple[100],
    darkest: deepPurple[500],
    special: deepPurple.A100,
  },
  paper: {
    background: '#fafafa',
    light: '#fff',
  },
  alphas: {
    halfWhite: 'rgba(255,255,255,0.5)',
  },
};

export const theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '28px',
      textAlign: 'center',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      textAlign: 'center',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'center',
      letterSpacing: '0.17px',
    },
    h5: {
      fontSize: '24px',
      fontWeight: 400,
      lineHeight: '32px',
    },
    h6: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '32px',
      textAlign: 'left',
      letterSpacing: '0.15px',
    },
    caption: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.4px',
    },
  },
  palette,
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          margin: '0 !important', // Turbio as fuck i know, pero lo estuvimos viendo con marco, tira de la nada un margin 48 que nos invalida de usar el grid si no
          padding: '8px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: palette.field.main,
          borderRadius: '8px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          letterSpacing: '0.17px',
          height: '40px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.field.main,
          borderRadius: '8px',
          '&.Mui-error': {
            '&>.MuiOutlinedInput-notchedOutline': {
              border: '1px solid #d32f2f',
            },
          },
        },
        notchedOutline: {
          border: 0,
          borderRadius: '8px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        labelIcon: {
          gap: 4,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
          '&:blur': {
            outline: 'none',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
          '&:blur': {
            outline: 'none',
          },
        },
      },
    },
  },
});
