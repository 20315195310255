import { Box, Typography, useTheme } from '@mui/material';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { IFeaturedCard } from '../../types';

import { Card } from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  item: IFeaturedCard;
}

export const FeaturedCard = ({ item }: Props) => {
  const { isSmallDown, isSmallUp } = useBreakpoints();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card>
      <img
        style={{
          objectFit: 'cover',
        }}
        src={item.img}
        height={119}
        width="50%"
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
        alignSelf="stretch"
        padding={theme.spacing(2)}
        flex={1}
      >
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          height={'55px'}
          sx={{ textTransform: 'capitalize' }}
        >
          {item.title}
        </Typography>
        <Box
          display="flex"
          justifyContent={isSmallDown ? 'flex-start' : 'space-between'}
          flexWrap="wrap"
          alignItems="space-arround"
          width={isSmallUp ? '100%' : 'auto'}
        >
          <Typography variant="body1" color={theme.palette.text.secondary} marginRight="5px">
            {t('hotels.cardsDestination.from')}
          </Typography>
          <Typography variant="body1" color={theme.palette.primary.dark}>
            {item.localcurrency} {item.price}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
