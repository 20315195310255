import { flagSpain, flagUnitedStates, flagBrazil } from '../../../../assets';

export const dataHotelDescription = [
  {
    name: 'Español',
    icon: flagSpain,
    showStarsSelector: true,
    hotelStar: '5',
  },
  {
    name: 'Inglés',
    icon: flagUnitedStates,
  },
  {
    name: 'Portugués',
    icon: flagBrazil,
  },
];
