import { createContext, useCallback, useState } from 'react';
import { PropertyData } from '../../types';

export type PropertyContextState = {
  handleSetProperty: (property: PropertyData) => void;
  handleDeselectProperty: () => void;
  property: PropertyData | null;
};

export const PropertyContext = createContext<PropertyContextState>({} as PropertyContextState);

interface PropertyContextProviderProps {
  children: JSX.Element;
}

export const PropertyContextProvider = ({ children }: PropertyContextProviderProps) => {
  const [property, setProperty] = useState<PropertyData | null>(null);

  const handleSetProperty = useCallback(async ({ name, price }: PropertyData) => {
    setProperty({ name, price });
  }, []);

  const handleDeselectProperty = useCallback(async () => {
    setProperty(null);
  }, []);

  return (
    <PropertyContext.Provider
      value={{
        handleSetProperty,
        handleDeselectProperty,
        property,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};
