import { Box } from '@mui/material';
import { Header } from '../Header';
import { Outlet } from 'react-router-dom';
import { useBreakpoints } from '../../hooks/useBreakpoints';

interface LayoutProps {
  noLargeDownHeader?: boolean;
}

export const Layout = ({ noLargeDownHeader }: LayoutProps) => {
  const { isLargeDown } = useBreakpoints();

  return (
    <Box bgcolor="paper.background">
      {isLargeDown && noLargeDownHeader ? undefined : <Header />}
      <Outlet />
    </Box>
  );
};
