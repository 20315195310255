import DomainIcon from '@mui/icons-material/Domain';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BedIcon from '@mui/icons-material/Bed';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

export const providers = [
  { id: 1, name: 'Proveedor 1' },
  { id: 2, name: 'Proveedor 2' },
  { id: 3, name: 'Proveedor 3' },
  { id: 4, name: 'Proveedor 4' },
];

export const getTranslatedTitlesArray = () => [
  { name: 'hotels.contentManager.configurationDescription', icon: DomainIcon },
  { name: 'hotels.contentManager.configurationUbication', icon: LocationOnIcon },
  { name: 'hotels.contentManager.configurationRooms', icon: BedIcon },
  { name: 'hotels.contentManager.configurationImages', icon: PermMediaIcon },
  { name: 'hotels.contentManager.configurationAmenities', icon: VolunteerActivismIcon },
];
