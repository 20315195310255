import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, useTheme, Tabs, Tab } from '@mui/material';
import {
  AirlineSeatReclineExtra,
  ArrowForward,
  DateRange,
  FlightLand,
  FlightTakeoff,
  Person,
  SyncAlt,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';

import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { SearchLayout } from '../../../common/components/SearchLayout';
import { homeBackgroundImage, unionArrow } from '../../../../assets';
import { RoomMenu } from '../../../hotel/components/RoomMenu';
import { IFlightSearch } from '../../../common/types';

const background = `url("${homeBackgroundImage}")`;
const mobileDeltaDistance = '133px';
const tRoot = 'flights.searcher.';

export const FlightSearcher: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [checkInDate, setCheckIn] = useState<dayjs.Dayjs | null>(null);
  const [checkOutDate, setCheckOut] = useState<dayjs.Dayjs | null>(null);
  const [disabledChecks, setDisabledChecks] = useState(false);
  const [sectionsAmount, setSectionsAmount] = useState(0);
  const [sections, setSections] = useState([<></>]);
  const { isMediumUp, isLargeDown, isLargeUp, isMediumDown } = useBreakpoints();
  const { t } = useTranslation();
  const theme = useTheme();
  const methods = useForm<IFlightSearch>();
  const { control, handleSubmit } = methods;

  const handleAddSection = () => setSectionsAmount(sectionsAmount + 1);
  const handleNavigate = (index: number) => () => {
    setPageIndex(index);
    setCheckIn(null);
    setCheckOut(null);
  };
  const handleDisableChecks = () => {
    setDisabledChecks((prevState) => !prevState);
    setCheckIn(null);
    setCheckOut(null);
  };
  const handleToggleRoomMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setOpen((prevState) => !prevState);
    setAnchorEl(event ? event.currentTarget : null);
  };
  const handleCheckIn = (value: Dayjs | null) => {
    setCheckIn(value);
  };
  const handleCheckOut = (value: Dayjs | null) => {
    setCheckOut(value);
  };

  const onSubmit = (args: any) => console.log(args);

  const firstInput = {
    formFieldName: 'origin',
    text: t(`${tRoot}origin`),
    error: t(`${tRoot}origin.error`),
    icon: <FlightTakeoff />,
  };
  const secondInput = {
    formFieldName: 'destination',
    text: t(`${tRoot}destination`),
    error: t(`${tRoot}destination.error`),
    icon: <FlightLand />,
  };

  const checkInConfig = useMemo(
    () => ({
      formFieldName: 'initialDate',
      text: t(`${tRoot}go`),
      error: t(`${tRoot}go.error`),
      disabled: disabledChecks,
      value: checkInDate,
      handler: handleCheckIn,
      icon: (props: any) => <DateRange {...props} color="primary" />,
    }),
    [disabledChecks, checkInDate, handleCheckIn],
  );

  const checkOutConfig = useMemo(
    () => ({
      formFieldName: 'endDate',
      text: t(`${tRoot}return`),
      error: t(`${tRoot}return.error`),
      disabled: disabledChecks,
      value: checkOutDate,
      handler: handleCheckOut,
      icon: (props: any) => <DateRange {...props} color="primary" />,
    }),
    [disabledChecks, checkOutDate, handleCheckOut],
  );

  const firstMenu = {
    formFieldName: 'guest',
    text: t(`${tRoot}guest`),
    error: t(`${tRoot}guest.error`),
    icon: <Person />,
  };
  const secondMenu = {
    formFieldName: 'economic',
    text: t(`${tRoot}economic`),
    error: t(`${tRoot}economic.error`),
    icon: <AirlineSeatReclineExtra />,
  };

  useEffect(() => {
    const newSections = [];
    for (let i = 0; i < sectionsAmount; i += 1) {
      newSections.push(
        <SearchLayout
          control={control}
          key={i}
          firstInput={firstInput}
          secondInput={secondInput}
          handleDisableChecks={handleDisableChecks}
          handleToggleRoomMenu={handleToggleRoomMenu}
          checkInConfig={checkInConfig}
          checkOutConfig={checkOutConfig}
        />,
      );
    }
    setSections(newSections);
  }, [sectionsAmount]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="stretch"
          height="325px"
          p={5}
          pt={isLargeDown ? 12 : 4}
          pb={4}
          sx={{
            background,
            backgroundSize: 'cover',
            backgroundPosition: isMediumDown ? 'center' : isLargeDown ? '0px -74px' : '0px -334px',
          }}
          mb={isLargeUp ? mobileDeltaDistance : 0}
        >
          <Box
            position="relative"
            bottom={isLargeUp ? '-231px' : 0} //@todo: make all searcher variants M - L - XL views
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            maxWidth="1156px"
            p={2}
            gap={3}
            borderRadius={2}
            boxShadow={8}
            bgcolor="common.white"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" width="100%" gap={1}>
              <Tabs variant="fullWidth" value={pageIndex} aria-label={t(`${tRoot}ariaLabelSearcher`)}>
                <Tab
                  icon={<ArrowForward />}
                  onClick={handleNavigate(0)}
                  iconPosition={isLargeUp ? 'start' : 'top'}
                  label={
                    <Typography variant="subtitle2" whiteSpace="nowrap">
                      {t(`${tRoot}oneWay`)}
                    </Typography>
                  }
                />
                <Tab
                  icon={<SyncAlt />}
                  onClick={handleNavigate(1)}
                  iconPosition={isLargeUp ? 'start' : 'top'}
                  label={
                    <Typography variant="subtitle2" whiteSpace="nowrap">
                      {t(`${tRoot}roundTrip`)}
                    </Typography>
                  }
                />
                <Tab
                  icon={<img src={unionArrow} alt="" />} //@todo: make this icon change to color primary on selected
                  onClick={handleNavigate(2)}
                  iconPosition={isLargeUp ? 'start' : 'top'}
                  label={
                    <Typography variant="subtitle2" whiteSpace="nowrap">
                      {t(`${tRoot}multidestination`)}
                    </Typography>
                  }
                />
              </Tabs>
            </Box>
            {pageIndex === 2 && sections /* @todo: make this sections work with forms */}
            <SearchLayout
              control={control}
              firstInput={firstInput}
              firstMenu={firstMenu}
              secondInput={secondInput}
              secondMenu={secondMenu}
              sectionSubtitleMessage={pageIndex === 2 && t(`${tRoot}section`, { amount: sectionsAmount })}
              unknownDatesMessage={pageIndex !== 2 && t(`${tRoot}unknownDates`)}
              addSectionMessage={pageIndex === 2 && t(`${tRoot}addSection`)}
              handleDisableChecks={handleDisableChecks}
              handleAddSections={handleAddSection}
              handleToggleRoomMenu={handleToggleRoomMenu}
              checkInConfig={checkInConfig}
              checkOutConfig={pageIndex === 1 ? checkOutConfig : undefined}
            />
            <Button
              fullWidth={isLargeDown}
              variant="contained"
              size="large"
              type="submit"
              sx={{
                padding: theme.spacing(1, 3),
                borderRadius: theme.spacing(1),
                boxShadow: theme.shadows[8],
                alignSelf: isMediumUp ? 'end' : '',
                marginTop: isLargeUp ? '-40px' : '',
              }}
            >
              <Typography variant="body1" color="primary.contrastText">
                {t(`${tRoot}search`)}
              </Typography>
            </Button>
            <RoomMenu
              firstFieldControlled={firstMenu}
              secondFieldControlled={secondMenu}
              control={control}
              open={open}
              toggle={handleToggleRoomMenu}
              anchorEl={anchorEl}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
