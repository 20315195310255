import EditIcon from '@mui/icons-material/Edit';
import DifferenceIcon from '@mui/icons-material/Difference';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const hotelArray = [
  {
    id: '1',
    name: 'Hotel Nacional',
    condition: 'Cantidad de habitaciones mayor a 100',
    markup: '+5% al valor + impuestos',
  },
  {
    id: '2',
    name: 'Gran Palacio Hotel',
    condition: 'Cantidad de habitaciones entre 50 y 100',
    markup: '+8% al valor + impuestos',
  },
  {
    id: '3',
    name: 'Vista del Mar Resort',
    condition: 'Cantidad de habitaciones entre 20 y 50',
    markup: '+10% al valor + impuestos',
  },
  {
    id: '4',
    name: 'Hotel Elegancia',
    condition: 'Cantidad de habitaciones menor o igual a 20',
    markup: '+12% al valor + impuestos',
  },
  {
    id: '5',
    name: 'Sunrise Beach Hotel',
    condition: 'Descuento para reservas anticipadas',
    markup: '+15% al valor + impuestos',
  },
  {
    id: '6',
    name: 'Royal Residence Inn',
    condition: 'Oferta especial para estancias largas',
    markup: '+18% al valor + impuestos',
  },
  {
    id: '7',
    name: 'Centro Plaza Hotel',
    condition: 'Ubicación céntrica',
    markup: '+10% al valor + impuestos',
  },
  {
    id: '8',
    name: 'Skyline Suites',
    condition: 'Cantidad de habitaciones mayor a 200',
    markup: '+7% al valor + impuestos',
  },
];

export const options = [
  { name: 'Editar', onClick: () => {}, icon: <EditIcon /> },
  { name: 'Duplicar', onClick: () => {}, icon: <DifferenceIcon /> },
  { name: 'Eliminar', onClick: () => {}, icon: <DeleteIcon /> },
  { name: 'Mover arriba', onClick: () => {}, icon: <ArrowUpwardIcon /> },
  { name: 'Mover abajo', onClick: () => {}, icon: <ArrowDownwardIcon /> },
];

export const title = ['NOMBRE', 'CONDICIONES', 'MARKUP'];

export const optionsArrayNewRules = [
  {
    id: 1,
    sets: [
      {
        label: 'Select 1',
        options: [
          { value: 1, label: 'Option 1' },
          { value: 2, label: 'Option 2' },
          { value: 3, label: 'Option 3' },
          { value: 4, label: 'Option 4' },
        ],
      },
      {
        label: 'Select 2',
        options: [
          { value: 5, label: 'Option 5' },
          { value: 6, label: 'Option 6' },
          { value: 7, label: 'Option 7' },
          { value: 8, label: 'Option 8' },
        ],
      },
      {
        label: 'Select 3',
        options: [
          { value: 9, label: 'Option 9' },
          { value: 10, label: 'Option 10' },
          { value: 11, label: 'Option 11' },
          { value: 12, label: 'Option 12' },
        ],
      },
    ],
  },
];
