import { Navigate } from 'react-router-dom';
import { Can } from '@casl/react';
import { Backdrop, CircularProgress } from '@mui/material';

import { useAuth } from '../../hooks/useAuth';
import { routes } from '../../../../router/routes';
import { useEffect, useState } from 'react';

interface ProtectedRouteProps {
  children: React.ReactNode;
  I: string; // named after casl prop names
  a: string; // named after casl prop names
}

export const ProtectedRoute = ({ children, I, a }: ProtectedRouteProps) => {
  const { ability } = useAuth();
  const [isLoadingAbilities, setIsLoadingAbilities] = useState(true);

  useEffect(() => {
    let loadingTimeout: NodeJS.Timeout | undefined;

    if (isLoadingAbilities) {
      loadingTimeout = setTimeout(() => {
        setIsLoadingAbilities(false);
      }, 1000);
    }

    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  if (isLoadingAbilities) {
    return (
      <Backdrop sx={{ color: (theme) => theme.palette.common.white, zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (ability) {
    return ability.can(I, a) ? (
      <Can I={I} a={a} ability={ability}>
        {children}
      </Can>
    ) : (
      <Navigate to={routes.error404} />
    );
  }

  return <Navigate to={routes.error404} />;
};
