import { Box, Typography, Checkbox, useTheme } from '@mui/material';
import { Card, CardContent, CardMedia, CardActions } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import MultipleSelectChip from '../../../common/components/MultiSelectChip';
import { amenities } from './constants';

interface CardData {
  imgUrl: string;
  active: boolean;
  order: number;
  provider: string;
}

interface Props {
  data: CardData;
}

export const HotelConentImageCard = ({ data }: Props) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: 330, p: theme.spacing(3, 0) }}>
      <Typography variant="body1" color={theme.palette.text.primary}>
        {data.order}
      </Typography>
      <Card>
        <CardMedia sx={{ height: 240 }} image={data.imgUrl} title="green iguana" />
        <CardContent sx={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(2, 2, 0, 2) }}>
          <Typography variant="subtitle2" component="div" textAlign="start">
            {data.provider}
          </Typography>
          <MultipleSelectChip data={amenities} />
        </CardContent>
        <Box></Box>
        <CardActions sx={{ display: 'flex', justifyContent: 'space-between', padding: theme.spacing(0, 2) }}>
          <DragIndicatorIcon sx={{ color: theme.palette.action.active }} />
          <Checkbox />
        </CardActions>
      </Card>
    </Box>
  );
};
