import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isSmallDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLarge = useMediaQuery(theme.breakpoints.only('lg'));
  const isLargeDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isSmall,
    isSmallDown,
    isSmallUp,
    isMedium,
    isMediumDown,
    isMediumUp,
    isLarge,
    isLargeDown,
    isLargeUp,
    gap: theme.spacing(isLargeUp ? 25 : isMediumUp ? 19 : 12.5),
  };
};
