import './styles.css';

interface PlaceMarkerProps {
  text: string;
}

export const PlaceMarker = ({ text }: PlaceMarkerProps) => `
    <div class="marker">
      <button class="button">
        <span class="marker-text">
          <span class="text">${text}</span>
        </span>
      </button>
    </div> `;
