import { MouseEvent } from 'react';
import Box from '@mui/material/Box';
import { Button, useTheme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { bancard, logo, pci, qrCode, supportAgent } from '../../../../assets';

import { MyIconButton } from './styles';
import { companyType, contactUs, identity, socialMediaIcons } from './constants';

interface FooterProps {
  hideContactSection?: boolean;
}

export function Footer({ hideContactSection }: FooterProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));

  const handleSocialMediaImageHover = (contrastImage: string) => (event: MouseEvent<HTMLButtonElement>) => {
    if (event.target) {
      (event.target as HTMLImageElement).src = contrastImage || '';
    }
  };

  const handleSocialMediaImageLeave = (originalImage: string) => (event: MouseEvent<HTMLButtonElement>) => {
    if (event.target) {
      (event.target as HTMLImageElement).src = originalImage || '';
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="center" pt={1} pb={2} mb={4} mt={3}>
        {!hideContactSection && (
          <Button type="submit" variant="contained" color="primary" sx={{ display: 'flex', gap: 2 }}>
            <img alt={t(contactUs)} src={supportAgent} />
            {t(contactUs).toUpperCase()}
          </Button>
        )}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        bgcolor={theme.palette.primary.main}
        height={300}
        pt={4}
        pb={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems={isLarge ? 'flex-start' : 'center'}
          marginLeft={isLarge ? '50px' : '0'}
          gap={2}
        >
          <MyIconButton sx={{ height: '100px' }}>
            <img src={logo} alt="logo-mevuelo" />
          </MyIconButton>

          <Box display="flex" justifyContent="center" gap={2}>
            {socialMediaIcons.map((icon, index) => (
              <MyIconButton
                key={index}
                sx={{ backgroundColor: theme.palette.primary.contrastText }}
                onMouseOver={handleSocialMediaImageHover(icon.contrastSrc)}
                onMouseOut={handleSocialMediaImageLeave(icon.src)}
              >
                <img src={icon.src} alt={icon.alt} />
              </MyIconButton>
            ))}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent="center"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" color={'white'} mt={2} flexDirection="column" alignItems="center">
            <Typography variant="h6">{t(companyType)}</Typography>
            <Box display="flex" justifyContent="center" width="70%" height={5} mt={3} mb={5}>
              <img src={qrCode} alt="qr-code" height="40px" width="40px" />
              <Typography variant="body2">{t(identity)}</Typography>
            </Box>
          </Box>

          <Box display="flex" gap={2}>
            <img src={bancard} height="50px" width="74px" alt="bancard" />
            <img src={pci} height="50px" width="74px" alt="bancard" />
          </Box>
        </Box>
      </Box>
    </>
  );
}
