import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { DateRange, KingBed, MyLocation, Person, Place } from '@mui/icons-material';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { RoomMenu } from '../RoomMenu';
import { SearchLayout } from '../../../common/components/SearchLayout';

import { IHotelSearch } from '../../../common/types';
import { routes } from '../../../../router/routes';
import { homeBackgroundImage } from '../../../../assets';

const background = `url("${homeBackgroundImage}")`;
const mobileDeltaDistance = '133px';
const tRoot = 'hotels.searcher.';

export const HotelSearcher: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null);
  const [checkInDate, setCheckIn] = useState<dayjs.Dayjs | null>(null);
  const [checkOutDate, setCheckOut] = useState<dayjs.Dayjs | null>(null);
  const [disabledChecks, setDisabledChecks] = useState(false);
  const { isMediumUp, isLargeDown, isLargeUp, isMediumDown } = useBreakpoints();
  const { t } = useTranslation();
  const theme = useTheme();
  const methods = useForm<IHotelSearch>();
  const { control, handleSubmit } = methods;
  const navigate = useNavigate();

  const handleToggleRoomMenu = (event?: React.MouseEvent<HTMLElement>) => {
    setOpen((prevState) => !prevState);
    setAnchorEl(event ? event.currentTarget : null);
  };
  const handleDisableChecks = () => {
    setDisabledChecks((prevState) => !prevState);
    setCheckIn(null);
    setCheckOut(null);
  };
  const handleCheckIn = (value: Dayjs | null) => {
    setCheckIn(value);
  };
  const handleCheckOut = (value: Dayjs | null) => {
    setCheckOut(value);
  };
  const onSubmit = (args: any) => console.log(args);

  const firstInput = {
    formFieldName: 'destination',
    text: t(`${tRoot}destination`),
    error: t(`${tRoot}destination.error`),
    icon: <Place />,
  };

  const checkInConfig = useMemo(
    () => ({
      formFieldName: 'initialDate',
      text: t(`${tRoot}checkIn`),
      error: t(`${tRoot}checkIn.error`),
      disabled: disabledChecks,
      value: checkInDate,
      handler: handleCheckIn,
      icon: (props: any) => <DateRange {...props} color="primary" />,
    }),
    [disabledChecks, checkInDate, handleCheckIn],
  );

  const checkOutConfig = useMemo(
    () => ({
      formFieldName: 'endDate',
      text: t(`${tRoot}checkOut`),
      error: t(`${tRoot}checkOut.error`),
      disabled: disabledChecks,
      value: checkOutDate,
      handler: handleCheckOut,
      icon: (props: any) => <DateRange {...props} color="primary" />,
    }),
    [disabledChecks, checkOutDate, handleCheckOut],
  );

  const firstMenu = {
    formFieldName: 'room',
    text: t(`${tRoot}room`),
    error: t(`${tRoot}room.error`),
    icon: <KingBed />,
  };
  const secondMenu = {
    formFieldName: 'guest',
    text: t(`${tRoot}guest`),
    error: t(`${tRoot}guest.error`),
    icon: <Person />,
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          alignSelf="stretch"
          height="325px"
          p={5}
          pt={isLargeDown ? 12 : 4}
          pb={4}
          sx={{
            background,
            backgroundSize: 'cover',
            backgroundPosition: isMediumDown ? 'center' : isLargeDown ? '0px -74px' : '0px -334px',
          }}
          mb={isLargeUp ? mobileDeltaDistance : 0}
        >
          <Box
            position="relative"
            bottom={isLargeUp ? '-191px' : '-15px'}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            maxWidth="1156px"
            p={2}
            gap={3}
            borderRadius={2}
            boxShadow={8}
            bgcolor="common.white"
          >
            <Box display="flex" flexDirection="column" justifyContent="center" width="100%" gap={1}>
              <Box display="flex" justifyContent="center" alignItems="center" gap={1} alignSelf="stretch">
                <MyLocation color="primary" />
                <Typography variant="h6" align="left" flex="1 0 0">
                  {t(`${tRoot}title`)}
                </Typography>
              </Box>
              <Divider />
            </Box>
            <SearchLayout
              control={control}
              firstInput={firstInput}
              firstMenu={firstMenu}
              secondMenu={secondMenu}
              unknownDatesMessage={t(`${tRoot}unknownDates`)}
              handleDisableChecks={handleDisableChecks}
              handleToggleRoomMenu={handleToggleRoomMenu}
              checkInConfig={checkInConfig}
              checkOutConfig={checkOutConfig}
            />
            <Button
              fullWidth={isLargeDown}
              // TODO: to be refactored when implemented
              onClick={() => {
                navigate(routes.hotelsAvailability);
              }}
              variant="contained"
              size="large"
              type="submit"
              sx={{
                padding: theme.spacing(1, 3),
                borderRadius: theme.spacing(1),
                boxShadow: theme.shadows[8],
                alignSelf: isMediumUp ? 'end' : '',
                marginTop: isLargeUp ? '-40px' : '',
              }}
            >
              <Typography variant="body1" color="primary.contrastText">
                {t(`${tRoot}search`)}
              </Typography>
            </Button>
          </Box>
          <RoomMenu
            firstFieldControlled={firstMenu}
            secondFieldControlled={secondMenu}
            control={control}
            open={open}
            toggle={handleToggleRoomMenu}
            anchorEl={anchorEl}
          />
        </Box>
      </form>
    </FormProvider>
  );
};
