import { useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

/* eslint-disable indent */
export const StatusIcon = (status: string) => {
  const theme = useTheme();
  switch (status) {
    case 'complete':
      return <CheckCircleIcon sx={{ color: theme.palette.success.main }} />;
    case 'in progress':
      return <ErrorIcon sx={{ color: theme.palette.warning.main }} />;
    default:
      return <CancelIcon sx={{ color: theme.palette.error.main }} />;
  }
};
