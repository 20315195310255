import { AbilityTuple, MongoAbility, MongoQuery } from '@casl/ability';
import { AltRoute, Dashboard, Group, TrendingUp } from '@mui/icons-material';
import { IconButton, IconButtonProps, ModalProps, Drawer } from '@mui/material';

import { routes } from '../../../../router/routes';

interface SidebarLink {
  name: string;
  link: string;
  logo: JSX.Element;
}

// NOTE: helper to load links based on user roles
export const getSidebarLinks = (ability: false | MongoAbility<AbilityTuple, MongoQuery>): SidebarLink[] => {
  if (!ability) return [];

  const adminLinks = [
    {
      name: 'common.nav.dashboard',
      link: routes.dashboard,
      logo: <Dashboard />,
    },
    {
      name: 'common.nav.userMenu.routingRules',
      link: routes.hotelsRoutingRules,
      logo: <AltRoute />,
    },
    {
      name: 'common.nav.userMenu.markupRules',
      link: routes.hotelMarkupRules,
      logo: <TrendingUp />,
    },
    {
      name: 'common.nav.userMenu.userList',
      link: routes.users,
      logo: <Group />,
    },
  ];

  const links = [...adminLinks];

  return links;
};

interface DashboardOpenIconButtonParentProps {
  isSidebarOpen: boolean;
}

export const DashboardOenIconButtonParent = <C extends React.ElementType>(
  props: IconButtonProps<C, { component?: C }> & DashboardOpenIconButtonParentProps,
) => <IconButton {...props}>{props.children}</IconButton>;

interface CustomDrawerProps {
  headerHeight: number | undefined;
}

export const CustomDrawer = <C extends React.ElementType>(
  props: ModalProps<C, { component?: C }> & CustomDrawerProps,
) => <Drawer {...props}>{props.children}</Drawer>;
