import Box from '@mui/material/Box/Box';
import useTheme from '@mui/material/styles/useTheme';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Close, Place } from '@mui/icons-material';
import Stars from '@mui/icons-material/Stars';
import MoreVert from '@mui/icons-material/MoreVert';
import { Button, Divider, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactNode, useState } from 'react';
import { Carousel } from 'react-material-ui-carousel/dist/components/Carousel';

import { useProperty } from '../../hooks/useProperty';
import { RatingStars } from '../HotelAvailabilityCard/starHook';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';

interface HotelPropertyMapCardProps {
  carouselImages: string[];
  starAmount: number;
  hotelDescription: string;
  distanceHint: string;
  amenities?: {
    name: string;
    icon: ReactNode;
  }[];
}

export const HotelPropertyMapCard = ({ ...props }: HotelPropertyMapCardProps) => {
  const [value, setValue] = useState(0);

  const theme = useTheme();
  const { t } = useTranslation();
  const { property, handleDeselectProperty } = useProperty();
  const { isLargeUp } = useBreakpoints();
  const { carouselImages, starAmount, hotelDescription, distanceHint, amenities } = props;

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const name = property?.name || 'No name found';

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="358px"
      bgcolor="paper.background"
      sx={{
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
      }}
      height="fit-content"
    >
      <Box display="flex" padding={theme.spacing(2)} gap={1} alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="initial">
          {name}
        </Typography>
        <IconButton aria-label="close property card" onClick={handleDeselectProperty}>
          <Close />
        </IconButton>
      </Box>
      {carouselImages.length > 0 && (
        <Carousel indicators={false} sx={{ width: '100%', height: '188px' }}>
          {carouselImages.map((image, index) => (
            <img src={image} key={`Image ${index}`} style={{ width: '100%', height: '100%' }} />
          ))}
        </Carousel>
      )}

      <Tabs
        variant="fullWidth"
        TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary.main } }}
        value={value}
        onChange={handleChange}
        textColor="primary"
        aria-label="Detail-review-tabs"
        sx={{ boxShadow: 1 }}
      >
        <Tab value={0} label={t('hotels.propertyMapCard.details')} />
        <Tab value={1} label={t('hotels.propertyMapCard.opinions')} />
      </Tabs>
      <Box display="flex" flexDirection="column" padding={theme.spacing(4, 2, 2)}>
        <Typography variant="h6" color="initial">
          {name}
        </Typography>
        <Box display="flex" alignItems="center">
          <RatingStars rating={starAmount} />
          <Typography variant="body1" color="gray">
            {starAmount} stars
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" color="gray" gap={1}>
          <Box display="flex" gap={1}>
            <Stars />
            <Typography variant="body1">{hotelDescription}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Place />
            <Typography variant="body1"> {distanceHint}</Typography>
          </Box>
        </Box>
        {isLargeUp && amenities && amenities.length > 0 && (
          <Box display="flex" flexDirection="column">
            <Divider flexItem sx={{ mt: 5, mb: 5 }} />
            <Typography variant="h6" color="initial" mb={1}>
              {t('hotels.propertyMapCard.popularAmenities')}
            </Typography>
            <Grid container justifyContent="space-between" rowSpacing={2} height="140px" overflow="scroll">
              {amenities.map((amenity) => (
                <Grid item key={amenity.name} xs={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    borderRadius={1}
                    border="1px solid var(--divider, rgba(0, 0, 0, 0.12))"
                    padding={theme.spacing(2, 0)}
                  >
                    {amenity.icon}
                    <Typography variant="body2" color="disabled">
                      {amenity.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
        <Box display="flex" alignSelf="stretch" mt={5} gap={1}>
          <IconButton
            aria-label="More options"
            onClick={handleDeselectProperty}
            sx={{ borderRadius: theme.spacing(1), boxShadow: 2 }}
          >
            <MoreVert />
          </IconButton>
          <Button variant="contained" color="primary" fullWidth>
            {t('hotels.propertyMapCard.reserveButton')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
