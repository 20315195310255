import { data } from './constants';

interface GetDataParams {
  from: number;
  to: number;
}

export const service = {
  getData: ({ from, to }: GetDataParams) => {
    const result = data.slice(from, to);
    return new Promise((resolve) => {
      resolve({
        count: data.length,
        data: result,
      });
    });
  },
};
