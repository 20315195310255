import { Box, Button, Card, Chip, Typography, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import StarsIcon from '@mui/icons-material/Stars';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';

import { iconArray } from '../HotelAvailabilityList/constants';
import { HotelAvailabilityCardProps } from './types';
import { RatingStars } from './starHook';

export const HotelAvailabilityCardMobile = ({
  title,
  rating,
  numberOfOpinions,
  proximity,
  localCurrency,
  price,
  img,
  cancellationPolicy,
  paymentPolicy,
}: HotelAvailabilityCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card>
      <Box display="flex" flexDirection="column">
        <Box display="flex" padding={theme.spacing(2)} justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <RatingStars rating={rating} />
              </Box>
              <Typography variant="body1" color={theme.palette.text.primary} marginRight={theme.spacing(1)}>
                {rating}
              </Typography>
              <Typography variant="caption" color={theme.palette.text.disabled}>
                ({t('hotels.availabilityCard.opinions', { numberOfOpinions: numberOfOpinions })})
              </Typography>
            </Box>
          </Box>
          <MoreVertIcon style={{ color: theme.palette.text.secondary }} />
        </Box>
        <Box position="relative" width="100%" padding={0}>
          <Box width="100%">
            <img src={img} alt="" width="100%" />
          </Box>
          <Box position="absolute" bottom={0} left={0} p={1} width="100%" display="flex">
            <Chip
              avatar={<StarIcon style={{ backgroundColor: 'transparent', color: theme.palette.text.primary }} />}
              label={t('hotels.availabilityCard.allInclusiveLabel')}
              size="small"
              style={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.primary,
                margin: theme.spacing(1),
              }}
            />
            <Chip
              avatar={<ThumbUpIcon style={{ backgroundColor: 'transparent', color: theme.palette.text.primary }} />}
              label={t('hotels.availabilityCard.recommendedLabel')}
              size="small"
              style={{ backgroundColor: green.A100, color: theme.palette.text.primary, margin: theme.spacing(1) }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" padding={theme.spacing(1, 2)}>
          <Box display="flex" alignItems="center">
            <Chip avatar={<StarsIcon />} size="medium" style={{ backgroundColor: 'transparent' }} />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {t('hotels.availabilityCard.hotelRating', { rating: rating })}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Chip avatar={<LocationOnIcon />} size="medium" style={{ backgroundColor: 'transparent' }} />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {proximity}
            </Typography>
          </Box>
        </Box>
        <Box padding={theme.spacing(1, 2)}>
          {iconArray.map((Icon, index) => (
            <Chip key={index} avatar={<Icon />} size="medium" style={{ backgroundColor: 'transparent' }} />
          ))}
        </Box>
        <Box display="flex" flexDirection="column" padding={theme.spacing(1, 2)}>
          <Box display="flex" alignItems="center">
            <Chip
              avatar={<CheckIcon style={{ color: theme.palette.success.main }} />}
              size="medium"
              style={{ backgroundColor: 'transparent' }}
            />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {cancellationPolicy}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Chip
              avatar={<CheckIcon style={{ color: theme.palette.success.main }} />}
              size="medium"
              style={{ backgroundColor: 'transparent' }}
            />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {paymentPolicy}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          padding={theme.spacing(1, 2)}
          borderTop={`1px solid ${theme.palette.divider}`}
        >
          <Box>
            <Typography variant="body2" color={theme.palette.text.disabled} textAlign="start">
              {t('hotels.availabilityCard.endValueText')}
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.dark} textAlign="start">
              {localCurrency} {price}
            </Typography>
          </Box>
          <Button variant="contained" size="small">
            {t('hotels.cardPackage.reserveButtonText')}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
