import { Box, Button, Card, CardContent, Typography, useTheme, CardMedia, Divider } from '@mui/material';
import NightlightIcon from '@mui/icons-material/Nightlight';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import cardMedia from '../../../../assets/cardMedia.png';
import { propertyPackage } from './constants';

interface OfferCardProps {
  city: string;
  hotel: { stars: number };
  totalStayDuration: number;
  startDate: string;
  endDate: string;
  localCurrency: string;
  price: number;
}
export const OfferCard = ({
  city,
  hotel,
  totalStayDuration,
  startDate,
  endDate,
  localCurrency,
  price,
}: OfferCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
            <Typography variant="h6" color={theme.palette.text.primary}>
              {city}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {t('hotels.cardPackage.hotelRating', { stars: hotel.stars })}
            </Typography>
          </Box>
          <MoreVertIcon style={{ color: theme.palette.text.secondary }} />
        </Box>
      </CardContent>

      <CardMedia component="img" image={cardMedia} alt="nombre paquete" />

      <CardContent>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box
              width="32px"
              height="32px"
              borderRadius="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.action.selected,
              }}
            >
              <NightlightIcon style={{ color: theme.palette.primary.main, transform: 'rotate(-35deg)' }} />
            </Box>
            <Typography variant="body2" color={theme.palette.text.primary} ml="10px">
              {t('hotels.cardPackage.nightsOfDuration', { amount: totalStayDuration })}
            </Typography>
          </Box>
          <Typography variant="caption" color={theme.palette.text.disabled}>
            {startDate} - {endDate}
          </Typography>
        </Box>

        <Box display="flex" marginTop={3} flexWrap="wrap">
          {propertyPackage.map((element, index) => (
            <Box display="flex" gap="6px" width="162px" flexWrap="wrap" key={index}>
              <Box>
                <AirplanemodeActiveIcon style={{ color: theme.palette.text.secondary }} />
              </Box>
              <Typography variant="caption" color={theme.palette.text.primary} padding="4px">
                {element.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
      <Divider />
      <CardContent>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {t('hotels.cardPackage.doubleBaseText')}
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {t('hotels.cardPackage.fromText')}
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.dark}>
              {localCurrency} {price}
            </Typography>
          </Box>
          <Button variant="contained" size="small">
            {t('hotels.cardPackage.reserveButtonText')}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};
