import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Tab,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import { routes } from '../../../../router/routes';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { optionsArrayNewRules } from './constants';

export const HotelCreateMarkupRule = () => {
  const [value, setValue] = useState('2');

  const theme = useTheme();
  const { t } = useTranslation();
  const { isLargeUp, isSmallDown } = useBreakpoints();
  const navigate = useNavigate();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  const handleGoMarkup = () => navigate(routes.hotelMarkupRules);

  return (
    <Container>
      <Box pt={isLargeUp ? 10 : 20} pb={8} color={theme.palette.text.primary}>
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
          <Typography variant="h5" color={theme.palette.text.primary}>
            {t('hotels.markupRules.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            margin: theme.spacing(5, 0),
            borderBottom: `1px solid ${theme.palette.text.disabled}`,
          }}
        >
          <TabContext value={value}>
            <TabList
              onChange={handleChange}
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <Tab
                label={t('hotels.markupRules.optionFlights')}
                style={{ color: theme.palette.text.primary }}
                value="1"
              />
              <Tab
                label={t('hotels.markupRules.optionHotels')}
                style={{ color: theme.palette.text.primary }}
                value="2"
              />
              <Tab
                label={t('hotels.markupRules.optionExplorer')}
                style={{ color: theme.palette.text.primary }}
                value="3"
              />
            </TabList>
          </TabContext>
        </Box>

        <Box padding={theme.spacing(4, 0)}>
          <Button startIcon={<ArrowBackIcon />} onClick={handleGoMarkup}>
            {t('hotels.newMarkUpRules.buttonBackList')}
          </Button>
        </Box>

        <Box
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            boxShadow: theme.shadows[1],
            borderRadius: theme.spacing(2),
            padding: theme.spacing(3),
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="h6">{t('hotels.newMarkUpRules.newRule')}</Typography>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Typography variant="body2">{t('hotels.newMarkUpRules.aplicationRatesTrackers')}</Typography>
                <Switch defaultChecked />
              </Box>
            </Box>
            <TextField
              placeholder={t('hotels.newMarkUpRules.name')}
              sx={{ height: theme.spacing(5), width: '566px' }}
            />
          </Box>

          <Box
            display="flex"
            padding={theme.spacing(4)}
            flexDirection="column"
            alignItems="flex-start"
            borderRadius={theme.spacing(2)}
            border={`1px solid ${theme.palette.divider}`}
            margin={theme.spacing(3, 0)}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="h6">{t('hotels.newMarkUpRules.conditionalGroup')} 1</Typography>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button size="small" sx={{ padding: 1 }} variant="text" color="error">
                  {t('hotels.newMarkUpRules.deleteGroup')}
                </Button>
              </Box>
            </Box>

            <Box display="flex" justifyContent="flex-start" flexWrap={isSmallDown ? 'wrap' : 'wrap'} width="100%">
              {optionsArrayNewRules?.length > 0 &&
                optionsArrayNewRules.map((option, outerIndex) => (
                  <div key={outerIndex} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    {option.sets.map((el, innerIndex) => (
                      <Select sx={{ width: '30%', margin: theme.spacing(1) }} key={innerIndex}>
                        {el.options.map((optionItem, itemIndex) => (
                          <MenuItem key={itemIndex} value={optionItem.value}>
                            {optionItem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ))}
                    <Button
                      variant="text"
                      style={{ padding: theme.spacing(0) }}
                      startIcon={<DeleteIcon color="error" />}
                    />
                  </div>
                ))}
            </Box>

            <Button size="large" sx={{ color: theme.palette.primary.main }}>
              {t('hotels.newMarkUpRules.addCondition')}
            </Button>
          </Box>

          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="body2" color={theme.palette.text.primary} marginRight={theme.spacing(2)}>
              {t('hotels.newMarkUpRules.relationshipGroups')}
            </Typography>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              <FormControlLabel value="female" control={<Radio />} label={t('hotels.newMarkUpRules.and')} />
              <FormControlLabel value="male" control={<Radio />} label={t('hotels.newMarkUpRules.or')} />
            </RadioGroup>
          </Box>

          <Box
            display="flex"
            padding={theme.spacing(4)}
            flexDirection="column"
            alignItems="flex-start"
            borderRadius={theme.spacing(2)}
            border={`1px solid ${theme.palette.divider}`}
            margin={theme.spacing(3, 0)}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="h6">{t('hotels.newMarkUpRules.conditionalGroup')} 2</Typography>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button size="small" sx={{ padding: 1 }} variant="text" color="error">
                  {t('hotels.newMarkUpRules.deleteGroup')}
                </Button>
              </Box>
            </Box>

            <Box display="flex" justifyContent="flex-start" flexWrap={isSmallDown ? 'wrap' : 'wrap'} width="100%">
              {optionsArrayNewRules?.length > 0 &&
                optionsArrayNewRules.map((option, index) => (
                  <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    {option.sets.map((el, index) => (
                      <Select sx={{ width: '30%', margin: theme.spacing(1) }} key={index}>
                        {el.options.map((optionItem, index) => (
                          <MenuItem key={index} value={optionItem.value}>
                            {optionItem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ))}
                    <Button
                      variant="text"
                      style={{ padding: theme.spacing(0) }}
                      startIcon={<DeleteIcon color="error" />}
                    />
                  </div>
                ))}
            </Box>
            <Button size="large" sx={{ color: theme.palette.primary.main }}>
              {t('hotels.newMarkUpRules.addCondition')}
            </Button>
          </Box>

          <Button variant="outlined" size="large">
            {t('hotels.newMarkUpRules.newGroup')}
          </Button>

          <Box
            display="flex"
            padding={theme.spacing(4)}
            flexDirection="column"
            alignItems="flex-start"
            borderRadius={theme.spacing(2)}
            border={`1px solid ${theme.palette.divider}`}
            margin={theme.spacing(3, 0)}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography variant="h6">Acción</Typography>
            </Box>

            <Box display="flex" justifyContent="flex-start" flexWrap={isSmallDown ? 'wrap' : 'wrap'} width="100%">
              {optionsArrayNewRules?.length > 0 &&
                optionsArrayNewRules.map((option, index) => (
                  <div key={index} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    {option.sets.map((el, index) => (
                      <Select sx={{ width: '30%', margin: theme.spacing(1) }} key={index}>
                        {el.options.map((optionItem, index) => (
                          <MenuItem key={index} value={optionItem.value}>
                            {optionItem.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ))}
                    <Button
                      variant="text"
                      style={{ padding: theme.spacing(0) }}
                      startIcon={<DeleteIcon color="error" />}
                    />
                  </div>
                ))}
            </Box>
            <Button size="large" sx={{ color: theme.palette.primary.main }}>
              {t('hotels.newMarkUpRules.addAction')}
            </Button>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="error" sx={{ marginRight: theme.spacing(1) }}>
              {t('hotels.newMarkUpRules.buttonDiscard')}
            </Button>
            <Button variant="contained">{t('hotels.newMarkUpRules.buttonSave')} </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
