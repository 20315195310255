import { ArrowBack, List, Map, Tune } from '@mui/icons-material';
import Box from '@mui/material/Box/Box';
import Button from '@mui/material/Button/Button';
import { TFunction } from 'i18next';
import { Container, IconButton, useTheme, Typography } from '@mui/material';
import { useState } from 'react';

import { HotelAvailabilitySearcher } from '../../features/hotel/components/HotelAvailabilitySearcher';
import { HotelAvailabilityFilter } from '../../features/hotel/components/HotelAvailabilityFilter';
import { PriceTrend } from '../../features/common/components/PriceTrend';
import { HotelAvailabilityList } from '../../features/hotel/components/HotelAvailabilityList';
import { dtoMock } from './mocks/availability.mock';
import { DesktopAvailabilityLayout } from '../../features/common/components/AvailabilityLayout/DesktopAvailabilityLayout';
import { MapWrapper } from '../../features/hotel/components/MapWrapper/MapWrapper';
import { markers } from './mocks/markers.mock';
import { amenities, propertyImages } from './mocks/property.mock';
import { HotelPropertyMapCard } from '../../features/hotel/components/HotelPropertyMapCard';
import { useProperty } from '../../features/hotel/hooks/useProperty';

interface MobileAvailabilityProps {
  openMap: boolean;
  zoom: number;
  center: google.maps.LatLngLiteral;
  t: TFunction<'translation', undefined>;
  handleMap: () => void;
}

export const DesktopAvailability = ({ openMap, zoom, center, t, handleMap }: MobileAvailabilityProps) => {
  const { property } = useProperty();
  const [openFilter, setOpenFilter] = useState(false);
  const theme = useTheme();
  const toggleFilter = () => setOpenFilter((prevState) => !prevState);
  return (
    <>
      <HotelAvailabilitySearcher />
      {openMap ? (
        <Box display="flex">
          <Box position="absolute" display="flex" zIndex={1}>
            {openFilter && (
              <>
                <Box padding={theme.spacing(0, 8)} bgcolor="paper.light" height="100vh" overflow="scroll">
                  <Box mt={5} mb={5}>
                    <HotelAvailabilityFilter destination={dtoMock.destination} />
                  </Box>
                </Box>
                <IconButton
                  aria-label="toggle filter button"
                  onClick={toggleFilter}
                  sx={{
                    color: 'primary.contrastText',
                    height: '42px',
                    width: 'fit-content',
                    backgroundColor: 'primary.main',
                    margin: theme.spacing(12, 1, 0, 0),
                    padding: 1,
                    borderRadius: theme.spacing(0, 1, 1, 0),
                    boxShadow: 1,
                  }}
                >
                  <ArrowBack />
                </IconButton>
              </>
            )}
            {property && (
              <Box mt={5}>
                <HotelPropertyMapCard
                  carouselImages={propertyImages}
                  starAmount={3}
                  hotelDescription="Hotel 5 estrellas"
                  distanceHint="A 200mts de Oklahoma St. 2354"
                  amenities={amenities}
                />
              </Box>
            )}
            {!openFilter && (
              <IconButton
                aria-label="toggle filter button"
                onClick={toggleFilter}
                sx={{
                  color: 'primary.contrastText',
                  height: '42px',
                  width: 'fit-content',
                  backgroundColor: 'primary.main',
                  margin: theme.spacing(12, 0, 0, 1),
                  padding: 1,
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                <Tune />
              </IconButton>
            )}
            <IconButton
              aria-label="toggle filter button"
              onClick={handleMap}
              sx={{
                color: 'primary.contrastText',
                height: '42px',
                backgroundColor: 'primary.main',
                margin: theme.spacing(12, 0, 0, 1),
                gap: 1,
                borderRadius: 1,
                padding: theme.spacing(1, 3),
                boxShadow: 1,
                whiteSpace: 'nowrap',
              }}
            >
              <List />
              <Typography variant="body1">{t('hotels.desktopAvailability.viewList')}</Typography>
            </IconButton>
          </Box>
          <MapWrapper markers={markers} style={{ height: '100vh', width: '100%' }} center={center} zoom={zoom} />
        </Box>
      ) : (
        <Container>
          <DesktopAvailabilityLayout
            filter={<HotelAvailabilityFilter destination={dtoMock.destination} />}
            cardList={<HotelAvailabilityList />}
            listHeader={
              <>
                <PriceTrend />
                <Button
                  sx={{ padding: 1 }}
                  aria-label={t('hotels.availabilityLayout.viewMapAriaLabel')}
                  variant="outlined"
                  color="primary"
                  startIcon={<Map color="primary" />}
                  onClick={handleMap}
                >
                  {t('hotels.availabilityLayout.viewMap')}
                </Button>
              </>
            }
          />
        </Container>
      )}
    </>
  );
};
