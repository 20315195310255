import { Box, useTheme } from '@mui/material';
import { KingBed, Person } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CounterItem } from '../../../common/components/CounterItem';

interface RoomProps {
  roomId: number;
}

export const Room = ({ roomId }: RoomProps) => {
  const [adultAmount, setAdultAmount] = useState(1);
  const [kidAmount, setKidAmount] = useState(0);
  const [babyAmount, setBabyAmount] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation();

  const incrementAdultAmount = () => setAdultAmount((prevState) => prevState + 1);
  const incrementKidAmount = () => setKidAmount((prevState) => prevState + 1);
  const incrementBabyAmount = () => setBabyAmount((prevState) => prevState + 1);

  const decrementAdultAmount = () => {
    if (adultAmount > 1) setAdultAmount((prevState) => prevState - 1);
  };
  const decrementKidAmount = () => {
    if (kidAmount > 0) setKidAmount((prevState) => prevState - 1);
  };
  const decrementBabyAmount = () => {
    if (babyAmount > 0) setBabyAmount((prevState) => prevState - 1);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="stretch"
        padding={theme.spacing(1, 3)}
        gap={1}
        bgcolor={theme.palette.primaryAlternative.lighter}
      >
        <KingBed color="primary" />
        {t('hotels.roomMenu.room')} {roomId}
      </Box>
      <CounterItem
        icon={<Person color="disabled" />}
        itemText={t('hotels.roomMenu.adults')}
        helperText={`+12 ${t('hotels.roomMenu.years')}`}
        amount={adultAmount}
        onAdd={incrementAdultAmount}
        onRemove={decrementAdultAmount}
      />
      <CounterItem
        icon={<KingBed color="disabled" />}
        itemText={t('hotels.roomMenu.kids')}
        helperText={`2 - 11  ${t('hotels.roomMenu.years')}`}
        amount={kidAmount}
        onAdd={incrementKidAmount}
        onRemove={decrementKidAmount}
      />

      <CounterItem
        icon={<KingBed color="disabled" />}
        itemText={t('hotels.roomMenu.infants')}
        helperText={`0 - 1 ${t('hotels.roomMenu.year')}`}
        amount={babyAmount}
        onAdd={incrementBabyAmount}
        onRemove={decrementBabyAmount}
      />
    </>
  );
};
