import { Chip, useTheme } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';

interface RatingStarProps {
  rating: number;
}
export const RatingStars = ({ rating }: RatingStarProps) => {
  const iconsStar = [];

  const theme = useTheme();

  for (let i = 0; i <= Math.ceil(rating); i++) {
    if (rating - i >= 1) {
      iconsStar.push(
        <Chip
          key={i}
          avatar={<StarIcon style={{ color: theme.palette.secondary.main }} />}
          style={{ backgroundColor: 'transparent' }}
          size="small"
        />,
      );
    } else if (rating - i > 0) {
      iconsStar.push(
        <Chip
          key={i}
          avatar={<StarHalfOutlinedIcon style={{ color: theme.palette.secondary.main }} />}
          style={{ backgroundColor: 'transparent' }}
          size="small"
        />,
      );
    }
  }
  return iconsStar;
};
