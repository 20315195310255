import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Pagination, Typography, useTheme } from '@mui/material';

import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { HotelConentImageCard } from './HotelConentImageCard';
import { service } from './services';

const itemsPerPage = 6;

export const ContentCardImage = () => {
  const [providers, setProviders] = useState([]);
  const [pagination, setPagination] = useState({
    count: 0,
    from: 0,
    to: itemsPerPage,
  });

  const { isMediumDown } = useBreakpoints();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    service.getData({ from: pagination.from, to: pagination.to }).then((response: any) => {
      setPagination({ ...pagination, count: response.count });
      setProviders(response.data);
    });
  }, [pagination.from, pagination.to]);

  const handlePageChange = (_event: any, page: number) => {
    const from = (page - 1) * itemsPerPage;
    const to = from + itemsPerPage;
    setPagination({ ...pagination, from: from, to: to });
  };

  return (
    <>
      <Box borderRadius={theme.spacing(2)} border={`1px solid ${theme.palette.divider}`} boxShadow={theme.shadows[4]}>
        <Typography color={theme.palette.text.primary} padding={theme.spacing(3)} variant="h6">
          {t('hotels.contentManager.contentImages.title')}
        </Typography>
        <Box
          display="flex"
          justifyContent={isMediumDown ? 'center' : 'space-around'}
          m={theme.spacing(0, 3)}
          flexWrap="wrap"
        >
          {providers.map((provider, index) => (
            <HotelConentImageCard key={index} data={provider} />
          ))}
        </Box>
        <Box display={'flex'} justifyContent="end" margin={theme.spacing(3, 3)}>
          <Button variant="contained">{t('hotels.contentManager.contentImages.buttonSave')}</Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" mt={theme.spacing(2)}>
        <Pagination count={Math.ceil(pagination.count / itemsPerPage)} onChange={handlePageChange} />
      </Box>
    </>
  );
};
