import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../../common/components/Footer';
import { DashboardSidebar } from '../DashboardSidebar';
import { DashboardContainer } from './styles';
import { useSidebar } from '../../contexts/SidebarContext/hook';

interface DashboardLayoutProps {
  children?: JSX.Element;
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { isOpen } = useSidebar();

  return (
    <Box bgcolor="paper.background" minHeight="100vh" display="flex" flexDirection="column" alignItems="flex-end">
      <DashboardSidebar />
      DashboardLayout
      <DashboardContainer isSidebarOpen={isOpen}>
        {children || <Outlet />}
        <Footer hideContactSection />
      </DashboardContainer>
    </Box>
  );
};
