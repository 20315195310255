import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { routes } from '../../../../router/routes';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { CardDetail } from './CardDetails';
import { MenuTabs } from './Tabs';
import { detailsHotelCard } from './constants';

export const HotelDetailContentManger = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isLargeUp } = useBreakpoints();

  const handleGoMarkup = () => navigate(routes.hotelContentManager);

  return (
    <Box p={isLargeUp ? 10 : 20} pb={8}>
      <Box padding={theme.spacing(4, 0)}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleGoMarkup}>
          {t('hotels.contentManager.hotelDetail.buttonBackList')}
        </Button>
      </Box>
      <Typography variant="h5" color={theme.palette.text.primary}>
        {t('hotels.contentManager.title')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: `1px solid ${theme.palette.divider}`,
          padding: theme.spacing(3),
          marginTop: theme.spacing(5),
          borderRadius: theme.spacing(2),
          boxShadow: theme.shadows[4],
          backgroundColor: theme.palette.primary.contrastText,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom={theme.spacing(2)}>
          <Typography variant="h6" color={theme.palette.text.primary}>
            {t('hotels.contentManager.hotelDetail.titleCard')}
          </Typography>
          <MoreVertIcon sx={{ color: theme.palette.text.disabled }} />
        </Box>
        <CardDetail dataHotel={detailsHotelCard} />
      </Box>
      <Box sx={{ width: '100%', pt: theme.spacing(5) }}>
        <MenuTabs />
      </Box>
    </Box>
  );
};
