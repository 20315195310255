import { Box, Typography, useTheme, Menu, Button, IconButton, FormControl } from '@mui/material';
import { Add, KingBed, Remove } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { RoomMenuDrawer } from './styles';
import { useState } from 'react';
import { Room } from '../Room';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { Controller } from 'react-hook-form';
import { ControlledFieldProp } from '../../../common/types';

interface RoomMenuProps {
  control: any; //@todo: fixit. This because Control<any,any> wont overlap with Control<MyInterface,any> by some reason.
  firstFieldControlled: ControlledFieldProp;
  secondFieldControlled: ControlledFieldProp;
  open: boolean;
  toggle: () => void;
  anchorEl: (EventTarget & HTMLElement) | null;
}

const widthOption = {
  large: 227,
  medium: 261,
  small: 141.5,
};

export const RoomMenu = ({
  control,
  firstFieldControlled,
  //secondFieldControlled,
  open,
  toggle,
  anchorEl,
}: RoomMenuProps) => {
  const [roomAmount, setRoomAmount] = useState(1);
  const [rooms] = useState([<Room roomId={1} key={`Room ${0}`} />]);
  const theme = useTheme();
  const { t } = useTranslation();

  const { isMediumUp, isMediumDown, isMedium, isLargeUp } = useBreakpoints();

  const incrementRoomAmount = () => {
    rooms.push(<Room roomId={rooms.length + 1} key={`Room ${rooms.length + 1}`} />);
    setRoomAmount((prevState) => prevState + 1);
  };

  const decrementRoomAmount = () => {
    if (roomAmount > 1) {
      rooms.pop();
      setRoomAmount((prevState) => prevState - 1);
    }
  };

  const menu = (
    <Controller
      control={control}
      name={firstFieldControlled.formFieldName}
      rules={{ required: true }}
      render={({
        //field: { onChange, value = null, ref },
        fieldState: { error },
      }) => (
        <>
          <FormControl error={Boolean(error)}>
            <Box display={'flex'} flexDirection={'column'} pt={0} pb={0}>
              {isMediumDown && (
                <Box display="flex" flexDirection="column" alignItems="center" pt={1} gap={1}>
                  <Remove color="primary" />
                </Box>
              )}

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex="1 0 0"
                padding={theme.spacing(1, 3)}
              >
                <Box
                  display="flex"
                  width={isLargeUp ? widthOption.large : isMedium ? widthOption.medium : widthOption.small}
                  alignItems="center"
                  gap={1}
                >
                  <KingBed color="disabled" />
                  {t('hotels.roomMenu.roomAmount')}
                </Box>
                <Box display="flex" alignItems="center">
                  <IconButton
                    onClick={decrementRoomAmount}
                    sx={{ backgroundColor: theme.palette.primaryAlternative.darker }}
                  >
                    <Remove color="primary" />
                  </IconButton>
                  <Typography padding={theme.spacing(0, 2)}>{roomAmount}</Typography>
                  <IconButton
                    onClick={incrementRoomAmount}
                    sx={{ backgroundColor: theme.palette.primaryAlternative.darker }}
                  >
                    <Add color="primary" />
                  </IconButton>
                </Box>
              </Box>
              {rooms}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
                flex="1 0 0"
                alignSelf="stretch"
                padding={theme.spacing(3, 2)}
              >
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={{
                    padding: theme.spacing(1, 3),
                    borderRadius: theme.spacing(1),
                    boxShadow: theme.shadows[8],
                  }}
                >
                  {t('hotels.roomMenu.apply')}
                </Button>
              </Box>
            </Box>
          </FormControl>
        </>
      )}
    />
  );

  return isMediumUp ? (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={toggle}>
      {menu}
    </Menu>
  ) : (
    <RoomMenuDrawer anchor="bottom" elevation={8} open={open} onClose={toggle}>
      {menu}
    </RoomMenuDrawer>
  );
};
