import { styled, Tab as MuiTab, Button } from '@mui/material';

export const AvatarHolder = styled('div')(() => ({
  flexGrow: 0,
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const Tab = styled(MuiTab)(({ theme }) => ({
  '&.Mui-selected': {
    color: `${theme.palette.primary.contrastText} !important`,
    outline: 'none',
  },
  '&.MuiTab-textColorPrimary': {
    color: `${theme.palette.alphas.halfWhite}`,
  },
}));

export const NavBarButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primaryAlternative.lighter,
  '&.active': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    path: {
      fill: theme.palette.primary.main,
    },
  },
  path: {
    fill: theme.palette.common.white,
  },
}));
