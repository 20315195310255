/**
 * Helper component used to add extra props to the Box component from @mui/material
 */

import { Box, BoxProps } from '@mui/material';

interface DashboardContainerParentProps {
  isSidebarOpen: boolean;
}

export const DashboardContainerParent = <C extends React.ElementType>(
  props: BoxProps<C, { component?: C }> & DashboardContainerParentProps,
) => <Box {...props}>{props.children}</Box>;
