import { Box } from '@mui/material';

import { HotelSearcher } from '../../features/hotel/components/HotelSearcher';
import { FeaturedSection } from '../../features/offers/components/FeaturedSection';
import { OfferSection } from '../../features/offers/components/OfferSection';
import { useBreakpoints } from '../../features/common/hooks/useBreakpoints';

export const Hotels = () => {
  const { gap } = useBreakpoints();

  return (
    <Box display="flex" flexDirection="column" gap={gap} paddingBottom={gap}>
      <HotelSearcher />
      <OfferSection />
      <FeaturedSection />
    </Box>
  );
};
