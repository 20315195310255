import {
  Accessible,
  Chat,
  Hail,
  LocalOffer,
  MonetizationOnRounded,
  Person,
  Place,
  Sort,
  SortByAlpha,
  Star,
  ThumbUp,
  VolunteerActivism,
  Whatshot,
  Work,
} from '@mui/icons-material';

import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
  useTheme,
} from '@mui/material';

import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OptionsTypography } from './styles';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';

interface Props {
  destination: string;
  darkMode?: boolean;
}

const useAvailabilityFilterPadding = () => {
  const theme = useTheme();
  const { isMediumUp, isLargeUp } = useBreakpoints();
  if (isLargeUp) return theme.spacing(0);
  if (isMediumUp) return theme.spacing(2, 4);
  return theme.spacing(2, 2);
};

export const HotelAvailabilityFilter = ({ destination, darkMode }: Props) => {
  const [order, setOrder] = useState('');
  const [distance, setDistance] = useState<number | number[]>(0);
  const [price, setPrice] = useState<number[]>([0, 1]);

  const { t } = useTranslation();
  const theme = useTheme();

  const handleSetOrder = (_event: ChangeEvent<HTMLInputElement>, value: string) => setOrder(value);
  const handleSetDistance = (_event: Event, value: number | number[]) => setDistance(value);
  const handleSetPrice = (_event: Event, value: number | number[]) => setPrice(value as number[]);
  const handleSetFilter = (_event: ChangeEvent<HTMLInputElement>, value: string) =>
    console.log(`You selected this filter: ${value}`);

  const titleTextColor = darkMode ? 'common.white' : 'purple.special';
  const filterTextColor = darkMode ? 'primary.contrastText' : 'text.secondary';
  const filterExtraColor = darkMode ? 'primary.contrastText' : 'primary.dark';
  const dividerColor = darkMode ? 'purple.special' : 'primary';
  const checkColor = darkMode ? 'secondary' : 'primary';
  const padding = useAvailabilityFilterPadding();

  return (
    <Box p={padding}>
      <Typography variant="h6" alignSelf="stretch" color={darkMode ? 'primary.contrastText' : 'primary'}>
        {t('hotels.filter.filters')}
      </Typography>
      <Divider flexItem sx={{ margin: theme.spacing(1, 0), borderColor: dividerColor }} />
      <FormControl component="fieldset" sx={{ alignSelf: 'stretch' }}>
        <FormLabel sx={{ marginBottom: 1 }} component="legend">
          <OptionsTypography margin={theme.spacing(1, 0)} variant="body1" alignSelf="stretch" color={titleTextColor}>
            <Sort />
            {t('hotels.filter.orderBy')}
          </OptionsTypography>
        </FormLabel>
        <RadioGroup
          aria-label={t('hotels.filter.orderBy')}
          name={t('hotels.filter.orderBy')}
          value={order}
          onChange={handleSetOrder}
          sx={{ gap: 1 }}
        >
          <FormControlLabel
            value={t('hotels.filter.recommended')}
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <ThumbUp />
                {t('hotels.filter.recommended')}
              </OptionsTypography>
            }
            control={<Radio color={checkColor} sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            value={t('hotels.filter.bestPrice')}
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <LocalOffer />
                {t('hotels.filter.bestPrice')}
              </OptionsTypography>
            }
            control={<Radio color={checkColor} sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            value={t('hotels.filter.moreReviews')}
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <Chat />
                {t('hotels.filter.moreReviews')}
              </OptionsTypography>
            }
            control={<Radio color={checkColor} sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            value={t('hotels.filter.score')}
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <Star />
                {t('hotels.filter.score')}
              </OptionsTypography>
            }
            control={<Radio color={checkColor} sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            value={t('hotels.filter.name')}
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <SortByAlpha />
                {t('hotels.filter.name')}
              </OptionsTypography>
            }
            control={<Radio color={checkColor} sx={{ color: filterTextColor }} />}
          />
        </RadioGroup>
      </FormControl>
      <Divider flexItem sx={{ margin: theme.spacing(1, 0), borderColor: dividerColor }} />

      <Box display="flex" flexDirection="column" alignItems="flex-start" alignSelf="stretch">
        <OptionsTypography margin={theme.spacing(1, 0)} variant="body1" alignSelf="stretch" color={titleTextColor}>
          <Place />
          {t('hotels.filter.searchDistance')}
        </OptionsTypography>
        <Slider
          color={darkMode ? 'secondary' : 'primary'}
          value={distance}
          onChange={handleSetDistance}
          aria-labelledby={t('hotels.filter.searchDistanceAriaLabel')}
          min={0}
          max={100}
        />
        <Typography
          margin={theme.spacing(1, 0)}
          display="flex"
          flex="1 0 0"
          alignSelf="stretch"
          justifyContent="space-between"
          color={filterExtraColor}
        >
          {`${destination} - ${t('hotels.filter.radiusMessage')} ${distance}km`}
        </Typography>
      </Box>
      <Divider flexItem sx={{ margin: theme.spacing(1, 0), borderColor: dividerColor }} />

      <FormControl component="fieldset" sx={{ alignSelf: 'stretch' }}>
        <FormLabel sx={{ marginBottom: 1 }} component="legend">
          <OptionsTypography margin={theme.spacing(1, 0)} variant="body1" alignSelf="stretch" color={titleTextColor}>
            <Whatshot />
            {t('hotels.filter.popularFilters')}
          </OptionsTypography>
        </FormLabel>
        <RadioGroup
          aria-label={t('hotels.filter.popularFiltersAriaLabel')}
          name={t('hotels.filter.popularFilters')}
          onChange={handleSetFilter}
          sx={{ gap: 1 }}
        >
          <FormControlLabel
            label={t('hotels.filter.allInclusive')}
            control={<Checkbox color={checkColor} value="All inclusive" sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            label={t('hotels.filter.oceanView')}
            control={
              <Checkbox color={checkColor} value={t('hotels.filter.oceanView')} sx={{ color: filterTextColor }} />
            }
          />
          <FormControlLabel
            label={t('hotels.filter.hotelResort')}
            control={
              <Checkbox color={checkColor} value={t('hotels.filter.hotelResort')} sx={{ color: filterTextColor }} />
            }
          />
          <FormControlLabel
            label={t('hotels.filter.hotel')}
            control={<Checkbox color={checkColor} value={t('hotels.filter.hotel')} sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            label={t('hotels.filter.freeCancel')}
            control={
              <Checkbox color={checkColor} value={t('hotels.filter.freeCancel')} sx={{ color: filterTextColor }} />
            }
          />
          <FormControlLabel
            label={t('hotels.filter.noPaymentInAdvance')}
            control={
              <Checkbox
                color={checkColor}
                value={t('hotels.filter.noPaymentInAdvance')}
                sx={{ color: filterTextColor }}
              />
            }
          />
        </RadioGroup>
      </FormControl>
      <Divider flexItem sx={{ margin: theme.spacing(1, 0), borderColor: dividerColor }} />
      <Box display="flex" flexDirection="column" alignItems="flex-start" alignSelf="stretch">
        <OptionsTypography margin={theme.spacing(1, 0)} variant="body1" alignSelf="stretch" color={titleTextColor}>
          <MonetizationOnRounded />
          {t('hotels.filter.price')}
        </OptionsTypography>
        <Slider
          color={darkMode ? 'secondary' : 'primary'}
          aria-labelledby={t('hotels.filter.priceAriaLabel')}
          value={price}
          onChange={handleSetPrice}
          min={0}
          max={10000}
        />
        <Box
          display="flex"
          flex="1 0 0"
          alignSelf="stretch"
          justifyContent="space-between"
          margin={theme.spacing(1, 0)}
        >
          <Typography variant="body2" color={filterExtraColor}>
            {`${t('common.currencies.usd')} ${price[0]}`}
          </Typography>
          <Typography variant="body2" color={filterExtraColor}>
            {`${t('common.currencies.usd')} ${price[1]}`}
          </Typography>
        </Box>
      </Box>
      <Divider flexItem sx={{ margin: theme.spacing(1, 0), borderColor: dividerColor }} />

      <FormControl component="fieldset" sx={{ alignSelf: 'stretch' }}>
        <FormLabel sx={{ marginBottom: 1 }} component="legend">
          <OptionsTypography margin={theme.spacing(1, 0)} variant="body1" alignSelf="stretch" color={titleTextColor}>
            <Hail />
            {t('hotels.filter.travelersExperience')}
          </OptionsTypography>
        </FormLabel>
        <RadioGroup
          aria-label={t('hotels.filter.travelersExperienceAriaLabel')}
          name={t('hotels.filter.travelersExperience')}
          onChange={handleSetFilter}
          sx={{ gap: 1 }}
        >
          <FormControlLabel
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <VolunteerActivism />
                {t('hotels.filter.lgbtq')}
              </OptionsTypography>
            }
            control={<Checkbox color={checkColor} value={t('hotels.filter.LGBTQ')} sx={{ color: filterTextColor }} />}
          />
          <FormControlLabel
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <Work />
                {t('hotels.filter.businessFriendly')}
              </OptionsTypography>
            }
            control={
              <Checkbox
                color={checkColor}
                value={t('hotels.filter.businessFriendly')}
                sx={{ color: filterTextColor }}
              />
            }
          />
          <FormControlLabel
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <Accessible />
                {t('hotels.filter.reducedMobility')}
              </OptionsTypography>
            }
            control={
              <Checkbox color={checkColor} value={t('hotels.filter.reducedMobility')} sx={{ color: filterTextColor }} />
            }
          />
          <FormControlLabel
            label={
              <OptionsTypography variant="body2" color={filterTextColor}>
                <Person />
                {t('hotels.filter.onlyAdults')}
              </OptionsTypography>
            }
            control={
              <Checkbox color={checkColor} value={t('hotels.filter.onlyAdults')} sx={{ color: filterTextColor }} />
            }
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
