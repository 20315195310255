import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { options } from '../../../hotel/components/HotelMarkupRules/constants';

const ITEM_HEIGHT = 48;

export const ThreeDotsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} selected={option.name === 'Pyxis'} onClick={handleClose}>
            <div style={{ color: theme.palette.primary.main, marginRight: theme.spacing(2) }}>{option.icon}</div>
            <Typography variant="body2" style={{ color: theme.palette.primary.main }}>
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
