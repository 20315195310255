import { Accessible, BeachAccess, Dining, SportsGymnastics, Wifi, LocalParking } from '@mui/icons-material';
import Box from '@mui/material/Box/Box';
import { propertyExampleImage } from '../../../assets';

export const amenities = [
  { icon: <Wifi key={'Wifi icon'} />, name: 'Wifi' },
  { icon: <LocalParking key={'LocalParking icon'} />, name: 'LocalParking' },
  { icon: <Dining key={'Dining icon'} />, name: 'Dining' },
  { icon: <SportsGymnastics key={'SportsGymnastics icon'} />, name: 'SportsGymnastics' },
  { icon: <BeachAccess key={'BeachAccess icon'} />, name: 'BeachAccess' },
  { icon: <Accessible key={'Accessible icon'} />, name: 'Accessible' },
];

export const propertyImages = [propertyExampleImage, propertyExampleImage, propertyExampleImage];

export const propertyAmenities = amenities.map((element, index) => (
  <Box display="flex" borderRadius="100px" bgcolor="#F5F5F5" p={1} key={`Amenity ${index}`}>
    {element.icon}
  </Box>
));
