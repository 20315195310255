import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import { IEditUser } from './types';
import { client } from '../common/services';
import { QueryKeys } from '../common/types';
import { useSnackBar } from '../common/components/SnackBarContext';

interface UsePatchMeProps {
  onSuccess?: () => void;
}

export const usePatchMe = ({ onSuccess }: UsePatchMeProps) => {
  const { addToast } = useSnackBar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (data: IEditUser) => {
      const response = await client.patch('user/me', data);
      return response.data;
    },
    {
      onMutate: async () => {
        await queryClient.cancelQueries([QueryKeys.getMyUserData]);
      },
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        addToast({
          message: t('auth.editUser.patchSuccess'),
          color: 'success',
        });
      },

      onSettled: () => {
        queryClient.invalidateQueries([QueryKeys.getMyUserData]);
      },
    },
  );

  return mutation;
};
