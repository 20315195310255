import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, InputBase, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

interface CardProviderProps {
  providerData: {
    providerName: string;
    hotelNameForProvider: string;
    stars: number;
    description: string;
  };
}

export const CardProvider = ({ providerData }: CardProviderProps) => {
  const [hotelName, setHotelName] = useState<string>('');
  const [hotelStars, setHotelStars] = useState<string>('');
  const [hotelDescription, setHotelDescription] = useState<string>('');

  const { providerName, stars } = providerData;

  const theme = useTheme();
  const { t } = useTranslation();

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => setHotelName(e.target.value);
  const onChangeStars = (e: ChangeEvent<HTMLInputElement>) => setHotelStars(e.target.value);
  const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => setHotelDescription(e.target.value);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle1" color={theme.palette.text.primary} pb={theme.spacing(3)}>
          {providerName}
        </Typography>
      </Box>
      <Box width="100%">
        <Grid container padding="0">
          <Grid item xs={12} md={12} lg={8} p={`${theme.spacing(0, 1, 0, 0)}`}>
            <InputBase
              sx={{
                backgroundColor: grey[100],
                paddingLeft: theme.spacing(1),
                borderRadius: theme.spacing(1),
                width: '100%',
                height: theme.spacing(5),
              }}
              value={hotelName}
              onChange={onChangeName}
              placeholder={t('hotels.contentManager.hotelContentDescriptionEditor.placeholderHotelName')}
            />
          </Grid>

          <Grid item sm={12} md={4} lg={4} padding="0">
            <InputBase
              sx={{
                backgroundColor: grey[100],
                paddingLeft: theme.spacing(1),
                borderRadius: theme.spacing(1),
                width: '100%',
                height: theme.spacing(5),
              }}
              value={hotelStars}
              onChange={onChangeStars}
              placeholder={t('hotels.contentManager.hotelContentDescriptionEditor.placeholderStars', {
                stars,
              })}
            />
          </Grid>

          <Grid item sm={12} md={12} lg={12} padding="0">
            <InputBase
              sx={{
                backgroundColor: grey[100],
                paddingLeft: theme.spacing(1),
                marginTop: theme.spacing(1),
                borderRadius: theme.spacing(1),
                width: '100%',
                height: '95px',
              }}
              value={hotelDescription}
              onChange={onChangeDescription}
              placeholder={t('hotels.contentManager.hotelContentDescriptionEditor.placeholderDescription')}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
