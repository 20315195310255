import { Box, Container, SwipeableDrawer, useTheme } from '@mui/material';
import { useBreakpoints } from '../../features/common/hooks/useBreakpoints';
import { UserList, UserFilters } from '../../features/users';
import { useState } from 'react';

export const Users = () => {
  const theme = useTheme();
  const { isLargeUp, isMediumUp, gap } = useBreakpoints();
  const [filtersDrawerOpen, setFiltersDrawerOpen] = useState(false);

  const handleOpenSideDrawer = () => setFiltersDrawerOpen(true);
  const handleCloseSideDrawer = () => setFiltersDrawerOpen(false);
  return (
    <Container>
      <Box minHeight="calc(100vh - 64px)" pt={isLargeUp ? 10 : 20}>
        <Box display="flex" flexDirection="row" justifyContent="flex-start" gap={theme.spacing(3)} paddingBottom={gap}>
          {isMediumUp && <UserFilters />}
          <UserList setFiltersDrawerOpen={handleOpenSideDrawer} />
          {!isMediumUp && (
            <SwipeableDrawer
              open={filtersDrawerOpen}
              anchor="bottom"
              onOpen={handleOpenSideDrawer}
              onClose={handleCloseSideDrawer}
            >
              <UserFilters setFiltersDrawerOpen={handleCloseSideDrawer} />
            </SwipeableDrawer>
          )}
        </Box>
      </Box>
    </Container>
  );
};
