export const packageDestination = {
  title: 'Los paquetes más pedidos por nuestros viajeros',
  items: [
    {
      city: 'New York City',
      image: 'nyc.jpg',
      startDate: '12 Ago',
      endDate: '30 Ago',
      totalStayDuration: 7,
      localCurrency: 'USD',
      price: 1200.0,
      hotel: {
        stars: 5,
      },
    },
    {
      city: 'Paris',
      image: 'paris.jpg',
      startDate: '12 Ago',
      endDate: '30 Ago',
      totalStayDuration: 7,
      localCurrency: 'USD',
      price: 1500.0,
      hotel: {
        stars: 3,
      },
    },
    {
      city: 'Tokyo',
      image: 'tokyo.jpg',
      startDate: '12 Ago',
      endDate: '30 Ago',
      totalStayDuration: 7,
      localCurrency: 'USD',
      price: 1800.0,
      hotel: {
        stars: 4,
      },
    },
    {
      city: 'Rome',
      image: 'rome.jpg',
      startDate: '12 Ago',
      endDate: '30 Ago',
      totalStayDuration: 7,
      localCurrency: 'USD',
      price: 1400.0,
      hotel: {
        stars: 5,
      },
    },
  ],
};
