import LocalParkingIcon from '@mui/icons-material/LocalParking';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import WifiIcon from '@mui/icons-material/Wifi';
import PoolIcon from '@mui/icons-material/Pool';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

export const detailsHotelCard = {
  id: '450',
  name: 'Mykonos Town Boutique H...',
  rating: 4.2,
  description: 'Hermoso hotel a pocos metros de la playa. Vista al mar diversión y espacimiento garantizados.',
  img: 'https://picsum.photos/id/50/4608/3072.jpg',
  proximity: 'A 200mts de Oklahoma St. 2354',
  localCurrency: 'USD',
  price: 99999,
};

const iconsHotelDetailContentManager = {
  LocalParking: LocalParkingIcon,
  FreeBreakfast: FreeBreakfastIcon,
  Wifi: WifiIcon,
  Pool: PoolIcon,
  FitnessCenter: FitnessCenterIcon,
};

export const iconArray = Object.values(iconsHotelDetailContentManager);

export enum OptionState {
  Disponible = 'Disponible',
  Ocupado = 'Ocupado',
  EnMantenimiento = 'En Mantenimiento',
  Reservado = 'Reservado',
  NoDisponible = 'No Disponible',
}

export const optionStateArray: OptionState[] = Object.values(OptionState);

export const labelTabs = [
  { label: 'Hotel', value: 'one' },
  { label: 'Ubicación', value: 'two' },
  { label: 'Habitaciones', value: 'three' },
  { label: 'Imagenes', value: 'four' },
  { label: 'Amenities', value: 'five' },
];
