import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuList,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Close, KeyboardArrowDown, Login } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { bell, currency, disconnectedUser, heart, help, language, ticket } from '../../../../assets';
import { MyMenuIcon, MyMenuItem } from './styles';
import { useAuth } from '../../../auth/hooks/useAuth';

interface UserMenuProps {
  open: boolean;
  toggle: () => void;
}

const tRoot = 'common.nav.userMenu.';

export const UserMenu: React.FC<UserMenuProps> = ({ open, toggle }) => {
  const { isAuthenticated, checkIsLogged, doSignOut, doSignIn, toggleEditUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const { myProfile } = useAuth();

  const { i18n } = useTranslation();
  const [lngAnchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();

  const handleSetLanguage = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  useEffect(() => {
    checkIsLogged();
    setLoading(false);
  }, [checkIsLogged, setLoading]);

  const handleLogout = async () => {
    setLoading(true);
    try {
      await doSignOut();
      setLoading(false);
    } catch (_e) {
      setLoading(false);
    }

    checkIsLogged();
  };

  return (
    <SwipeableDrawer anchor={'right'} open={open} onClose={toggle} onOpen={toggle}>
      {!isMedium && (
        <Box padding={theme.spacing(1, 3)} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          <IconButton size="medium" onClick={toggle}>
            <Close fontSize="medium" />
          </IconButton>
        </Box>
      )}
      {!isAuthenticated && (
        <MenuList sx={{ width: 320 }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            pb={2}
            pr={2}
            pl={2}
          >
            <Avatar alt={t(`${tRoot}userIdentifier`)} src={disconnectedUser} sx={{ width: 60, height: 60 }} />
            <Typography variant="h6">{t(`${tRoot}userIdentifier`)}</Typography>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color="text.disabled" variant="subtitle1">
                {t(`${tRoot}disconnectedMessage`)}
              </Typography>
            </Box>
          </Box>
        </MenuList>
      )}
      {isAuthenticated && myProfile && (
        <MenuList sx={{ width: 320 }}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'flex-start'}
            pb={2}
            pr={2}
            pl={2}
          >
            <Avatar
              alt={`${myProfile.firstName} ${myProfile.lastName}`}
              src={disconnectedUser}
              sx={{ width: 60, height: 60 }}
            />
            <Box display="flex" gap={1}>
              <Typography variant="h6">
                {myProfile.firstName} {myProfile.lastName}
              </Typography>
              <IconButton aria-label="edit profile" onClick={toggleEditUser}>
                <EditIcon />
              </IconButton>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Typography color="text.disabled" variant="subtitle1">
                {myProfile.email}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <MyMenuItem>
            <MyMenuIcon src={heart} />
            <Typography variant="subtitle1" color={'primary'}>
              {t(`${tRoot}myFavorites`)}
            </Typography>
          </MyMenuItem>
          <MyMenuItem>
            <MyMenuIcon src={bell} />
            <Typography variant="subtitle1" color={'primary'}>
              {t(`${tRoot}myNotifications`)}
            </Typography>
          </MyMenuItem>
          <MyMenuItem>
            <MyMenuIcon src={ticket} />
            <Typography variant="subtitle1" color={'primary'}>
              {t(`${tRoot}myBookings`)}
            </Typography>
          </MyMenuItem>

          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '3rem',
              padding: theme.spacing(1, 2),
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
            }}
            color={'text.disabled'}
            variant="body1"
          >
            {t(`${tRoot}accountMessage`)}
          </Typography>
          <Divider />
        </MenuList>
      )}
      <MenuList sx={{ width: 320 }}>
        <MyMenuItem>
          <MyMenuIcon src={currency} />
          <Box display="flex" alignItems={'flex-start'} justifyContent={'space-between'} flex={'1 0 0'}>
            <Typography color={'primary'} variant="subtitle1">
              {t(`${tRoot}currentCurrency`)}
            </Typography>
            <Box display={'flex'}>
              <Typography color="text.disabled" variant="subtitle1">
                {t('common.currencies.usd')}
              </Typography>
              <IconButton onClick={handleSetLanguage} sx={{ padding: 0 }}>
                <KeyboardArrowDown color="primary" />
              </IconButton>
            </Box>
          </Box>
        </MyMenuItem>
        <MyMenuItem>
          <MyMenuIcon src={language} />
          <Box display="flex" alignItems={'flex-start'} justifyContent={'space-between'} flex={'1 0 0'}>
            <Typography color={'primary'} variant="subtitle1">
              {t(`${tRoot}currentLanguage`)}
            </Typography>
            <Box display={'flex'}>
              <Typography color="text.disabled" variant="subtitle1">
                {t(`${tRoot}selectedLanguage`)}
              </Typography>
              <IconButton onClick={handleSetLanguage} sx={{ padding: 0 }}>
                <KeyboardArrowDown color="primary" />
              </IconButton>
            </Box>
          </Box>
          <Menu anchorEl={lngAnchorEl} open={Boolean(lngAnchorEl)} onClose={handleCloseMenu}>
            <MenuList>
              <MyMenuItem onClick={() => i18n.changeLanguage('es')}>
                <Typography color="text.disabled" variant="subtitle1">
                  Español
                </Typography>
              </MyMenuItem>
              <MyMenuItem onClick={() => i18n.changeLanguage('en')}>
                <Typography color="text.disabled" variant="subtitle1">
                  English
                </Typography>
              </MyMenuItem>
            </MenuList>
          </Menu>
        </MyMenuItem>
        <MyMenuItem>
          <MyMenuIcon src={help} />
          <Typography variant="subtitle1" color={'primary'}>
            {t(`${tRoot}help`)}
          </Typography>
        </MyMenuItem>
        <Box padding={theme.spacing(6, 1)} display={'flex'} alignItems={'flex-start'} gap={1} justifyContent={'center'}>
          <LoadingButton
            loading={loading}
            fullWidth
            size="medium"
            color="primary"
            variant="contained"
            onClick={isAuthenticated ? handleLogout : doSignIn}
            startIcon={<Login />}
            sx={{
              borderRadius: theme.spacing(1),
            }}
          >
            <Typography sx={{ letterSpacing: '0.4px', fontWeight: 500 }} variant="subtitle2">
              {t(`${tRoot}${isAuthenticated ? 'logoutMesssage' : 'loginMesssage'}`)}
            </Typography>
          </LoadingButton>
        </Box>
      </MenuList>
    </SwipeableDrawer>
  );
};
