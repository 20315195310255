import { Add, Remove } from '@mui/icons-material';
import { Box, IconButton, Typography, useTheme } from '@mui/material';

interface CounterItemProps {
  icon: JSX.Element;
  itemText: string;
  helperText: string;
  amount: number;
  onAdd: () => void;
  onRemove: () => void;
}

export const CounterItem = ({ icon, itemText, helperText, amount, onRemove, onAdd }: CounterItemProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" flex="1 0 0" padding={theme.spacing(1, 3)}>
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box display="flex" width={141.5} alignItems="center" gap={1}>
          {icon}
          {itemText}
        </Box>
        <Typography color="warning.main" variant="body2">
          {helperText}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <IconButton
          disabled={amount === 0}
          onClick={onRemove}
          sx={{
            backgroundColor: theme.palette.primaryAlternative.darker,
            '&.Mui-disabled': {
              backgroundColor: theme.palette.primaryAlternative.lighter,
            },
          }}
        >
          <Remove color="primary" />
        </IconButton>
        <Typography padding={theme.spacing(0, 2)}>{amount}</Typography>
        <IconButton onClick={onAdd} sx={{ backgroundColor: theme.palette.primaryAlternative.darker }}>
          <Add color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
};
