export const routes = {
  error404: '/404',
  // Flights
  flights: '/',
  // Cars
  cars: '/cars',
  // Explorer
  explorer: '/explorer',
  // Users
  users: '/users',
  // HOTELS
  hotels: '/hotels',
  hotelsAvailability: '/hotels/shopping',
  hotelMarkupRules: '/hotels/settings/markup-rules',
  hotelsRoutingRules: '/hotels/settings/routing-rules',
  hotelContentManager: '/hotels/settings/content-manager',
  hotelDetailContentItem: '/hotel/settings/hotels-content/:id',
  hotelNewMarkupRule: '/hotels/settings/markup-rules/new',
  // Dashboard with tools for Admins, Operators, and Agents
  dashboard: '/dashboard',
};
