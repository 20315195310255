import { useEffect, useState } from 'react';
import { Alert, Box, Drawer, Grid, MenuItem, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { IEditUser, DocumentType, IMyProfileData } from '../../types';
import { ControlledTextField } from '../../../common/components/ControlledTextField';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { ControlledSelect } from '../../../common/components/ControlledSelect';
import { countries } from '../../../common/constants/countries';

import { usePatchMe } from '../../mutations';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  initialData: IMyProfileData | null;
}

export const EditUser = ({ isOpen, onClose, initialData }: Props) => {
  const { t } = useTranslation();

  const resolver = yupResolver(
    yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      phoneCountryCode: yup
        .string()
        .test((value) => value !== 'no-selection')
        .required(),
      phoneNumber: yup.string().required(),
      address1: yup.string().required(),
      address2: yup.string().optional(),
      country: yup
        .string()
        .test((value) => value !== 'no-selection')
        .required(),
      city: yup.string().required(),
      postalCode: yup.string().required(),
      documentType: yup.mixed<DocumentType>().oneOf(Object.values(DocumentType)).required(),
      documentNumber: yup.string().required(),
    }),
  );

  const methods = useForm<IEditUser>({
    resolver,
  });
  const { control, handleSubmit, reset, setValue } = methods;

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { mutate } = usePatchMe({
    onSuccess: () => {
      onClose && onClose();
    },
  });

  const onSubmit = async (data: IEditUser) => {
    setLoading(true);
    mutate(data);
  };

  const { isSmallUp } = useBreakpoints();

  useEffect(() => {
    if (isOpen) {
      reset();
      if (initialData) {
        setValue('firstName', initialData.firstName);
        setValue('lastName', initialData.lastName);
        setValue('phoneCountryCode', initialData.phoneCountryCode);
        setValue('phoneNumber', initialData.phoneNumber);
        setValue('address1', initialData.address1);
        setValue('address2', initialData.address2);
        setValue('country', initialData.country);
        setValue('city', initialData.city);
        setValue('postalCode', initialData.postalCode);
        setValue('documentType', initialData.documentType as DocumentType);
        setValue('documentNumber', initialData.documentNumber);
      }
      setShowError(false);
      setLoading(false);
    }
  }, [reset, setShowError, setLoading, isOpen, initialData]);

  return (
    <Drawer anchor={isSmallUp ? 'right' : 'bottom'} open={isOpen} onClose={onClose}>
      <Box display="flex" flexDirection="column" minWidth={390} maxWidth={700} padding={2}>
        <Typography variant="h6">{t('auth.editUser.title')}</Typography>

        <Typography variant="subtitle1" align="left" color="text.secondary">
          {t('auth.editUser.subtitle')}
        </Typography>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {showError && (
            <Box mb={2}>
              <Alert severity="error">{t('auth.editUser.failMessage')}</Alert>
            </Box>
          )}
          <Grid container spacing={1} sx={{ maxWidth: 600 }}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                required
                control={control}
                placeholder={t('auth.editUser.firstName.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.firstName.errorMessage')}
                fullWidth
                helperText={t('auth.editUser.firstName.helperText')}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                required
                control={control}
                placeholder={t('auth.editUser.lastName.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.lastName.errorMessage')}
                fullWidth
                helperText={t('auth.editUser.lastName.helperText')}
              />
            </Grid>

            <Grid item xs={4}>
              <ControlledSelect
                name="phoneCountryCode"
                required
                control={control}
                placeholder={t('auth.editUser.phoneCountryCode.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.lastName.errorMessage')}
                defaultValue={'no-selection'}
                fullWidth
              >
                <MenuItem value={'no-selection'}>{t('auth.editUser.phoneCountryCode.placeHolder')}</MenuItem>
                {countries.map((item, index) => (
                  <MenuItem key={index} value={item.phoneCode}>
                    {item.name} (+{item.phoneCode})
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>

            <Grid item xs={8}>
              <ControlledTextField
                name="phoneNumber"
                required
                control={control}
                placeholder={t('auth.editUser.phoneNumber.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.phoneNumber.errorMessage')}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                name="address1"
                required
                control={control}
                placeholder={t('auth.editUser.address1.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.address1.errorMessage')}
                fullWidth
                helperText={t('auth.editUser.address1.helperText')}
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                name="address2"
                placeholder={t('auth.editUser.address2.placeHolder')}
                control={control}
                size="small"
                errorMessage={t('auth.editUser.address2.errorMessage')}
                fullWidth
                helperText={t('auth.editUser.address2.helperText')}
              />
            </Grid>

            <Grid item xs={4}>
              <ControlledSelect
                name="country"
                required
                control={control}
                placeholder={t('auth.editUser.country.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.country.errorMessage')}
                defaultValue={'no-selection'}
                fullWidth
              >
                <MenuItem value={'no-selection'}>{t('auth.editUser.country.placeHolder')}</MenuItem>
                {countries.map((item, index) => (
                  <MenuItem key={index} value={item.code}>
                    {item.name}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </Grid>
            <Grid item xs={4}>
              <ControlledTextField
                name="city"
                required
                control={control}
                placeholder={t('auth.editUser.city.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.city.errorMessage')}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <ControlledTextField
                name="postalCode"
                required
                control={control}
                placeholder={t('auth.editUser.postalCode.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.postalCode.errorMessage')}
                fullWidth
              />
            </Grid>

            <Grid item xs={4}>
              <ControlledSelect
                name="documentType"
                required
                control={control}
                placeholder={t('auth.editUser.documentType.helperText')}
                size="small"
                errorMessage={t('auth.editUser.documentType.errorMessage')}
                fullWidth
                defaultValue={DocumentType.CI}
                helperText={t('auth.editUser.documentType.helperText')}
              >
                <MenuItem value={DocumentType.CI}>{DocumentType.CI}</MenuItem>
                <MenuItem value={DocumentType.DNI}>{DocumentType.DNI}</MenuItem>
                <MenuItem value={DocumentType.PASSPORT}>{DocumentType.PASSPORT}</MenuItem>
              </ControlledSelect>
            </Grid>
            <Grid item xs={8}>
              <ControlledTextField
                name="documentNumber"
                required
                control={control}
                placeholder={t('auth.editUser.documentNumber.placeHolder')}
                size="small"
                errorMessage={t('auth.editUser.documentNumber.errorMessage')}
                fullWidth
                helperText={t('auth.editUser.documentNumber.helperText')}
              />
            </Grid>

            <Grid item xs={12}>
              <LoadingButton loading={loading} fullWidth type="submit" variant="contained" color="primary">
                {t('auth.editUser.applyChangesBtn')}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <Box display="flex" flexDirection="column" gap={1}></Box>
    </Drawer>
  );
};
