import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, useTheme } from '@mui/material';

import { CardProvider } from './CardProvider';
import { providerData } from './constants';

export const HotelContentProvaiderDescription = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      padding={theme.spacing(3)}
      margin={theme.spacing(5, 0)}
      borderRadius={theme.spacing(2)}
      border={`1px solid ${theme.palette.primary.contrastText}`}
      boxShadow={theme.shadows[4]}
    >
      <Box padding={theme.spacing(3)} borderRadius={theme.spacing(2)} border={`1px solid ${theme.palette.divider}`}>
        {providerData.map((provider, index) => (
          <>
            <CardProvider key={index} providerData={provider} />
            <Divider flexItem sx={{ margin: theme.spacing(3, 0) }} />
          </>
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" padding={theme.spacing(3, 0)}>
        <Button variant="contained">{t('hotels.contentManager.hotelContentProviderDescription.buttonSave')}</Button>
      </Box>
    </Box>
  );
};
