export const data = [
  {
    id: 1,
    location: 'Cancún',
    name: 'Playa del Sol Resort',
    idMevuelo: 1,
    idTravelgate: 101,
    impressions: 1500,
    status: 'complete',
  },
  {
    id: 2,
    location: 'Barcelona',
    name: 'Majestic Heights Hotel',
    idMevuelo: 2,
    idTravelgate: 102,
    impressions: 1200,
    status: 'in progress',
  },
  {
    id: 3,
    location: 'New York',
    name: 'Metropolitan Plaza Inn',
    idMevuelo: 3,
    idTravelgate: 103,
    impressions: 800,
    status: 'failed',
  },
  {
    id: 4,
    location: 'Paris',
    name: 'Eiffel Grand Hotel',
    idMevuelo: 4,
    idTravelgate: 104,
    impressions: 2000,
    status: 'complete',
  },
  {
    id: 5,
    location: 'Tokyo',
    name: 'Sakura Garden Hotel',
    idMevuelo: 5,
    idTravelgate: 105,
    impressions: 1800,
    status: 'in progress',
  },
  {
    id: 6,
    location: 'Rome',
    name: 'Roman Heritage Suites',
    idMevuelo: 6,
    idTravelgate: 106,
    impressions: 1000,
    status: 'failed',
  },
  {
    id: 7,
    location: 'Sydney',
    name: 'Harbourview Oasis Hotel',
    idMevuelo: 7,
    idTravelgate: 107,
    impressions: 2200,
    status: 'complete',
  },
  {
    id: 8,
    location: 'Marrakech',
    name: 'Desert Rose Resort',
    idMevuelo: 8,
    idTravelgate: 108,
    impressions: 900,
    status: 'in progress',
  },
  {
    id: 9,
    location: 'Barcelona',
    name: 'Sunset View Hotel',
    idMevuelo: 9,
    idTravelgate: 109,
    impressions: 750,
    status: 'completed',
  },
  {
    id: 10,
    location: 'Tokyo',
    name: 'Cherry Blossom Inn',
    idMevuelo: 10,
    idTravelgate: 110,
    impressions: 1200,
    status: 'in progress',
  },
  {
    id: 11,
    location: 'Sydney',
    name: 'Harbor Lights Resort',
    idMevuelo: 11,
    idTravelgate: 111,
    impressions: 600,
    status: 'completed',
  },
  {
    id: 12,
    location: 'New York',
    name: 'Cityscape Suites',
    idMevuelo: 12,
    idTravelgate: 112,
    impressions: 950,
    status: 'in progress',
  },
  {
    id: 13,
    location: 'Paris',
    name: 'Eiffel Tower Lodge',
    idMevuelo: 13,
    idTravelgate: 113,
    impressions: 850,
    status: 'completed',
  },
  {
    id: 14,
    location: 'Rio de Janeiro',
    name: 'Carnival Beach Resort',
    idMevuelo: 14,
    idTravelgate: 114,
    impressions: 1100,
    status: 'in progress',
  },
  {
    id: 15,
    location: 'Cape Town',
    name: 'Table Mountain View Hotel',
    idMevuelo: 15,
    idTravelgate: 115,
    impressions: 700,
    status: 'completed',
  },
  {
    id: 16,
    location: 'Bangkok',
    name: 'Silk Road Palace',
    idMevuelo: 16,
    idTravelgate: 116,
    impressions: 800,
    status: 'in progress',
  },
  {
    id: 17,
    location: 'Berlin',
    name: 'Brandenburg Gate Inn',
    idMevuelo: 17,
    idTravelgate: 117,
    impressions: 1000,
    status: 'completed',
  },
  {
    id: 18,
    location: 'Amsterdam',
    name: 'Canal Cruise Apartments',
    idMevuelo: 18,
    idTravelgate: 118,
    impressions: 950,
    status: 'in progress',
  },
  {
    id: 19,
    location: 'Dubai',
    name: 'Skyline Oasis Hotel',
    idMevuelo: 19,
    idTravelgate: 119,
    impressions: 1200,
    status: 'completed',
  },
  {
    id: 20,
    location: 'Seoul',
    name: 'K-Pop Paradise Resort',
    idMevuelo: 20,
    idTravelgate: 120,
    impressions: 700,
    status: 'in progress',
  },
  {
    id: 21,
    location: 'Montreal',
    name: 'Maple Leaf Lodge',
    idMevuelo: 21,
    idTravelgate: 121,
    impressions: 850,
    status: 'completed',
  },
  {
    id: 22,
    location: 'Venice',
    name: 'Grand Canal Suites',
    idMevuelo: 22,
    idTravelgate: 122,
    impressions: 1100,
    status: 'in progress',
  },
  {
    id: 23,
    location: 'Prague',
    name: 'Castle View Apartments',
    idMevuelo: 23,
    idTravelgate: 123,
    impressions: 600,
    status: 'completed',
  },
];

export const title = ['Ubicacion', 'Nombre', 'ID Me vuelo', 'ID travelgate', 'Impresiones', 'Estatus'];
