import { AbilityTuple, MongoAbility, MongoQuery } from '@casl/ability';
import { Dashboard, DirectionsCar, Explore, Flight, Hotel } from '@mui/icons-material';

import { routes } from '../../../../router/routes.ts';

export const getLinks = (ability: false | MongoAbility<AbilityTuple, MongoQuery>) => {
  const registeredLinks = [
    {
      name: 'common.nav.flights',
      link: routes.flights,
      logo: <Flight />,
    },
    {
      name: 'common.nav.hotels',
      link: routes.hotels,
      logo: <Hotel />,
    },
    {
      name: 'common.nav.cars',
      link: routes.cars,
      logo: <DirectionsCar />,
    },
    {
      name: 'common.nav.explorer',
      link: routes.explorer,
      logo: <Explore />,
    },
  ];

  const adminLinks = [
    {
      name: 'common.nav.dashboard',
      link: routes.dashboard,
      logo: <Dashboard />,
    },
  ];

  const links = [...registeredLinks];

  if (ability && ability.can('view', 'Dashboard')) {
    for (const adminLink of adminLinks) {
      links.push(adminLink);
    }
  }

  return links;
};
