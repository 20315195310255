import { Drawer, styled } from '@mui/material';

export const RoomMenuDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: theme.spacing(3, 3, 0, 0),
  },
  '.MuiDrawer-paper': {
    height: '90%',
  },
  '.MuiList-root': {
    paddingTop: 0,
  },
}));
