import logoMevuelo from './logoMevuelo.svg';
import logoMevueloWhite from './logoMevueloWhite.svg';
import logoQrCode from './qrCode.png';
import logoInstagram from './instagram.svg';
import logoInstagramWhite from './instagram--white.svg';
import logoLinkedin from './linkedin.svg';
import logoLinkedinWhite from './linkedin--white.svg';
import logoFacebook from './facebook.svg';
import logoFacebookWhite from './facebook--white.svg';
import logoYoutube from './youtube.svg';
import logoYoutubeWhite from './youtube--white.svg';
import logoTwitter from './twitter.svg';
import logoTwitterWhite from './twitter--white.svg';
import logoWhatsapp from './whatsapp.svg';
import logoWhatsappWhite from './whatsapp--white.svg';
import logoBancard from './bancard.png';
import logoPci from './pci.png';
import logoSupportAgent from './supportAgent.svg';
import logosSecurityEncryption from './securityEncryption.svg';
import logoWallet from './wallet.svg';
import logoBackPack from './backpack.svg';
import logoBank from './bank.svg';
import logoCard from './card.svg';
import logoCash from './cash.svg';
import logoVisa from './visa.svg';
import logoOca from './oca.svg';
import logoAmerican from './american.svg';
import logoMasterCard from './mastercard.svg';
import fbLoginAsset from './fb_login.png';
import googleLoginAsset from './google_login.png';
import propertyExample from './hotelExampleProperty.svg';
import chatGpt from './logoChatgpt.svg';
import homeBackground from './background.webp';

// icons
import iconArrowDown from './arrowDown.svg';
import iconLanguage from './languageIcon.svg';
import iconDisconnectedUser from './disconnectedUser.svg';
import iconHeart from './heart.svg';
import iconCurrency from './currency.svg';
import iconBell from './bell.svg';
import iconHelp from './help.svg';
import iconTicket from './ticket.svg';
import iconFlame from './flame.svg';
import unionIcon from './Union.svg';
import iconSpain from './iconSpain.svg';
import iconUnitedStates from './iconUnitedStates.svg';
import iconBrazil from './iconBrazil.svg';

// logos
export const logo = logoMevuelo;
export const logoDesktop = logoMevueloWhite;
export const qrCode = logoQrCode;
export const instagram = logoInstagram;
export const instagramWhite = logoInstagramWhite;
export const linkedin = logoLinkedin;
export const linkedinContrast = logoLinkedinWhite;
export const facebook = logoFacebook;
export const facebookContrast = logoFacebookWhite;
export const youtube = logoYoutube;
export const youtubeContrast = logoYoutubeWhite;
export const twitter = logoTwitter;
export const twitterContrast = logoTwitterWhite;
export const whatsapp = logoWhatsapp;
export const whatsappContrast = logoWhatsappWhite;
export const bancard = logoBancard;
export const pci = logoPci;
export const supportAgent = logoSupportAgent;
export const securityEncryption = logosSecurityEncryption;
export const wallet = logoWallet;
export const backpack = logoBackPack;
export const bank = logoBank;
export const card = logoCard;
export const cash = logoCash;
export const visa = logoVisa;
export const oca = logoOca;
export const american = logoAmerican;
export const mastercard = logoMasterCard;
export const fbLoginIcon = fbLoginAsset;
export const googleLoginIcon = googleLoginAsset;
export const propertyExampleImage = propertyExample;
export const homeBackgroundImage = homeBackground;
export const logoChatGpt = chatGpt;

// icons
export const disconnectedUser = iconDisconnectedUser;
export const arrowDown = iconArrowDown;
export const language = iconLanguage;
export const heart = iconHeart;
export const currency = iconCurrency;
export const bell = iconBell;
export const help = iconHelp;
export const ticket = iconTicket;
export const flame = iconFlame;
export const unionArrow = unionIcon;
export const flagSpain = iconSpain;
export const flagUnitedStates = iconUnitedStates;
export const flagBrazil = iconBrazil;
