import { Box } from '@mui/material';
import { Footer } from '../Footer';
import { Outlet } from 'react-router-dom';
import { PaymentMethods } from './paymentMethods';
import { useBreakpoints } from '../../hooks/useBreakpoints';

interface SublayoutProps {
  noFooter?: boolean;
  noPayments?: boolean;
  noMt?: boolean;
  noLargeDownMt?: boolean;
}

const getMarginTop = (noMt?: boolean, noLargeDownMt?: boolean) => {
  if (noMt) {
    return 0;
  }
  const { isLargeDown, isLargeUp } = useBreakpoints();
  if (isLargeDown && noLargeDownMt) {
    return 0;
  }
  return isLargeUp ? 8 : 16;
};

export const Sublayout = ({ noFooter, noPayments, noMt, noLargeDownMt }: SublayoutProps) => (
  <Box bgcolor="paper.background">
    <Box mt={getMarginTop(noMt, noLargeDownMt)}>
      <Outlet />
    </Box>
    {!noPayments && <PaymentMethods />}
    {!noFooter && <Footer />}
  </Box>
);
