import { Box, Button, Typography, useTheme } from '@mui/material';
import { logoDesktop } from '../../assets';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// NOTE: found an infinite loop, when users want to go back using browser buttons
export const Error404 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackHome = () => navigate('/');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.main,
        gap: 3,
      }}
    >
      <img width={330} src={logoDesktop} alt="me vuelo" />
      <Typography padding={theme.spacing(3, 0)} variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        {t('error404.message')}
      </Typography>
      <Button variant="contained" onClick={handleBackHome}>
        {t('error404.btnLabel')}
      </Button>
    </Box>
  );
};
