import { Avatar, Box, Button, Grid, IconButton, Theme, Typography, useTheme } from '@mui/material';
import { Hotel, Tune } from '@mui/icons-material';
import { Map } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { TFunction } from 'i18next';

import { MobileAvailabilityLayout } from '../../features/common/components/AvailabilityLayout/MobileAvailabilityLayout';
import { useBreakpoints } from '../../features/common/hooks/useBreakpoints';
import { MobileMapView } from '../../features/common/components/MapView/MobileMapView';
import { BackToolbar } from '../../features/common/components/BackToolbar';
import { PriceTrend } from '../../features/common/components/PriceTrend';
import { HotelAvailabilityTitle } from '../../features/hotel/components/HotelAvailabilityTitle';
import { HotelAvailabilitySearcher } from '../../features/hotel/components/HotelAvailabilitySearcher';
import { HotelAvailabilityFilter } from '../../features/hotel/components/HotelAvailabilityFilter';
import { HotelAvailabilityList } from '../../features/hotel/components/HotelAvailabilityList';
import { HotelPropertyMapDrawer } from '../../features/hotel/components/HotelPropertyMapDrawer';
import { markers } from './mocks/markers.mock';
import { propertyAmenities, propertyImages } from './mocks/property.mock';
import { dtoMock } from './mocks/availability.mock';

interface MobileAvailabilityProps {
  isSmallUp: boolean;
  openMap: boolean;
  openEdit: boolean;
  openFilter: boolean;
  zoom: number;
  center: google.maps.LatLngLiteral;
  t: TFunction<'translation', undefined>;
  theme: Theme;
  handleMap: () => void;
  handleBack: () => void;
  handleEdit: () => void;
  handleTune: () => void;
}

const useAvailabilityListPadding = () => {
  const theme = useTheme();
  const { isSmallUp, isLargeUp } = useBreakpoints();
  if (isLargeUp) return theme.spacing(0);
  if (isSmallUp) return theme.spacing(2, 4);
  return theme.spacing(2);
};

export const MobileAvailability = ({
  zoom,
  center,
  t,
  theme,
  isSmallUp,
  openMap,
  openEdit,
  openFilter,
  handleMap,
  handleBack,
  handleEdit,
  handleTune,
}: MobileAvailabilityProps) =>
  openMap ? (
    <>
      <MobileMapView zoom={zoom} markers={markers} center={center} title={dtoMock.destination} onBack={handleMap} />
      <HotelPropertyMapDrawer
        amenities={propertyAmenities}
        carouselImages={propertyImages}
        name="Mykonos Town Boutique Hotel"
        starAmount={4.1}
        hotelDescription="Hotel 4.1 estrellas"
        distanceHint="A 200mts de Oklahoma"
        valueAmount="99.999.999.999"
        benefits={[
          <>
            <Check color="success" />
            <Typography variant="body1" color="initial">
              Cancelación gratis
            </Typography>
          </>,
          <>
            <Check color="success" />
            <Typography variant="body1" color="initial">
              Sin pago adelantado
            </Typography>
          </>,
        ]}
      />
    </>
  ) : (
    <>
      <MobileAvailabilityLayout
        title={
          <HotelAvailabilityTitle
            destination={dtoMock.destination}
            date={dtoMock.date}
            guestsAmount={dtoMock.guestsAmount}
            roomAmount={dtoMock.roomAmount}
          />
        }
        onBack={handleBack}
        onEdit={handleEdit}
        openEdit={openEdit}
        openFilter={openFilter}
        edit={
          <Box display="flex" flexDirection="column">
            <BackToolbar title={t('hotels.availabilityLayout.editSearchTitle')} handleBack={handleEdit} />
            <HotelAvailabilitySearcher />
          </Box>
        }
        filter={<HotelAvailabilityFilter darkMode destination={dtoMock.destination} />}
      >
        <Avatar
          aria-label={t('hotels.availabilityLayout.avatarAriaLabel')}
          variant="circular"
          sx={{ background: 'none', color: 'primary.dark', border: '2px solid', marginRight: theme.spacing(2) }}
        >
          <Hotel />
        </Avatar>
        <Typography flex="1 0 0" variant="body1" color="text.primary">
          {dtoMock.resultAmount} {t('hotels.availabilityLayout.results')}
        </Typography>
        {isSmallUp ? (
          <Button
            sx={{ padding: 1 }}
            aria-label={t('hotels.availabilityLayout.viewMapAriaLabel')}
            color="primary"
            startIcon={<Map color="primary" />}
            onClick={handleMap}
          >
            {isSmallUp && t('hotels.availabilityLayout.viewMap').toUpperCase()}
          </Button>
        ) : (
          <IconButton aria-label={t('hotels.availabilityLayout.viewMapAriaLabel')} onClick={handleMap}>
            <Map color="primary" />
          </IconButton>
        )}
        <IconButton aria-label={t('hotels.availabilityLayout.tuneFiltersAriaLabel')} onClick={handleTune}>
          <Tune color="primary" />
        </IconButton>
      </MobileAvailabilityLayout>
      <Box display="flex" flex="1 0 0" p={useAvailabilityListPadding} justifyContent="center">
        <PriceTrend />
      </Box>
      <Grid container p={useAvailabilityListPadding} gap={4} spacing={0} justifyContent="center">
        <HotelAvailabilityList />
      </Grid>
    </>
  );
