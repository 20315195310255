import FormControl from '@mui/material/FormControl/FormControl';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { Control, Controller } from 'react-hook-form';
import { Dayjs } from 'dayjs';

type ControlledDatePicker = DatePickerProps<Dayjs | null> & {
  control: Control<any, any>;
  name: string;
  disabledChecks: boolean;
  required?: boolean;
  errorMessage?: string;
  helperText?: string;
  showPicker?: boolean;
  icon?: React.ElementType;
  handleAccept: (value: Dayjs | null) => void;
};

export const ControlledDatePicker = ({
  name,
  control,
  disabledChecks,
  showPicker,
  handleAccept,
  ...props
}: ControlledDatePicker) => (
  <Controller
    control={control}
    name={name}
    rules={{ required: !disabledChecks }}
    render={({ field: { onChange, ref }, fieldState: { error } }) => (
      <FormControl error={Boolean(error)} sx={{ width: '100%' }}>
        <DatePicker
          {...props}
          inputRef={ref}
          onChange={(event) => {
            onChange(event);
          }}
          disabled={disabledChecks}
          open={showPicker}
          onAccept={handleAccept}
          slots={props.icon ? { openPickerIcon: props.icon } : undefined}
          slotProps={{
            inputAdornment: { position: 'start' },
          }}
        />
      </FormControl>
    )}
  />
);
