import React from 'react';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import {
  securityEncryption,
  wallet,
  backpack,
  card,
  bank,
  cash,
  visa,
  american,
  mastercard,
  oca,
} from '../../../../assets';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { useTranslation } from 'react-i18next';

const PaymentMethodCard = () => {
  const { t } = useTranslation();
  const { isSmallUp, isLargeUp } = useBreakpoints();

  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          padding={theme.spacing(3)}
          border={isSmallUp ? `1px solid ${theme.palette.divider}` : 'none'}
          borderRadius="32px"
          // maxWidth={326}
        >
          <Box display="flex" justifyContent="center" alignItems="center" height={250}>
            <img
              src={securityEncryption}
              style={{ width: isLargeUp ? 369 : 82, height: 94 }}
              alt={t('common.footer.payments.altImages.securityEncryption')}
            />
          </Box>
          <Box display="flex" flexDirection="column" alignSelf="stretch" width={'100%'}>
            <Typography variant="subtitle1" color={theme.palette.primary.main} textAlign="start">
              {t('common.footer.payments.certification.title')}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary} textAlign="start">
              {t('common.footer.payments.certification.description')}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          padding={theme.spacing(3)}
          border={isSmallUp ? `1px solid ${theme.palette.divider}` : 'none'}
          borderRadius="32px"
          maxWidth={326}
        >
          <Box display="flex" justifyContent="center" alignItems="center" height={250}>
            <img src={wallet} style={{ width: 82, height: 94 }} alt={t('common.footer.payments.altImages.wallet')} />
          </Box>

          <Box display="flex" flexDirection="column" alignSelf="stretch" width="100%">
            <Typography variant="subtitle1" color={theme.palette.primary.main} textAlign="start">
              {t('common.footer.payments.intelligentTravel.title')}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary} textAlign="start">
              {t('common.footer.payments.intelligentTravel.description')}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={4} display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          padding={theme.spacing(3)}
          border={isSmallUp ? `1px solid ${theme.palette.divider}` : 'none'}
          borderRadius="32px"
          maxWidth={326}
        >
          <Box display="flex" justifyContent="center" alignItems="center" height={250}>
            <img
              src={backpack}
              style={{ width: 82, height: 94 }}
              alt={t('common.footer.payments.altImages.backpack')}
            />
          </Box>

          <Box display="flex" flexDirection="column" alignSelf="stretch" width="100%">
            <Typography variant="subtitle1" color={theme.palette.primary.main} textAlign="start">
              {t('common.footer.payments.explore.title')}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary} textAlign="start">
              {t('common.footer.payments.explore.description')}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const PaymentMethods: React.FC = () => {
  const theme = useTheme();
  const { isSmallDown } = useBreakpoints();
  const { t } = useTranslation();
  return (
    <Container>
      <Box display="flex" justifyContent="center">
        <PaymentMethodCard />
      </Box>
      <Box
        display="flex"
        flexDirection={isSmallDown ? 'column' : 'row'}
        justifyContent="space-around"
        alignItems="flex-start"
        alignSelf="stretch"
        mt={10}
        gap={theme.spacing(3)}
      >
        <Box>
          <Typography variant={isSmallDown ? 'h6' : 'h5'} color={'text.primary'} mb="20px">
            {t('common.footer.payments.paymentMethods.title')}
          </Typography>
          <Box display="flex" mb={1}>
            <img src={card} alt={t('common.footer.payments.altImages.card')} />
            <Typography variant={isSmallDown ? 'body2' : 'body1'} color={'text.secondary'} marginLeft="5px">
              {t('common.footer.payments.paymentMethods.credit')}
            </Typography>
          </Box>
          <Box display="flex" mb={1}>
            <img src={bank} alt={t('common.footer.payments.altImages.bank')} />
            <Typography variant={isSmallDown ? 'body2' : 'body1'} color={'text.secondary'} ml={1}>
              {t('common.footer.payments.paymentMethods.bankTransfers')}
            </Typography>
          </Box>
          <Box display="flex" mb={1}>
            <img src={cash} alt={t('common.footer.payments.altImages.cash')} />
            <Typography variant={isSmallDown ? 'body2' : 'body1'} color={'text.secondary'} marginLeft="5px">
              {t('common.footer.payments.paymentMethods.cash')}
            </Typography>
          </Box>
        </Box>

        <Box
          width={isSmallDown ? '100%' : 568}
          display="flex"
          flexWrap="wrap"
          justifyContent={isSmallDown ? 'space-around' : 'space-between'}
          padding={isSmallDown ? '0' : `${theme.spacing(3)}`}
          mt={isSmallDown ? '40px' : '0'}
          mb="50px"
        >
          <img src={visa} alt={t('common.footer.payments.altImages.visa')} style={{ height: 50, width: 73 }} />
          <img
            src={american}
            alt={t('common.footer.payments.altImages.american')}
            style={{
              backgroundColor: '#016FD0',
              height: 50,
              width: 74,
              borderRadius: '8px',
              marginBottom: 10,
              padding: '2px 6px',
            }}
          />
          <img
            src={mastercard}
            alt={t('common.footer.payments.altImages.mastercard')}
            style={{
              height: 50,
              width: 74,
              marginBottom: 10,
            }}
          />
          <img
            src={oca}
            alt={t('common.footer.payments.altImages.oca')}
            style={{
              backgroundColor: '#016FD0',
              height: 50,
              width: 74,
              borderRadius: '8px',
              padding: '2px 6px',
              marginBottom: 10,
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};
