import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material';
import { Controller, Control } from 'react-hook-form';

type ControlledSelectProps = SelectProps & {
  control: Control<any, any>;
  name: string;
  required?: boolean;
  errorMessage?: string;
  helperText?: string;
};

export const ControlledSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  errorMessage,
  helperText,
  ...props
}: ControlledSelectProps) => {
  const labelId = `${name}-label`;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value = '', ref }, fieldState: { error } }) => (
        <>
          <FormControl error={Boolean(error)} sx={{ width: props.fullWidth ? '100%' : 'auto' }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
              {...props}
            >
              {children}
            </Select>
            {error && errorMessage && (
              <FormHelperText id={`component-error-text-${name}`}>{errorMessage}</FormHelperText>
            )}
            {helperText && !(error && errorMessage) && (
              <FormHelperText id={`component-error-text-${name}`}>{helperText}</FormHelperText>
            )}
          </FormControl>
        </>
      )}
    />
  );
};
