import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

export const Card = styled('div')(() => {
  const theme = useTheme();
  return {
    display: 'flex',
    height: '119px',
    width: '100%',
    overflow: 'hidden',
    alignItems: 'center',
    borderRadius: '16px',
    marginLeft: 10,
    marginBottom: 15,
    boxShadow: theme.shadows[4],
  };
});
