import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, IconButton, InputBase, Paper, Typography, useTheme } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { routes } from '../../../../router/routes';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { ButtonConfig } from '../../../common/components/ButtonConfig';
import { ContentManagerList } from './ContentManagerList';
import { data } from './constants';
import { ContentCardImage } from '../HotelContentImages';

export const HotelContentManager = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { isLargeUp } = useBreakpoints();
  const navigate = useNavigate();

  const handleGoHome = () => navigate(routes.flights);

  return (
    <Container>
      <Box width="100%" pt={isLargeUp ? 10 : 20} pb={8}>
        <Button startIcon={<ArrowBackIcon />} onClick={handleGoHome}>
          {t('hotels.markupRules.buttonBackHome')}
        </Button>
        <ContentCardImage />

        <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%" m={theme.spacing(5, 0)}>
          <Typography variant="h5" color={theme.palette.text.primary}>
            {t('hotels.contentManager.title')}
          </Typography>
          <Box display="flex" alignItems="center" sx={{ color: theme.palette.primary.main }}>
            <SettingsIcon />
            <Typography variant="body2" marginLeft={theme.spacing(1)}>
              <ButtonConfig />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
          <Typography variant="subtitle1" color={theme.palette.text.primary}>
            {t('hotels.markupRules.listTitle')}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            margin={`${theme.spacing(3)} 0`}
          >
            <Paper
              component="form"
              sx={{
                p: '2 4',
                display: 'flex',
                alignItems: 'center',
                width: '517px',
                backgroundColor: deepPurple[50],
              }}
            >
              <IconButton type="button" sx={{ p: theme.spacing(1) }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={t('hotels.markupRules.search')}
                inputProps={{ 'aria-label': t('hotels.markupRules.search') }}
              />
            </Paper>
          </Box>

          <Typography variant="body2" color={theme.palette.text.secondary} margin={theme.spacing(3, 0)}>
            {data.length} {t('hotels.routingRules.numberOfResults')} | ({data.length})
            {t('hotels.routingRules.shownElements')}
          </Typography>

          <ContentManagerList />
        </Box>
      </Box>
    </Container>
  );
};
