export interface IEditUser {
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  address1: string;
  address2?: string;
  country: string;
  city: string;
  postalCode: string;
  documentType: DocumentType;
  documentNumber: string;
}

export interface IMyProfileData {
  lastUpdatedBy: string;
  phoneNumber: string;
  documentType: string;
  lastName: string;
  tokenIss: string;
  country: string;
  createdBy: string;
  firstName: string;
  city: string;
  cognitoSub: string;
  roles: string[];
  groups: string[];
  documentNumber: string;
  phoneCountryCode: string;
  postalCode: string;
  address1: string;
  address2: string;
  email: string;
}

export enum UserRoles {
  ADMINS = 'ADMINS',
  AGENTS = 'AGENTS',
  OPERATORS = 'OPERATORS',
  REGISTERED = 'REGISTERED',
}

export enum DocumentType {
  PASSPORT = 'PASSPORT',
  DNI = 'DNI',
  CI = 'CI',
}
