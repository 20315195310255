import Container from '@mui/material/Container/Container';
import Typography from '@mui/material/Typography/Typography';

export const Cars = () => (
  <Container component="main" maxWidth="xs">
    <Typography variant="h6" color="common.black">
      Esta es la home de autos!
    </Typography>
  </Container>
);
