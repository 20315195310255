import {
  facebook,
  facebookContrast,
  instagram,
  linkedin,
  linkedinContrast,
  twitter,
  twitterContrast,
  whatsapp,
  whatsappContrast,
  youtube,
  youtubeContrast,
} from '../../../../assets';

export const socialMediaIcons = [
  { src: instagram, alt: 'logo-instagram', contrastSrc: facebookContrast },
  { src: linkedin, alt: 'logo-linkedin', contrastSrc: linkedinContrast },
  { src: facebook, alt: 'logo-facebook', contrastSrc: facebookContrast },
  { src: youtube, alt: 'logo-youtube', contrastSrc: youtubeContrast },
  { src: twitter, alt: 'logo-twitter', contrastSrc: twitterContrast },
  { src: whatsapp, alt: 'logo-whatsapp', contrastSrc: whatsappContrast },
];

const tFooterRoot = 'common.footer.';

export const contactUs = `${tFooterRoot}contactMessage`;
export const companyType = `${tFooterRoot}companyType`;
export const identity = `${tFooterRoot}identity`;
