import { Add, Search, Tune } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { stringToAvatar } from '../../common/utils/helpers';
import { useBreakpoints } from '../../common/hooks/useBreakpoints';
import { FiltersDrawerHandler } from '../types';
import { useGetAllUsers } from '../queries';
import { disconnectedUser } from '../../../assets';

export const UserList = ({ setFiltersDrawerOpen }: FiltersDrawerHandler) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMediumUp } = useBreakpoints();

  const { data } = useGetAllUsers({});

  return (
    <Box width="100%">
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          sx={!isMediumUp ? { margin: 0, padding: 0 } : undefined}
        >
          {t('users.list')}
        </Typography>
        {!isMediumUp && (
          <IconButton onClick={() => setFiltersDrawerOpen()}>
            <Tune />
          </IconButton>
        )}
      </Stack>

      <Box marginY={theme.spacing(2)}>
        <Box display="flex">
          <Autocomplete
            fullWidth
            options={data?.data ? data.data : []}
            renderInput={(params) => (
              <TextField
                placeholder={t('users.search')}
                {...params}
                InputProps={{
                  startAdornment: <Search />,
                }}
              />
            )}
            renderOption={(_props, { firstName, lastName }) => `${firstName} ${lastName}`}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={isMediumUp ? <Add /> : null}
            sx={{ marginLeft: theme.spacing(2), minWidth: isMediumUp ? '190px' : 'none' }}
          >
            {isMediumUp ? t('users.new') : <Add />}
          </Button>
        </Box>
      </Box>

      <Box>
        {data?.data?.map(({ firstName, lastName, email, phoneNumber }) => (
          <Card
            key={email}
            sx={{
              margin: theme.spacing(3, 0),
              padding: theme.spacing(2, 1),
              cursor: 'pointer',
            }}
          >
            <Box display="flex" justifyContent="flex-start">
              <Avatar alt={`${name} ${lastName}`} src={disconnectedUser}>
                {stringToAvatar(`${name} ${lastName}`)}
              </Avatar>
              <Box ml={theme.spacing(2)} width="100%" display="flex" justifyContent="space-between" alignItems="center">
                <Stack alignItems="flex-start" width={isMediumUp ? '30%' : '100%'} mr={theme.spacing(1)}>
                  <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
                  {/* TODO */}
                  {/* <Typography variant="caption">{roles?.join(',')}</Typography> */}
                </Stack>
                <Typography variant="caption" width={isMediumUp ? '30%' : '100%'} textAlign="left">
                  {email}
                </Typography>
                {isMediumUp && (
                  <Typography variant="caption" width="30%" textAlign="left">
                    {phoneNumber}
                  </Typography>
                )}
              </Box>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};
