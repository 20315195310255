import { Grid } from '@mui/material';
import { HotelAvailabilityCard } from '../HotelAvailabilityCard';
import { availabilityCards } from './constants';

export const HotelAvailabilityList = () =>
  availabilityCards.map((element, index) => (
    <Grid item xs={12} key={index} width="100%">
      <HotelAvailabilityCard {...element} />
    </Grid>
  ));
