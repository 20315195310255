import { styled } from '@mui/material';

import { closedSidebarWidthRem, openSidebarWidthRem } from '../DashboardSidebar/constants';
import { DashboardContainerParent } from './helpers';

export const DashboardContainer = styled(DashboardContainerParent, {
  shouldForwardProp: (prop) => prop !== 'isSidebarOpen',
})(({ theme, isSidebarOpen }) => ({
  width: `calc(100vw - ${isSidebarOpen ? openSidebarWidthRem : closedSidebarWidthRem}rem)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));
