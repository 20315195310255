import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItemText, TablePagination, Typography, useTheme } from '@mui/material';

import { ContentManagerItem } from './ContentManagerItem';
import { data, title } from './constants';

export const ContentManagerList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const theme = useTheme();
  const { t } = useTranslation();

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          boxShadow: theme.shadows[1],
          borderRadius: theme.spacing(2),
        }}
      >
        <Box display="flex">
          {title.map((nameColumn, index) => (
            <ListItemText
              key={index}
              sx={{ color: theme.palette.text.primary, display: 'flex', justifyContent: 'center' }}
            >
              <Box display="flex" width="100%">
                <Typography variant="subtitle1">{nameColumn}</Typography>
              </Box>
            </ListItemText>
          ))}
        </Box>
        {(rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data).map(
          (item, index) => (
            <ContentManagerItem key={`${index}-${item.id}`} data={item} />
          ),
        )}
      </List>
      <Box display="flex" justifyContent="center" width="100%">
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 15, 20]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('hotels.contentManager.paginationRowsPerPage')}
        />
      </Box>
    </>
  );
};
