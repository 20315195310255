import { Box, Button, Card, CardMedia, Chip, Typography, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import StarsIcon from '@mui/icons-material/Stars';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { HotelAvailabilityCardProps } from './types';

import { iconArray } from '../HotelAvailabilityList/constants';
import { RatingStars } from './starHook';

export const HotelAvailabilityCardDesktop = ({
  title,
  rating,
  numberOfOpinions,
  proximity,
  localCurrency,
  price,
  img,
  cancellationPolicy,
  paymentPolicy,
}: HotelAvailabilityCardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      elevation={4}
      sx={{
        display: 'flex',
        position: 'relative',
        width: '100%',
        padding: theme.spacing(1),
        gap: theme.spacing(3),
      }}
    >
      <CardMedia
        component="img"
        alt="hotel image"
        height="307"
        sx={{
          width: 307,
          borderRadius: '10px',
        }}
        image={img}
      />

      <Box position="absolute" top={0} left={0} p={1} display="flex">
        <Chip
          avatar={<StarIcon sx={{ backgroundColor: 'transparent', color: theme.palette.text.primary }} />}
          label={t('hotels.availabilityCard.allInclusiveLabel')}
          size="small"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
            margin: theme.spacing(1),
          }}
        />
        <Chip
          avatar={<ThumbUpIcon sx={{ backgroundColor: 'transparent', color: theme.palette.text.primary }} />}
          label={t('hotels.availabilityCard.recommendedLabel')}
          size="small"
          sx={{ backgroundColor: green.A100, color: theme.palette.text.primary, margin: theme.spacing(1) }}
        />
      </Box>

      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" padding={theme.spacing(1)} justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="h6" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box>
                <RatingStars rating={rating} />
              </Box>
              <Typography variant="body1" color={theme.palette.text.primary} marginRight={theme.spacing(1)}>
                {rating}
              </Typography>
              <Typography variant="caption" color={theme.palette.text.disabled}>
                ({t('hotels.availabilityCard.opinions', { numberOfOpinions: numberOfOpinions })})
              </Typography>
            </Box>
          </Box>
          <MoreVertIcon sx={{ color: theme.palette.text.secondary }} />
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Chip avatar={<StarsIcon />} size="medium" sx={{ backgroundColor: 'transparent' }} />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {t('hotels.availabilityCard.hotelRating', { rating: rating })}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Chip avatar={<LocationOnIcon />} size="medium" sx={{ backgroundColor: 'transparent' }} />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {proximity}
            </Typography>
          </Box>
        </Box>
        <Box>
          {iconArray.map((Icon, index) => (
            <Chip key={index} avatar={<Icon />} size="medium" sx={{ backgroundColor: 'transparent' }} />
          ))}
        </Box>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Chip
              avatar={<CheckIcon style={{ color: theme.palette.success.main }} />}
              size="medium"
              sx={{ backgroundColor: 'transparent' }}
            />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {cancellationPolicy}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Chip
              avatar={<CheckIcon style={{ color: theme.palette.success.main }} />}
              size="medium"
              sx={{ backgroundColor: 'transparent' }}
            />
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {paymentPolicy}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          borderTop={`1px solid ${theme.palette.divider}`}
        >
          <Box marginTop={theme.spacing(2)}>
            <Typography variant="body2" color={theme.palette.text.disabled} textAlign="start">
              {t('hotels.availabilityCard.endValueText')}
            </Typography>
            <Typography variant="h6" color={theme.palette.primary.dark} textAlign="start">
              {localCurrency} {price}
            </Typography>
          </Box>
          <Button variant="contained" size="small">
            {t('hotels.cardPackage.reserveButtonText')}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};
