import { useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';

interface SelectItem {
  value: string;
  text: string;
}

interface Props {
  data: SelectItem[];
  onChange?: (items: SelectItem[]) => void;
}

const getStyles = (item: SelectItem, data: SelectItem[], theme: Theme) => ({
  fontWeight: data.find(({ value }) => value === item.value)
    ? theme.typography.fontWeightRegular
    : theme.typography.fontWeightMedium,
});
export default function MultipleSelectChip({ data }: Props) {
  const [selectedItems, setSelectedItems] = useState<SelectItem[]>([]);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<SelectItem[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(value as SelectItem[]);
  };

  return (
    <FormControl sx={{ width: '100%', p: theme.spacing(1, 0) }}>
      <Select
        multiple
        value={selectedItems}
        onChange={handleChange}
        input={<OutlinedInput sx={{ height: 'auto' }} />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map(({ text }) => (
              <Chip
                key={text}
                label={text}
                size="small"
                sx={{
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primaryAlternative.darker,
                }}
              />
            ))}
          </Box>
        )}
      >
        {data?.map((item, index) => (
          <MenuItem key={`${item.value}-${index}`} value={item as any} style={getStyles(item, selectedItems, theme)}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
