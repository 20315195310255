import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';

import { FeaturedCard } from '../FeaturedCard';
import { featuredSectionData } from './featuredSectionDataDummyData';

export const FeaturedSection = () => {
  const { isMediumDown } = useBreakpoints();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Container style={{ marginTop: '50px' }}>
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant={isMediumDown ? 'h6' : 'h5'} color={theme.palette.text.primary} width="100%" ml="20px">
          {featuredSectionData.title}
        </Typography>

        <Grid spacing={0} container>
          {featuredSectionData.items.map((item, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <FeaturedCard item={item} />
            </Grid>
          ))}
        </Grid>

        <Box width="100%" display="flex" justifyContent="center" marginTop="5px">
          <Button size="large" variant={isMediumDown ? 'text' : 'outlined'}>
            + {t('hotels.cardsDestination.seeMore')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
