import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, useTheme } from '@mui/material';

import logoChatGpt from '../../../../assets/logoChatgpt.svg';
import { CardDescription } from './CardDescription';
import { dataHotelDescription } from './constants';

export const HotelContentDescriptionEditor = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      padding={theme.spacing(3)}
      margin={theme.spacing(5, 0)}
      borderRadius={theme.spacing(2)}
      border={`1px solid ${theme.palette.primary.contrastText}`}
      boxShadow={theme.shadows[4]}
    >
      <Box padding={theme.spacing(3)} borderRadius={theme.spacing(2)} border={`1px solid ${theme.palette.divider}`}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Typography variant="h6" color={theme.palette.text.primary}>
            {t('hotels.contentManager.hotelContentDescriptionEditor.title')}
          </Typography>
          <Button variant="outlined" size="large">
            <img src={logoChatGpt} alt="Logo GPT" style={{ paddingRight: theme.spacing(1) }} />
            {t('hotels.contentManager.hotelContentDescriptionEditor.buttonEverythingWithGpt')}
          </Button>
        </Box>
        <Box padding={theme.spacing(3, 0)}>
          {dataHotelDescription.map((data, index) => (
            <CardDescription
              key={index}
              icon={data.icon}
              name={data.name}
              showStarsSelector={data.showStarsSelector}
              hotelStar={data.hotelStar}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
