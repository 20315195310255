export const propertyPackage = [
  {
    name: 'Vuelos',
    img: '',
  },
  {
    name: 'Hotel',
    img: '',
  },
  {
    name: 'Desayuno',
    img: '',
  },
  {
    name: 'Solarium',
    img: '',
  },
];
