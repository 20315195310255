import { MenuItem, styled } from '@mui/material';

export const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.75, 3),
}));

export const MyMenuIcon = styled('img')(({ theme }) => ({
  paddingRight: theme.spacing(3),
}));
