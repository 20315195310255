import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

interface BackToolbarProps {
  title: string;
  children?: React.ReactNode;
  handleBack: () => void;
}

export const BackToolbar = ({ title, children, handleBack }: BackToolbarProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      padding={theme.spacing(1, children ? 4 : 2)}
      alignItems="center"
      alignSelf="stertch"
      gap={2}
      boxShadow={4}
      zIndex={0}
      bgcolor="primary.main"
    >
      <IconButton aria-label="" onClick={handleBack} sx={{ padding: children ? 0 : 2 }}>
        <Close sx={{ color: 'primary.contrastText' }} />
      </IconButton>
      <Typography display="flex" variant="h6" color="primary.contrastText" flex="1 0 0">
        {title}
      </Typography>
      {children}
    </Box>
  );
};
