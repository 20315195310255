import { AccountCircle, Close } from '@mui/icons-material';
import { Box, Checkbox, Divider, FormControlLabel, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { roles } from './users.mock';
import { useBreakpoints } from '../../common/hooks/useBreakpoints';
import { FiltersDrawerHandler } from '../types';

export const UserFilters = ({ setFiltersDrawerOpen }: Partial<FiltersDrawerHandler>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isMediumDown } = useBreakpoints();

  return (
    <Box
      maxWidth={isMediumDown ? 'none' : '240px'}
      width={isMediumDown ? 'none' : '30%'}
      height={isMediumDown ? 'calc(100vh - 100px)' : '100%'}
      display="flex"
      justifyContent="flex-start"
      flexDirection="column"
      padding={isMediumDown ? theme.spacing(2) : 'none'}
      sx={{ borderRadius: theme.spacing(2) }}
    >
      <Stack justifyContent="space-between" direction="row" alignItems="center">
        <Typography variant="h6" color={theme.palette.text.primary} sx={{ mb: theme.spacing(2) }}>
          {t('common.filters')}
        </Typography>
        {isMediumDown && (
          <IconButton onClick={() => setFiltersDrawerOpen?.()}>
            <Close />
          </IconButton>
        )}
      </Stack>

      <Divider />

      <Box padding={theme.spacing(2, 0)} display="flex">
        <AccountCircle
          sx={{
            color: theme.palette.text.primary,
            mr: theme.spacing(1),
          }}
        />
        <Typography variant="subtitle2" color={theme.palette.text.primary}>
          {t('users.filters.userTypes')}
        </Typography>
      </Box>

      <Box mt={theme.spacing(1)} display="flex" justifyContent="flex-start" flexDirection="column">
        {roles.map((role) => (
          <FormControlLabel
            value="end"
            labelPlacement="end"
            control={<Checkbox />}
            key={role}
            label={
              <Typography variant="body1" color={theme.palette.text.secondary}>
                {t(`users.roles.${role}`)}
              </Typography>
            }
          />
        ))}
      </Box>
    </Box>
  );
};
