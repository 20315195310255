import { Box, Grid, Typography, useTheme } from '@mui/material';
import { OfferCard } from '../OfferCard';
import { packageDestination as data } from './constants';

export const OfferSection = () => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection={'column'}>
      <Box sx={{ maxWidth: '1200px', width: '100%' }}>
        <Typography variant="h6" color={'text.primary'} sx={{ padding: theme.spacing(0, 2) }}>
          {data.title}
        </Typography>
        <Grid container spacing={0} component="section">
          {data.items.map((element, index) => (
            <Grid key={index} item xs={12} sm={6} xl={4}>
              <OfferCard {...element} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
