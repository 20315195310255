import Cookie from 'js-cookie';

import axios from 'axios';
import i18next from 'i18next';

export const client = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 15000,
  headers: {
    'Accept-Language': i18next.language,
  },
});

client.interceptors.request.use(async (config) => {
  const idToken = Cookie.get('idToken');

  if (config.headers && idToken) {
    config.headers.Authorization = `Bearer ${idToken}`;
  }
  return config;
});
