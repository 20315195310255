import { Box, useTheme, Typography } from '@mui/material';
import { ArrowDropDown, Timeline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const PriceTrend = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      alignSelf="stretch"
      flex="1 0 0"
      padding={theme.spacing(1, 2, 1, 3)}
      bgcolor={theme.palette.paper.light}
      borderRadius={1}
      border={`1px solid ${theme.palette.divider}`}
      maxWidth={882}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start" alignSelf="stretch" flex="1 0 0" gap={1}>
        <Timeline />
        <Typography variant="body1" color="initial">
          {t('common.priceTrend.message')}
        </Typography>
      </Box>
      <ArrowDropDown />
    </Box>
  );
};
