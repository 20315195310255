import { useQuery } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { QueryKeys } from '../../common/types';
import { client } from '../../common/services';
import { UserListItem } from '../types';

interface useGetAllUsersProps {
  onErrorCallback?: (_e: AxiosError) => void;
}

export const useGetAllUsers = ({ onErrorCallback }: useGetAllUsersProps) =>
  useQuery({
    queryKey: [QueryKeys.getAllUsers],
    queryFn: async (): Promise<AxiosResponse<UserListItem[], any> | undefined> => {
      try {
        return await client.get('user');
      } catch (_e) {
        onErrorCallback && onErrorCallback(_e as AxiosError);
      }
    },
    refetchOnWindowFocus: true,
  });
