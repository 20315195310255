import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSidebar } from '../../contexts/SidebarContext/hook';
import { DashboardButtonLink } from '../DashboardButtonLink';
import { useAuth } from '../../../auth/hooks/useAuth';
import { getSidebarLinks } from './helpers';
import { Drawer, OpenIconButton } from './styles';

export const DashboardSidebar = () => {
  const theme = useTheme();
  const { isOpen, handleSidebarOpening, headerHeight } = useSidebar();
  const { ability } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const links = getSidebarLinks(ability);

  const handleButtonLinkClick = (link: string) => () => {
    navigate(link, { replace: true });
  };

  return (
    // @ts-ignore
    <Drawer variant="permanent" open={isOpen} headerHeight={headerHeight}>
      <Box height="3rem" display="flex" alignItems="center" justifyContent="flex-end" padding="0.5rem">
        <OpenIconButton aria-label={isOpen ? 'Open' : 'Close'} onClick={handleSidebarOpening} isSidebarOpen={isOpen}>
          <ChevronRight />
        </OpenIconButton>
      </Box>
      <Box minHeight="2rem" height="2rem" marginTop="0.5rem" paddingBottom={isOpen ? '0' : '24px'}>
        {isOpen ? (
          <Divider aria-hidden="true" textAlign="left">
            <Typography variant="body1" color={theme.palette.disabled.main} textTransform="uppercase">
              General
            </Typography>
          </Divider>
        ) : (
          <Divider
            aria-hidden="true"
            variant="middle"
            sx={{
              height: theme.spacing(4),
            }}
          />
        )}
      </Box>
      <Stack spacing={2}>
        {links.length > 0 &&
          links.map(({ logo, name, link }) => (
            <DashboardButtonLink
              key={name}
              icon={logo}
              label={t(name)}
              link={link}
              onClick={handleButtonLinkClick(link)}
            />
          ))}
      </Stack>
    </Drawer>
  );
};
