import { ReactNode } from 'react';

export enum QueryKeys {
  getMyUserData = 'getMyUserData',
  getAllUsers = 'getAllUsers',
}

type Flight = {
  origin: string;
  destination: string;
  initialDate: string;
  endDate?: string;
  travelerAmount: number;
  flightClass: string;
  totalAdult: string;
  totalChildren: string;
  totalInfant: string;
  totalInfantWithSeat: string;
};

export type IFlightSearch = Flight[];

type roomInfo = {
  adults: number;
  kids?: number;
  babies?: number;
};

export type IHotelSearch = {
  destination: string;
  initialDate?: string;
  endDate?: string;
  rooms: roomInfo[];
};

export interface ControlledFieldProp {
  formFieldName: string;
  text: string;
  error?: string;
  icon?: ReactNode;
}
